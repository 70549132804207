import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Alert from '@mui/material/Alert';
import TagsProductSelect from '../TagsProductSelect';

const TagsPage = () => {


    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [successDelete, setSuccessDelete] = useState(false) 
    const [selectedTags, setSelectedTags] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
        const addTag = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          const tagsData = {
            name: name ,
            description: description
                   }
      try {         
     const response = await axios.post('/api/tags', tagsData, config);
          setDescription('')
          setName('')
          setError(false)
          setSuccess(true)
        } catch(err) {
          setSuccess(false)
          setName('')
          setError(err.response.data.errors.name[0])
        }
      }
        addTag(); 
    }


    const deleteTag = (id) => {
      
      const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
      const delTag = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }
      
    try {         
   await axios.delete(`/api/tags/${id}`, config);
        setSuccess(true)
        setSuccessDelete(true)
      } catch(err) {
        setSuccess(false)
        setName('')
        setError(err.response.data.errors.name[0])
      }
    }
      delTag(); 
    }


    useEffect(()=> {
      
    }, [error, success, successDelete, name])

  return (
    <div>
    <p  className="bg-white shadow-md rounded text-xl px-8 pt-6 pb-8 mb-4"> Manage Tags </p>
    {error && <Alert severity="error">{error}</Alert>}
    {success && <Alert severity="success"> Tag  added </Alert> }
    {successDelete && <Alert severity="success"> Tag  deleted </Alert> }

    <form onSubmit={submitHandler} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <button
            className="w-1/4 px-6 py-2 text-blue-200 bg-blue-600 hover:bg-blue-900" type='submit' > Add
    </button>

   <div className="space-x-0 py-8 lg:flex lg:space-x-4">
         <div className="w-full lg:w-1/2">
            <label 
            htmlFor="Address" 
            className="block mb-3 text-sm font-semibold text-gray-500">
              Tags Name
            </label>
            <input
            className="w-full px-4 py-3 text-xs border border-gray-300 rounded lg:text-sm 
            focus:outline-none focus:ring-1 focus:ring-blue-600"
            name="Name" 
            type='text' 
            value={name} 
            onChange={(e)=>setName(e.target.value)}
             cols="20" rows="4" 
             placeholder="Name">
             </input>
         </div>
         <div className="w-full lg:w-1/2">
            <label 
            htmlFor="Address" 
            className="block mb-3 text-sm font-semibold text-gray-500">
              Tags description
            </label>
            <input
            className="w-full px-4 py-3 text-xs border border-gray-300 rounded lg:text-sm 
            focus:outline-none focus:ring-1 focus:ring-blue-600"
            name="Name" 
            type='text' 
            value={description} 
            onChange={(e)=>setDescription(e.target.value)}
             cols="20" rows="4" 
             placeholder="Name">
             </input>
          </div>
        </div>
      </form>

      <TagsProductSelect 
      selectedTags={selectedTags}
      setSelectedTags = {setSelectedTags}
      ableToDelete = {true}
      deleteTag = {deleteTag}
      />

    </div>
  )
}

export default TagsPage
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';

const CartPageProductsTheme = ({cartItems, t, removeFromCartHandler, addToCartHandler}) => {

  return (
    <>
{cartItems.map(item => (
        <div key={item.product} className="flex h-auto items-center">
          <div className="flex pt-10 w-full h-full flex-row">
            <div className='flex flex-col  w-2/3'>
            <img src={`${process.env.REACT_APP_API_ENDPOINT}storage/${item.image}`} alt="product" 
            className="object-cover max-w-4/5 h-auto object-center"/>
            </div>

            <div className='flex  h-full mx-2 flex-col  flex-grow w-1/3'>
            <span className="text-sm">{item.title}</span>
            <span className="text-xs text-theme-base-color2">{item.author}</span>

            <div className="flex pt-2 flex-row">
            <select className="w-8 bg-white" type="text" value={item.qty}  
            onChange={(e) => addToCartHandler(item.product,Number(e.target.value))}>
              {
          [...Array(item.stock).keys()].map((x) => 
          (<option key={x+1} value={x+1}> {x+1} </option>
          
          ))} </select>
          </div>
          <div className='w-full pt-2'>
          <span className="text-center font-semibold px-1 text-sm">
            {(item.qty*( item.discountPlan ? 
          Math.round(((item.price-(item.discountPlan.percentage*item.price*0.01)) + Number.EPSILON) * 100) / 100
          : item.discount > 0 ? 
           Math.round(((item.price-item.discount) + Number.EPSILON) * 100) / 100 
          : item.price)).toFixed(2)} &euro;	
          </span> 
          </div>
          <a onClick={()=> removeFromCartHandler(item.product)} className="
           mt-10
           font-semibold
           text-xs
           cursor-pointer
           ">
            <ClearIcon/>
            </a> 
           </div>
           </div>
           </div>
))}
    </>
  )
}

export default CartPageProductsTheme
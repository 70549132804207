import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {logout} from '../actions/userActions';
import  {SearchBox}  from './SearchBox';
import {Route} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CartHeaderIcon from './CartHeaderIcon'
import LanguageSwitcher from './LanguageSwitcher'
import UserInfoDisplay from './UserInfoDisplay'

export default function Header({showBox}) {

  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
     i18n.changeLanguage(lng);
    };

    const dispatch = useDispatch();
  const userLogin = useSelector(state=>state.userLogin)
  const {userInfo} = userLogin;
  const {cartItems} = useSelector(state=>state.cart);
  const [isOpen, setIsOpen] = useState(false);
  const icon = `${process.env.REACT_APP_URL}images/icons/cart-icon.png`
  const logoIcon = `${process.env.REACT_APP_URL}images/about/naftilos_logo.jpg` 
  const logoutHandler = ({history}) =>{
    dispatch(logout())
  }

    return (
        <>
 <nav className="top-0 relative h-26 md:h-32 z-10 bg-white">
  <ul className="pt-16 px-2 md:px-10 grid grid-cols-2 gap-0 md:gap-1 grid-rows-1 md:grid-cols-4">
  <div className='flex flex-row'>
  <a href="/" className=" text-xl font-astana lg:text-2xl xl:text-3xl  text-theme-base-color2">
              ΝΑΥΤΙΛΟΣ
  </a>
  <li className="hoverable hover:bg-white">
           <SearchRoundedIcon  sx={{ fontSize:40, marginLeft:1, marginTop:0.1 }}/>
            <div className="p-6  mega-menu h-auto mb-16 sm:mb-0 shadow-xl bg-white">
              <div className="container relative z-50  w-full flex flex-col justify-start mx-2">
                <div className="w-full mx-2 md:mx-4 font-astana my-2">
                  <h2 className="text-sm md:text-md">{t('search')}</h2>
                </div>
                <ul className="mx-2 w-2/3 md:mx-4 border-gray-600 border-b">
                  <div className="flex items-center">
                  {<Route render={({history})=> <SearchBox history={history}/>} /> }
                  </div>
                </ul>
                <ul className="w-full mt-4 lg:pt-3">
                  <div className="flex flex-row justify-start  items-center">
                  <button onClick={(e)=> window.location.href='/search'} className="flex font-astana uppercase flex-row"> 
                  <svg   xmlns="http://www.w3.org/2000/svg" width="25px" height="auto" viewBox="0 0 24 24">
  <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
</svg>
                  {t('analytical')}  {t('cosearch')}
                  </button>
                  </div>
                </ul>
              </div>
            </div>
    </li> 
      </div>

      <div className='flex flex-row text-gray-600 cols-span-2 pb-1 lg:cols-span-1 items-center justify-end relative z-50'>
     { userInfo && 
          <CartHeaderIcon cartItems={cartItems}  icon={icon} />
      }
          <UserInfoDisplay userInfo={userInfo} logoutHandler={logoutHandler} t={t}/>
          <LanguageSwitcher changeLanguage={changeLanguage} />
        </div>
    </ul>

    </nav>
        </>
    )
}

import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';


const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

const theme = createTheme({
  ...breakpoints,
  typography: {
    fontFamily: [''],
    fontWeight:'400',
    body1: {
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.75rem'
        },
      [breakpoints.breakpoints.up('sm')]: {
          fontSize: '0.875rem'
       },
      [breakpoints.breakpoints.up('lg')]: {
            fontSize: '1.11rem'
        },  
    
    }
  }
})


export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion 
  disableGutters
  elevation={0} 
  square 
  {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
    p:0,
    m:0
  },
  '&:before': {
    display: 'none',
    p:0
  },
  '& .MuiAccordionSummary-root': {
    minHeight:'0px',
  },
  '& .MuiCollapse-root': {
    width: '90%',
    paddingTop:'4px',
  },
}));


export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({ 
  fontFamily: theme.typography,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, 0)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-16px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    marginTop: '0px',
    marginBottom: '0px'
  },
}));


export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  border: '0px solid rgba(0, 0, 0, 0)',
}));




export const MyTypography = ({content}) => {
    return (
<ThemeProvider theme={theme}>
  <Typography> {content}</Typography>
</ThemeProvider>
    )
}


import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
    AnimatePresence,
    motion } from 'framer-motion/dist/framer-motion';
import { getUserDetails } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux'

const backdrop = {
    visible: { opacity : 1},
    hidden: {opacity: 0}
}

const modal = {
    hidden: {
        y:"-100vh",
        opacity:0
    },
    visible: {
        y:"200px",
        opacity:1,
        transition: { delay:0.5}
    }
}

const Modal = ({showModal, setShowModal, belongTo, data}) => {

    const userDetails = useSelector((state) => state.userDetails)
    const {user, loading} = userDetails
    const dispatch = useDispatch()


    useEffect(() => {
        if(belongTo==='order') {
            if(data.user_id) {
              dispatch(getUserDetails(data.user_id))
            }
        }
      }, [data.user_id])   

      
  return (
      <>
      <AnimatePresence exitBeforeEnter>
              <motion.div className="backdrop" 
              variants={backdrop}
              initial = "hidden"
              animate="visible"
              exit="hidden"
              >

            <motion.div className="modal"
                          variants={modal}
                          initial = "hidden"
                          animate="visible"
            > 

          { belongTo==='order' && !loading && showModal  && user.data !== undefined && (
              <>
            <p className={`py-2 bg-gray-200   ${data.status === 'pending' ? 
        ' bg-red-700 hover:text-red-700' : data.status === 'processing' ?
        'bg-green-700 hover:text-green-700' : data.status === 'decline' ?
        'line-through bg-gray-800 hover:text-gray-400' :
        'bg-blue-500  hover:text-blue-700' } `}> {data.order_number} </p>

            <p className='py-1  text-xl'>  {user.data.name} </p>
            <p className='py-1  text-xl'>  {data.shippingMethod === 'pick' ? 'παραλαβή απο κατάστημα' : 'Ταχυδρομείο'} </p>
            <p className={`py-1 text-white  text-xl ${data.payment_status ? 'bg-blue-700' : 'bg-red-700' }`}>  {data.totalPrice} </p>
            <div className='text-2xl py-2'>
            <p className='uppercase py-1 bg-gray-200'> To </p>
            <p className='py-1'>  {data.shippingName} </p>
            <p className='py-1'>  {data.shippingAddress} </p>
            <p className='py-1'>  {data.shippingCity} </p>
            <p className='py-1'>  {data.shippingPostalCode} </p>
            <p className='py-1'>  {data.shippingCountry} </p>
            <p className='py-1'> Note: {data.notes} </p>

        </div>
             <div className='py-1'> 
             <p className='py-2 text-xl uppercase bg-gray-200'> Books </p>
            {data.items.map( (item, index) => 
            <div key={item.product_id} className='w-full text-xl bg-gray-50  py-1'> 
            <Link  to={ `/product/${item.product_id}`}> {index+1}. {item.product_name} --> {item.quantity} αντίτυπο</Link> 
            </div>
            )}

            <button className="border-2 px-4 py-2 w-1/2 uppercase text-xl border-gray-800 hover:border-red-600" onClick={(e)=> setShowModal(false)}> close </button>
            </div>
            </>
        )}

{ belongTo==='user' && (
    <div className='px-4'>
    <p className='pt-2'>Name: {data.name} </p>
    <p className='pt-4'>Email: {data.email} </p>
    <p className='pt-4'>Phone Number: {data.tel_no} </p>
    <p className='pt-4'>Address: {data.address ? data.address : ''} </p>
    <p className='pt-4'>City: {data.city ? data.city : ''} </p>
    <p className='pt-4'>Post Code: {data.postalCode? data.postalCode : ''} </p>
    <p className='py-4'>Country: {data.country ? data.country : ''} </p>
 
    <button className="border-2 px-4 py-2 w-1/2 uppercase text-xl border-gray-800 hover:border-red-600" 
    onClick={(e)=> setShowModal(false)}> close 
    </button>
</div>
)
}
            </motion.div>
            </motion.div>
      </AnimatePresence>
    </>
  )
};


export default Modal;

import React from 'react'
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";


export const ProfilePageOrdersTheme = ({
    showModal,
    setShowModal,
    showOrder,
    setShowOrder,
    loadingProfileOrders,
    orders}) => {
      const { t, i18n } = useTranslation();

  return (
      <>
    { loadingProfileOrders === false &&  orders && (

    <div className="w-full md:w-3/4 bg-gray-50 ">
    <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
                <ModalBody>
                    <p className="text-base  leading-relaxed text-gray-600 font-normal">
                    { showModal && (orders.data.find(order=> order.order_id === showOrder).items.map(
                        item=> <div className='w-full border-2 p-2'> 
                          <Link key={item.product_id} to={ `/product/${item.product_id}`}> {item.product_name} - {item.quantity}</Link> </div>))}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModal(false)}
                        ripple="dark"
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>


<div class="flex items-center justify-center">
<div class="container">
<table class="w-4/5 flex  flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
  <thead className="bg-gray-50">
  <tr class="bg-teal-400 flex flex-col rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
      <th scope="col" className="p-3 text-left">
        Order Number
      </th>
      <th scope="col" className="p-3 text-left">
        Shipping Method
      </th>
      <th scope="col" className="p-3 text-left">
        Payment Method
      </th>
      <th scope="col" className="p-3 text-left">
        TotalPrice
      </th>
      <th scope="col" className="p-3 text-left">
        Date
      </th>
      <th scope="col" className="p-3 text-left">
        Order Status
      </th>
      <th scope="col" className="p-3 text-left">
        Payment Status
      </th>

    </tr>
  </thead>
	<tbody class="flex-1 sm:flex-none ">
          {orders.data.map((order) => (          
    <tr key={order.order_id} onClick={(e) => { setShowOrder(order.order_id); setShowModal(true)}} 
    className="flex flex-col mb-2 sm:mb-0">
      <td className="border-grey-light border cursor-pointer hover:bg-gray-100 p-3 truncate">
        <div className="flex items-center">
          <div className="">
            <div className="text-sm font-medium text-gray-900">
              {order.order_number}
            </div>

          </div>
        </div>
      </td>
      <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
        <div className="text-sm text-gray-500">{order.shippingMethod ==="pick" ?  
        <p> {t('In-store PickUp')}</p> : 
        <p> {t('post')}</p>}</div>
      </td>
      <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
        <div className="text-sm text-gray-500">{order.payment_method === 'Cash' && order.shippingMethod === 'pick' ? t('Cash') : 
  order.payment_method === 'Cash' &&  order.shippingMethod === 'post' ?  t('payOnDelivery') : 'PayPal'  }</div>
      </td>
      <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
        <div className="text-sm text-gray-500">{order.totalPrice}</div>
      </td>
      <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
        <div className="text-sm text-gray-500">{order.dateofOrder}</div>
      </td>
      <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
        <div className="text-sm text-gray-500">{order.status === 'pending' ? 'Εκκρεμεί' : order.status === 'completed' ? 'Ολοκληρώθηκε' : ''}</div>
      </td>             
      <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
        <div className="text-sm text-gray-500">{order.payment_status ? 'Εξοφλήθη' : 'Εκκρεμεί πληρωμή'}</div>
      </td>
    </tr>
    
      ))}
  </tbody>
</table>
</div>
</div>
</div>
)}
</>
  )
}

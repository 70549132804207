import React, {useEffect, useState} from 'react'
import axios from 'axios'
import SearchAutoComp from './SearchAutoComp'
import { TrashIcon } from '@heroicons/react/solid';

const TagsProductSelect = ({
    selectedTags,
    setSelectedTags,
    deleteTag,
    ableToDelete
}) => {
 
const [tags, setTags] = useState('')

const userData = JSON.parse(localStorage.getItem('userInfo')).data


    return (
<div>
<p className=' text-xl pt-8 pb-2'>
        Tags
</p>
<ul>
        {
            selectedTags && selectedTags.map(item=>
                <li key={item.id} className='p-2'>{item.name} | {item.slug} {
                    ableToDelete && <TrashIcon className = "p-1 w-8" onClick={(e)=>deleteTag(item.id)}></TrashIcon>
                }</li>
                )     
        }
</ul>
    <div>
   <SearchAutoComp 
    userData={userData}
    labelOpts = 'tags'
    apiUrl = '/api/searchtag'
    setFilterValue={setSelectedTags}/>
    </div>
</div>
    )
}

export default TagsProductSelect
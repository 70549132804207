import { TextField } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import LogoHeader from '../components/LogoHeader'
import { PageTitle, GlobalButton } from './generalComponents'
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';


const RegisterPageTheme = ({
    error, message,
    loading,
    submitHandler,
    name,setName, 
    email, setEmail, 
    password, setPassword,
    password_confirmation, setPasswordConfirmation,
    redirect
    }) => {

        const { t, i18n } = useTranslation();

  return (
    <div className = "w-full min-h-screen font-astana bg-bgImage">
        <Header/>
        <div className="flex items-center -mt-32 justify-center ">
        <div className="px-8 pt-32">
                    <PageTitle>{t('register')}</PageTitle>
                    <form onSubmit={submitHandler}>

                        {error && <Alert severity="error">{error}</Alert> }
                        {message && <Alert severity="error">{message}</Alert> }
                        {loading && <p>loading</p>}
                        <TextField
              variant="standard"
              className="uppercase	w-full"
              type='text' 
              label={`${t('Name')} /`}
              value={name} 
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 700,
                    letterSpacing: '0.05em'
                    },
              }}
              onChange={(e)=>setName(e.target.value)} 
             >
        </TextField>
        <TextField
              type='text' 
              label={`${t('Email')} /`}
              value={email} 
              onChange={(e)=>setEmail(e.target.value)} 
              variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             >
        </TextField>
        <TextField
              type='password' 
              label={`${t('Password')} /`}
              value={password} 
              onChange={(e)=>setPassword(e.target.value)} 
              variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             >
        </TextField>
        <TextField
              type='password' 
              label={`${t('Confirm Password')} /`}
              value={password_confirmation} 
              onChange={(e)=>setPasswordConfirmation(e.target.value)} 
              variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             >
        </TextField>


                <button className='  text-theme-base-color2 uppercase pt-8' type="submit">{t('register')} </button>

                    <div className="text-center text-sm text-grey-dark mt-4">
                        <p>{t('agree')} </p> 
                        <Link className="no-underline border-b border-grey-dark text-grey-dark" to="/aboutUs">
                            {t('termsUse')}
                        </Link>
                    </div>
                    <div className="mt-6">
                    {t('Already have an account')}? 
                        <button>
                        <Link className=' px-2 text-theme-base-color2' to={redirect ? `/login` : '/login'}> 
                        {t('login')}
                        </Link> 
                        </button>
                </div>
                </form>
                </div>
            </div>
    </div>
  )
}

export default RegisterPageTheme
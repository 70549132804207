import React from 'react'
import DiscountPlansForm from './DiscountPlansForm'
import DiscountPlansList from './DiscountPlansList'


const DiscountPlans = () => {
  return (
    <>
    <DiscountPlansList/>
    </>
  )
}

export default DiscountPlans
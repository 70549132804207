import React, {useState, useRef, useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer';
import Bottom from '../components/Bottom';
import Product from '../components/Product';
import { GlobalButton, PageTitle } from '../theme/generalComponents';
import Loader from '../components/Loader';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import PaginationComponent from '../components/subComponents/PaginationComponent';
import SearchPageTheme from '../theme/SearchPageTheme';
import useWindowDimensions from '../utils/useWindowDimensions';

const SearchPage = ({history, location}) => {

  const {width} = useWindowDimensions()

  let paginationSize = 0
  switch(true) {
    case (width < 768):
     paginationSize = 1
    break;
    case (width >= 768 && width < 1024):
     paginationSize = 2
    break;
    case (width >=  1024):
    paginationSize = 3
  }

  const { t, i18n } = useTranslation();
  const [keyTitle, setKeyTitle] = useState( window.location.search ?   decodeURIComponent(window.location.search.split('t=')[1]) : '' )
  const [keyAuthor, setKeyAuthor] = useState( window.location.search ?   decodeURIComponent(window.location.search.split('t=')[1]) : '' )
  const [searchResult, setSearchResult] = useState('')
  const [keyPublisher, setKeyPublisher] = useState('')
  const [keyISBN, setKeyISBN] = useState('')
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState('')
  const [results, setResults] = useState('')
  const [total, setTotal] = useState('')
  const [current, setCurrent] = useState('')
  const [perPage, setPerPage] = useState('')
  const showBox = false;


const clearFields = (e) => {
  e.preventDefault()
          setKeyTitle('')
          setKeyAuthor('')
          setKeyPublisher('')
          setKeyISBN('')
}

  useEffect(() => {
    const fetchData = async () => {
      if (window.location.search) {  

        const {data} = await axios.get(`/api/quickSearch?page=${pageNum}`, {
          params: {
            quick:true,
            keyword:keyTitle,
            term:paginationSize
          }});
              setPerPage(data.data.meta.per_page)
              setCurrent(data.data.meta.current_page)
              setTotal(data.data.meta.total)
              setSearchResult(data.data.data)
              setLoading(false)
            }

 else if(keyTitle.length > 3 || keyAuthor.length > 3 || keyISBN.length > 3 || keyPublisher.length > 3) { 
    const result = await axios.get('/api/searchBooks',{
      params: {
        keyAuthor:keyAuthor,
        keyTitle:keyTitle,
        keyPublisher:keyPublisher,
        keyISBN: keyISBN
      }
    });

    setSearchResult(result.data)
    setLoading(false)
} else {
  setLoading(false)
  setSearchResult([])
}
}
fetchData()
}, [keyAuthor, keyTitle, keyPublisher, keyISBN, pageNum])


console.log(searchResult)

const handleChangePage = (event, newPage) => {
  setPageNum(newPage);
};


const data = {
  keyTitle: keyTitle,
  keyAuthor: keyAuthor,
  keyPublisher:keyPublisher,
  keyISBN: keyISBN,
  results: searchResult,
  t:t,
  loading: loading,
  total:total,
  pageNum:pageNum,
  perPage:perPage,
  history: history,
  setKeyTitle: setKeyTitle,
  setKeyAuthor: setKeyAuthor,
  setKeyPublisher: setKeyPublisher,
  setKeyISBN:  setKeyISBN,
}



  return (
<SearchPageTheme
    data = {data}
    handleChangePage = {handleChangePage}
    clearFields = {clearFields}
/>
  )
}

export default SearchPage
import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import {Link } from 'react-router-dom';
import { listUsers, deleteUser } from '../actions/userActions.js'
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from 'react-js-pagination';
import axios from 'axios';
import Modal from '../components/Modal'

export const UserListPage = ({history}) => {
  
  const dispatch = useDispatch()

    const userLogin = useSelector((state)=> state.userLogin)
    const {userInfo} = userLogin

    const userDelete = useSelector((state)=>state.userDelete)
    const [filterValue, setFilterValue] = useState('')
    const [keyword, setKeyword] = useState('')
    const [pageNum, setPageNum] = useState('')
    const [total, setTotal] = useState('')
    const [current, setCurrent] = useState('')
    const [perpage, setPerPage] = useState('')
    const [users, setUsers] = useState('')
    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [userModal, setUserModal]= useState('')
    const belongTo = 'user'

    useEffect( () => {
      const fetchUsers = async () => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
  
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,  
            }
        }
        try {
        const result = await axios.get(`api/users?page=${pageNum}`, config);     
        setPerPage(result.data.data.meta.per_page)
        setCurrent(result.data.data.meta.current_page)
        setTotal(result.data.data.meta.total)
        setUsers(result.data.data.data)
        } catch (err) {
            setError(err.response)
        }
      };
      fetchUsers();
        
    }, [userDelete, pageNum])

    useEffect(() => {
      const fetchData = async () => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
  
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,  
            },
            params: {
              keyName:keyword
            },
          }
      const result = await axios.get('api/users/search', config);
      setUsers(result.data.data.data)
  }
  fetchData()
  }, [keyword])



    const deleteHandler = (id) => {
      if(window.confirm('Confirm this user delete')){
      dispatch(deleteUser(id))}
    }

const showUserModal = (user) => {
  setShowModal(true)
  setUserModal(user)
}



    return (
        <div>
              { showModal && ( 
  <Modal 
    showModal={showModal} 
    setShowModal={setShowModal}
    belongTo = {belongTo}
    data={userModal} 
    /> 
    )}
{users && (
<>
 <Card>
 <CardHeader color="purple" contentPosition="left">
     <h2 className="text-white text-2xl">Users Table</h2>
 </CardHeader>

 <div className='w-1/4 px-10'>
  <Autocomplete
    multiple
    renderOption={(props, option) => {
      return (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      );
    }}
    filterOptions={(x)=> x}
    id="size-small-outlined-multi"
    size="medium"
    options={users}
    onChange={(event, newValue) => setFilterValue(newValue)}
    getOptionLabel={(option) => option.name}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label="Name"
        placeholder=""
        name="value"
        onChange={(e)=>{setKeyword(e.target.value)}}
      />
    )}
  />
  </div>
 <CardBody>
     <div className="overflow-x-auto">
         <table className="items-center w-full bg-transparent border-collapse">
             <thead>
                 <tr>
                     <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                         Name
                     </th>
                     <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                         Address
                     </th>
                     <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                         Email
                     </th>
                     <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                         
                     </th>
                     <th scope="col" className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                       <span className="sr-only">Edit</span>
                    </th>                     
                   </tr>
               </thead>
               <tbody>
              {
  (filterValue.length>0 ? users.filter(user => user.name===filterValue[0].name) : users).map((user) => (

<tr key={user.id}>
              <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                <div className="flex items-center">
                  <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    <button className="p-2 border-2 neumorph "
                      onClick={(e)=> showUserModal(user)}> {user.name} 
                    </button>
                  </div>
                  </div>
                </div>
              </td>
<th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
{user.address}
</th>
<th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
{user.email} 
</th>
<th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
<button onClick={()=>deleteHandler(user.id)}  className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                    DELETE
            </button> 
</th>
<th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">

</th>
</tr>
              ))}
          </tbody>
            </table>
             </div>
</CardBody>
</Card>
<Pagination 
    activePage={current}
    totalItemsCount={total}
    itemsCountPerPage={perpage}
    onChange={(pageNum)=> setPageNum(pageNum)}
    itemClass="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
    linkClass='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
    firstPageText='First'
    lastPageText='Last'
    />
</>
            )}
            
        </div>
    )
}

import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";


const ProductRelatedSwiper = ({ product}) => {

  const { t, i18n } = useTranslation();
  const history = useHistory();



    const mainImage =
    product.media.filter((el) => el.isMain).length ?  product.media.filter((el) => el.isMain ).map(el=>el.pathway) 
    : product.media.length > 0 ?  product.media[0].pathway : ""

    const [hoverProduct, setHoverProduct] = useState(false)
    
    const icon = `${process.env.REACT_APP_URL}images/icons/cart-icon.png`


  return (
<div 
 onClick={()=> {history.push(`/product/${product.slug}`)}}
 className="h-full"
 onMouseEnter={()=>setHoverProduct(true)}
 onMouseLeave={()=>setHoverProduct(false)} 
 >

    <div className="flex relative h-5/6 w-full">
        { mainImage ?
          <img className='object-cover w-full h-full'
            src={`${process.env.REACT_APP_API_ENDPOINT}storage/${mainImage}`} 
            alt={product.slug}
            width="auto"
            height="auto"
            />  : 
            <div className='w-full border-2 flex flex-col text-xs md:text-sm items-center border-theme-base-color2 h-full'>
              <p className='pt-14 px-2'> {product.title} </p>
              <p className='pt-2 px-2'> {product.author} </p>
              <p className='pt-2 px-2'> {product.publisher} </p>
              <p className='pt-2 px-2 text-theme-base-color2'> Μή διαθέσιμο εξώφυλλο </p>
              </div>
        }

        {
        product.discountPlan && 
        <p className='absolute m-1 p-2 text-xs rounded-full border-2 text-white bg-theme-base-color2 '> -{product.discountPlan.percentage}%
        </p> 
        }

    { hoverProduct && 
        <div className="absolute
         bg-theme-base-color2 
         h-full w-full
         place-content-center 
         z-10">
        <div className="text-xs">
          <p className='pt-1
           uppercase
           italic 
           font-extrabold 
           flex'>{product.title}
           </p>        
          <p className='pt-1  italic font-extrabold'>
            {product.author} 
          </p>
          <p className='pt-1  italic font-extrabold flex '>
            {product.publisher} {product.year_published}
           </p> 
           <p className='pt-1  italic font-extrabold flex '>
             {t('pages')} {product.pages_no} 
           </p> 
          <p className=' flex'>
              
              { product.discountPlan ? 
                <h1>{Math.round(((product.price-(product.discountPlan.percentage*product.price*0.01)) + Number.EPSILON) * 100) / 100} </h1>
                : product.discount > 0 ? 
                <h1>{Math.round(((product.price-product.discount) + Number.EPSILON) * 100) / 100} &euro;</h1> : ''
              }

          <h1 className={`px-1 font- ${(product.discountPlan || product.discount>0) && 'text-gray-800'}`} >{product.price} &euro;</h1>
          </p>
          <h2 className='font-bold flex'>{product.stock >0 ? "Διαθέσιμο" : "Εξαντλημένο"}</h2>
          </div>
        </div> 
     }
        </div>
</div>
  )
}

export default ProductRelatedSwiper
import React from 'react'
import {motion } from 'framer-motion/dist/framer-motion';
import { useTranslation } from "react-i18next";
import Header from '../components/Header';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { PageTitle } from './generalComponents';

const LoginPageTheme = ({
    submitHandler,
    email, setEmail,
    password, setPassword, 
    message,
    error,
    loading, 
    handleForgotPassword,
    AuthProvider
    }) => {

  const { t } = useTranslation();

  const containerVariants = {
    hidden: {
      opacity:0,
    },
    visible : {
      opacity:1,
      transition: {delay:0, duration:1}
    },
    exit: {
      x: '-100vw',
      transition: {duration:1,}
    }
  }

  return (
  <motion.div
  variants  = {containerVariants}
  initial="hidden"
  animate="visible"
  exit="exit"
className="w-full min-h-screen font-astana bg-bgImage">
      <Header/>
  <div className="flex items-center h-full justify-center -mt-32">
    <div className="px-8 pt-32  text-left ">

        <PageTitle>{t('LoginAccount')}</PageTitle>

        <form onSubmit={submitHandler} className=" px-8 pt-1 mb-4">
            {error && error[0] && <Alert severity="error">{error[0]}</Alert>}
            {error && error[1] && <Alert severity="error">{error[1]}</Alert>}

            <div className="mt-2">
    <TextField
              type='email' 
              label={`${t('Email')} /`}
              value={email} 
              onChange={(e)=>setEmail(e.target.value)} 
              variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             >
        </TextField>
                <div className="mt-4">
        <TextField
              type='password' 
              label={`${t('Password')} /`}
              value={password} 
              onChange={(e)=>setPassword(e.target.value)} 
              variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             >
        </TextField>

                </div>
                <div className="pt-8 flex items-baseline justify-left">
                  <button className='  text-theme-base-color2 ' type="submit"> {t('login')}</button>
                  
                </div>
                <div className='flex flex-col pt-10'>
                <p  onClick={ (e) => {handleForgotPassword()}} 
                className="text-sm cursor-pointer 
                hover:underline">{t('ForgotPassword')}?</p>
                <a href="/register" className='mt-4 text-base text-theme-base-color2'>{t('signup')}</a>
                </div>
                {message && (<p className='border-2 text-center border-red-600'> {message} </p>)}
            </div>
        </form>
    </div>
</div>

</motion.div>
  )
}

export default LoginPageTheme
import React, { useState, useEffect, getState } from "react";
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import { listProductDetails, updateProduct } from "../actions/productActions";
import ProductListImage from "./ProductListImage";
import ProductAddImage from "./ProductAddImage";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
import { TextareaAutosize, TextField } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CategorySelection from "./CategorySelection";
import CategoriesMenuSelect from "./CategoriesMenuSelect";
import CategoriesProductSelect from "./CategoriesProductSelect";
import TagsProductSelect from "./TagsProductSelect";
import ProductAddReviewComponent from "./dashboard/ProductAddReviewComponent";

const ProductEditComponent = ({match, history}) => {

const dispatch = useDispatch()
const productId = match.params.id
const [slug,setSlug] = useState('')
const [title,setTitle] = useState('')
const [translator,setTranslator] = useState('')
const [author,setAuthor] = useState('')
const [year_published,setYear_published] = useState('')
const [published_at,setPublished_at] = useState('')
const [publisher,setPublisher] = useState('')
const [pages_no,setPages_no] = useState('')
const [isbn_no,setIsbn_no] = useState('')
const [condition,setCondition] = useState('')
const [cond_cmnt,setCond_cmnt] = useState('')
const [availability,setAvailability] = useState('')
const [stock,setStock] = useState('')
const [price,setPrice] = useState('')
const [weight,setWeight] = useState('')
const [length,setLength] = useState('')
const [width,setWidth] = useState('')
const [height,setHeight] = useState('')
const [sku,setSku] = useState('')
const [content,setContent] = useState('')
const [discount,setDiscount] = useState('')
const [discountPlan, setDiscountPlan] = useState([])
const [feature,setFeature] = useState(true)
const [newProduct, setNewProduct] = useState(false)
const [visibility, setVisibility] = useState(false)
const [tags, setTags] = useState('')
const [categories, setCategories] = useState('')
const [notifyImg, setNotifyImg] = useState(false)

const productUpdate = useSelector((state)=> state.productUpdate)
const {
   loading:loadingUpdate,
   error:errorUpdate,
   success:successUpdate,
   } = productUpdate

const productDetails = useSelector((state)=> state.productDetails)
const { loading, error, product } = productDetails

const userLogin = useSelector((state)=> state.userLogin)
const {userInfo} = userLogin

useEffect(() => {
  if(successUpdate) {
    dispatch({type:PRODUCT_UPDATE_RESET})
    history.push('/admin/dashboard/productlist')
  } else {
  if( product.data.title === undefined || product.data.id !== productId) {
  dispatch(listProductDetails(productId))
  } 
  if(product.data.title) {
  setSlug(product.data.slug)
  setTitle(product.data.title)
  setTranslator(product.data.translator)
  setAuthor(product.data.author)
  setYear_published(product.data.year_published)
  setPublished_at(product.data.published_at)
  setPublisher(product.data.publisher)
  setPages_no(product.data.pages_no)
  setIsbn_no(product.data.isbn_no)
  setCondition(product.data.condition)
  setCond_cmnt(product.data.cond_cmnt)
  setAvailability(product.data.availability)
  setStock(product.data.stock)
  setPrice(product.data.price)
  setWeight(product.data.weight)
  setLength(product.data.length)
  setWidth(product.data.width)
  setHeight(product.data.height)
  setSku(product.data.sku)
  setContent(product.data.content)
  setDiscount(product.data.discount)
  setFeature(product.data.feature)
  setNewProduct(product.data.newProduct)
  setVisibility(product.data.visibility)
  setCategories(product.data.categories)
  setTags(product.data.tags)
  setDiscountPlan(product.data.discountPlan ? product.data.discountPlan.id : '')
  }
}
}, [productId, product.data.title, successUpdate])



 const submitForm = () =>  {
   dispatch(
     updateProduct({
id:productId,
slug,
title,
translator,
author,
year_published,
published_at,
publisher,
pages_no,
isbn_no,
condition,
cond_cmnt,
availability,
stock,
price,
weight,
length,
width,
height,
sku,
content,
discount,
discountPlan,
feature,
newProduct,
visibility,
categories:categories.map(item=>item.id),
tags:tags.map(item=>item.id)
   }))
  }


return (
    <>

{product && (
<div className="p-5"> 
 <div className="w-full pt-10 grid grid-cols-2 gap-4 pr-8 pl-8">
 <div className="w-full">
  <form  className="w-full">
   <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Title"
        placeholder="Title"
        value={title}
        onChange = {(e)=> setTitle(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Slug"
        placeholder="Slug"   
        value={slug}
        onChange = {(e)=> setSlug(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Author"
        placeholder="Author"
        value={author}
        onChange = {(e)=> setAuthor(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Published Year"
        placeholder="Published Year"   
        value={year_published}
        onChange = {(e)=> setYear_published(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Published Place"
        placeholder="Published Place"
        value={published_at}
        onChange = {(e)=> setPublished_at(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Publisher"     
        placeholder="Publisher"   
        value={publisher}
        onChange = {(e)=> setPublisher(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Pages"     
        placeholder="Pages"
        value={pages_no}
        onChange = {(e)=> setPages_no(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        label="ISBN"     
        variant="outlined"
        placeholder="ISBN"   
        value={isbn_no}
        onChange = {(e)=> setIsbn_no(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Condition</InputLabel>
        <Select
          id="plan"
          value={condition}
          label="Condition"
          onChange={e => {setCondition(e.target.value)}}
        >
          <MenuItem value={'Level1'}>Αριστη</MenuItem>
          <MenuItem value={'Level2'}>Πολύ καλή</MenuItem>
          <MenuItem value={'Level3'}>Kαλή</MenuItem>       
        </Select>
      </FormControl>
    </Box>  
    </div>
    <div className="w-full md:w-1/2  px-3 mb-6 md:mb-0">
      <TextareaAutosize 
        minRows={2}
        label="Condition Comment"
        placeholder="Condition Comment"
        value={cond_cmnt ? cond_cmnt : ''}
        style={{ width: 200 }}
        onChange = {(e)=> setCond_cmnt(e.target.value)}
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Discount"     
        placeholder="Discount"   
        value={discount}
        onChange = {(e)=> setDiscount(e.target.value)}       
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Price"     
        placeholder="Price"   
        value={price}
        onChange = {(e)=> setPrice(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Weight"     
        placeholder="Weight"
        value={weight}
        onChange = {(e)=> setWeight(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Length"     
        placeholder="Length"
        value={length}
        onChange = {(e)=> setLength(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Width"     
        placeholder="Width"
        value={width}
        onChange = {(e)=> setWidth(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Height"     
        placeholder="Height"   
        value={height}
        onChange = {(e)=> setHeight(e.target.value)}       
      />
    </div>

    <div className="w-full pt-6 px-3 mb-6 md:mb-0">
      <TextareaAutosize 
        minRows={10}
        label="Content"
        placeholder = "Content"
        value={content ? content : ''}
        style={{ width: 500 }}
        onChange = {(e)=> setContent(e.target.value)}
      />
    </div>
  </div>     
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Sku"     
        placeholder="Sku"
        value={sku}
        onChange = {(e)=> setSku(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Translator"     
        placeholder="Translator"
        value={translator}
        onChange = {(e)=> setTranslator(e.target.value)}
      />
    </div>
  </div>    
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Stock"     
        placeholder="Stock"
        value={stock}
        onChange = {(e)=> setStock(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 mt-6"> Featured
      <Checkbox 
        value={feature}
        label="Feature"
        text="Feature"
        placeholder="Feature"
        checked = {feature}
        onChange={e => {setFeature(e.target.checked)}}
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
  <div className="w-full md:w-1/2 px-3 mb-6 mt-6"> New Edition
      <Checkbox 
        value={newProduct}
        label="New Edition"
        text="New Edition"
        placeholder="New Edition"
        checked = {newProduct}
        onChange={e => {setNewProduct(e.target.checked)}}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 mt-6"> Visibility
      <Checkbox 
        value={visibility}
        label="Visibility"
        text="Visibility"
        placeholder="Visibility"
        checked = {visibility}
        onChange={e => {setVisibility(e.target.checked)}}
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Discount Plan</InputLabel>
        <Select
          id="plan"
          value={discountPlan}
          label="Discount Plan"
          onChange={e => {setDiscountPlan(e.target.value)}}
        >
          <MenuItem value={''}>None</MenuItem>
          <MenuItem value={1}>planA</MenuItem>
          <MenuItem value={2}>planB</MenuItem>
          <MenuItem value={3}>planC</MenuItem>
        </Select>
      </FormControl>
    </Box>
    </div> 
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
<Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Availability</InputLabel>
        <Select
          id="plan"
          value={availability}
          label="Availability"
          onChange={e => {setAvailability(e.target.value)}}
        >
          <MenuItem value={'Level1'}>αμεση</MenuItem>
          <MenuItem value={'Level0'}>εξαντλημενο</MenuItem>
        </Select>
      </FormControl>
    </Box>
    </div>
    </div>

      <TagsProductSelect 
      setSelectedTags = {setTags}
      selectedTags = {tags}
      />

      <CategoriesProductSelect 
      setSelectedCats = {setCategories}
      selectedCats = {categories}
      />

<Button 
onClick={
  (e)=>  {
    switch (visibility) {
      case false: 
    if(window.confirm('this product will not be visible. Are you sure you want to proceed')) { submitForm() }
    break;
    default:
      submitForm()
    }
  }
}
className="float-left mt-10 bg-blue-200 w-full pt-4 pb-4"
 > Update Changes 
 </Button>

</form>

    </div>
    <div className="w-full pr-4 pl-4">
<ProductAddImage productId={productId} userInfo={userInfo} notifyImg={notifyImg} setNotifyImg={setNotifyImg} />
<ProductListImage productId={productId} notifyImg={notifyImg}/>
<ProductAddReviewComponent product={product} userInfo={userInfo} />
  </div>
</div>
</div>
          )}
    </>
    )
}

export default ProductEditComponent

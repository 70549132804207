import React, {useState} from 'react'
import Loader from '../components/Loader'
import Product from '../components/Product'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import { makeStyles } from '@material-ui/core/styles';
import PaginationComponent from '../components/subComponents/PaginationComponent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ProductRelatedSwiper from '../components/subComponents/ProductRelatedSwiper';

const SearchPageTheme = ({
  data, 
  handleChangePage,
  clearFields
}) => {
  const [filterValue, setFilterValue] = useState('')

  return (
<div className="h-auto min-h-screen font-astana bg-bgImage">
{ 
data.loading ? <Loader/> : 
!window.location.search &&  !data.loading  ? 
<>
<div className='w-full 
h-20
lg:h-32 
pt-12
z-20
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-white'>
 <BreadCrumb category = {data.keyTitle ? data.keyTitle : 
 data.keyAuthor ? data.keyAuthor : 
 data.keyPublisher ? data.keyPublisher:
 data.KeyISBN ? data.keyISBN : ''
 } t = {data.t}/> 
</div>
<div className='py-5 px-20 grid gap-2 grid-cols-1 md:grid-cols-2 lg:gap-4 lg:grid-cols-4'>
<Autocomplete
      className='w-full'
      multiple
      filterOptions={(x)=> x}
      id="size-small-outlined-multi"
      size="medium"
      options={data.results ? data.results : ''}
      onChange={(event, filterValue) => {data.history.push(`/product/${filterValue[0].slug}`)}}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={data.t('title')}
          placeholder=""
          name="value"
          onChange={(e)=>{data.setKeyTitle(e.target.value)}}
        />

      )}
    />
   <Autocomplete
      className='w-full'
      multiple
      filterOptions={(x)=> x}
      id="size-small-outlined-multi"
      size="medium"
      options={data.results ? data.results : ''}
      onChange={(event, filterValue) => {setFilterValue(filterValue); filterValue[0] && data.setKeyAuthor(filterValue[0].author)}}
      getOptionLabel={(option) => option.author}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={data.t('author')}
          placeholder=""
          name="value"
          onChange={(e)=>{data.setKeyAuthor(e.target.value)}}
        />
      )}
    /> 
       <Autocomplete
      className='full'
      multiple
      filterOptions={(x)=> x}
      id="size-small-outlined-multi"
      size="medium"
      options={data.results ? data.results : ''}
      onChange={(event, filterValue) => {setFilterValue(filterValue); filterValue[0] && data.setKeyPublisher(filterValue[0].publisher)}}
      getOptionLabel={(option) => option.publisher}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={data.t('publisher')}
          placeholder=""
          name="value"
          onChange={(e)=>{data.setKeyPublisher(e.target.value)}}
        />
      )}
    /> 
     <Autocomplete
      className='full'
      multiple
      filterOptions={(x)=> x}
      id="size-small-outlined-multi"
      size="medium"
      options={data.results ? data.results : ''}
      onChange={(event, filterValue) =>
      {setFilterValue(filterValue); filterValue[0] && data.setKeyISBN(filterValue[0].isbn_no)}}
      getOptionLabel={(option) => option.isbn_no}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="ISBN"
          placeholder=""
          name="value"
          onChange={(e)=>{data.setKeyISBN(e.target.value)}}
        />
      )}
    /> 
    <button onClick={(e)=> {clearFields(e)}} className='px-1'> {data.t('clear')} </button>    
</div>
<div className="
  grid grid-cols-1 md:grid-cols-2 lg lg:grid-cols-3
 h-auto
 px-1
 md:px-[2vh]">
{data.results.length> 0 && data.results.length < 15 && data.results.map( (product) => (
      <div className='w-full flex h-auto'>
              <ProductRelatedSwiper key={product.id} product={product}/> 
      </div>
      ))
}
</div>
</> :
<>
<div className='w-full 
h-20
lg:h-32 
pt-12
z-20
lg:pt-20 
px-[4vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-white'>
 <BreadCrumb category = {data.keyTitle} t = {data.t}/> 
{ data.results &&
 <PaginationComponent data = {data} handleChangePage = {handleChangePage}/>
}
</div>

<div className="
 grid grid-cols-1 md:grid-cols-2 lg lg:grid-cols-3
 h-screen
 -mt-32
 gap-2
 md:gap-4
 w-auto
 px-1
 md:px-[2vh]
 lg:px-[10vh]">
{ data.results && data.results.length > 0 ? data.results.map((product) => 
( <Product 
 t = {data.t}
 history={data.history} 
 key={product.id} 
 product={product}/>
 )) : 
<p className='text-sm md:text-base pt-40'> {data.t('notFound')}</p>
}
</div>
</>
}

</div>
  )
}

export default SearchPageTheme
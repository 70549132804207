import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationGR from "./locales/gr/translation.json";


//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  gr: {
    translation: translationGR,
  },
};

//i18N Initialization
const DETECTION_OPTIONS = {
  order: ['localStorage','navigator'],
  caches: ['localStorage']
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        resources,
        detection: DETECTION_OPTIONS,
        lng: 'gr',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
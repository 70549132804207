import axios from "axios"
import {
     PRODUCT_LIST_FAIL,
     PRODUCT_LIST_SUCCESS, 
     PRODUCT_LIST_REQUEST,
     PRODUCT_DETAILS_FAIL,
     PRODUCT_DETAILS_SUCCESS,
     PRODUCT_DETAILS_REQUEST,
     PRODUCT_CREATE_SUCCESS,
     PRODUCT_CREATE_FAIL,
     PRODUCT_CREATE_REQUEST,
     PRODUCT_DELETE_SUCCESS,
     PRODUCT_DELETE_REQUEST,
     PRODUCT_DELETE_FAIL,
     PRODUCT_REVIEW_SUCCESS,
     PRODUCT_REVIEW_RESET,
     PRODUCT_REVIEW_FAIL,
     PRODUCT_REVIEW_REQUEST,
     PRODUCT_UPDATE_REQUEST,
     PRODUCT_UPDATE_SUCCESS,
     PRODUCT_UPDATE_FAIL,
     } from "../constants/productConstants"

     export const listProducts = (keyword='') => async (dispatch) => {
        try {
            dispatch({ type: 
                PRODUCT_LIST_REQUEST})

            const {data} = await axios.get(`/api/products?keyword=${keyword}`)

            dispatch({ 
                type: PRODUCT_LIST_SUCCESS,
                payload: data
            })
        } catch (error) {
            dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error.response.data.message
            })
            
        }
     }

     export const listProductDetails = (slug) => async (dispatch) => {
        try {
            dispatch({ type: 
                PRODUCT_DETAILS_REQUEST})

            const {data} = await axios.get(`/api/product/${slug}`)
            dispatch({ 
                type: PRODUCT_DETAILS_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error
            })
            
        }
     }

     export const deleteProduct = (id) => async (dispatch, getState) => {
        try {
            dispatch({ type: 
                PRODUCT_DELETE_REQUEST})

                const {
                    userLogin: {userInfo},
                } = getState()
    
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.data.token}`,
                    },
                }       

            await axios.delete(`/api/products/${id}`, config)

            dispatch({ 
                type: PRODUCT_DELETE_SUCCESS
            })
        } catch (error) {
            dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload: error.response.data.message
            })
            
        }
     }

   export const createProduct = (product) => async (dispatch, getState) => {
        try {
            dispatch({ type: 
                PRODUCT_CREATE_REQUEST})

            const {
                userLogin: {userInfo},
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.data.token}`,
                },
            }

            const {data} = await axios.post(`/api/products`, product, config)

            dispatch({ 
                type: PRODUCT_CREATE_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: error.response.data.message
            })
            
        }
     }

     export const updateProduct = (product) => async (dispatch, getState) => {
        try {
          dispatch({
            type: PRODUCT_UPDATE_REQUEST,
          })
      
          const {
            userLogin: { userInfo },
          } = getState()
      
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.data.token}`,
            },
          }
      
          const { data } = await axios.put(
            `/api/products/${product.id}`,
            product,
            config
          )
      
          dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            payload: data,
          })
          dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
        } catch (error) {
          const message =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          dispatch({
            type: PRODUCT_UPDATE_FAIL,
            payload: message,
          })
        }
      }
      

     export const createReview = (productId, review) => async (dispatch, getState) => {
        try {
            dispatch({ type: 
                PRODUCT_REVIEW_REQUEST})

            const {
                userLogin: {userInfo},
            } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.data.token}`,
                },
            }

            await axios.post(`/api/products/${productId}/reviews`, review, config)

            dispatch({ 
                type: PRODUCT_REVIEW_SUCCESS
            })
        } catch (error) {
            dispatch({
            type: PRODUCT_REVIEW_FAIL,
            payload: error.response.data.message
            })
            
        }
     }
import React, {useState, useEffect} from 'react'
import Input from '@material-tailwind/react/Input'
import Button from "@material-tailwind/react/Button";
import axios from 'axios';
import Pagination from 'react-js-pagination';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ErrorField } from '../../theme/generalComponents';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const AddressBook = () => {

  const [error, setError] = useState('')
  const [addressBook, setAddressBook] = useState('')
  const [msn, setMsn ] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel_no, setTel_no] = useState('')
  const [address, setAddress] =  useState('')
  const [notify, setNotify] =  useState(false)
  const [pageNum, setPageNum] = useState('')
  const [total, setTotal] = useState('')
  const [current, setCurrent] = useState('')
  const [perpage, setPerPage] = useState('')

  const [keyword, setKeyword] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [resultSearch, setResultSearch ] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,  
          },
          params: {
            keyName:keyword      
          },
        }
        try {    
    const result = await axios.get('api/addressbook/search', config);
    setAddressBook(result.data.data.data)
        } catch (err) {
        setError(err.response.data.message)
      }
}
fetchData()
}, [keyword])

  useEffect(() => {
    const fetchAddresses = async () => {
      const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,  
          }
      }
      try {
      const result = await axios.get(`api/addressbook?page=${pageNum}`, config);
       
      setPerPage(result.data.data.meta.per_page)
      setCurrent(result.data.data.meta.current_page)
      setTotal(result.data.data.meta.total)
      setAddressBook(result.data.data.data)
      } catch (err) {
        setError(err.response.data.message)
      }

    };
    fetchAddresses();
    
  },[notify, msn, pageNum]);

  const submitHandler = (e) => { 
    e.preventDefault()
    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
    const addAddressBook = async () => {
    
      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      const addressBookData = {
        name: name ,
        email: email,
        tel_no: tel_no,
        address: address
      }
    try {  
    await axios.post('/api/addressbook', addressBookData, config)
    } catch (err) {
        setMsn(JSON.parse(err.response.data.message))
    }
      setName('')
      setTel_no('')
      setEmail('')
      setAddress('')
    
    };
    addAddressBook();
    setNotify(!notify)
    }

    

    const deleteHandler = (id) => {
      const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

      const deleteAddressBook = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }
      const resp =  await axios.delete(`api/addressbook/${id}`, config)
        setAddressBook(addressBook.filter((addressBook)=> {return addressBook.id !== id}))
      }
      deleteAddressBook()
      setNotify(!notify)
    }

    const editHandler = (item) => {
        setName(item.name)
        setEmail(item.email)
        setTel_no(item.tel_no)
        setAddress(item.address)
    }

    const updateHandler = (id) => {
      const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
      const addAddressBook = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }
        const addressBookData = {
          name: name ,
          email: email,
          tel_no: tel_no,
          address: address
        }
     try {   
     await axios.put(`/api/addressbook/${id}`, addressBookData, config);
     } catch (err) {
      setMsn(JSON.parse(err.response.data))
     }
        setName('')
        setTel_no('')
        setEmail('')
        setAddress('')
      };
      addAddressBook();
      setNotify(!notify)
    }
    
    const handleClickAway= (e) => {
      setKeyword('')
    }


  return (
    <div className='h-screen'>
    <p className='p-4 pt-8 text-2xl  text-center'> AddressBook</p>
    {error && <ErrorField> {error} </ErrorField>}
    {addressBook && (
    <div className='w-1/4 px-10'>
    <Autocomplete
      multiple
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      filterOptions={(x)=> x}
      id="size-small-outlined-multi"
      size="medium"
      options={addressBook}
      onChange={(event, newValue) => setFilterValue(newValue)}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <ClickAwayListener onClickAway={handleClickAway}>
        <TextField
          {...params}
          variant="outlined"
          label="Search"
          placeholder=""
          name="value"
          onChange={(e)=>{setKeyword(e.target.value)}}
        />
        </ClickAwayListener>
      )}
    />
    </div>
    )}

    {msn && msn.email && (<ErrorField> {msn.email} </ErrorField>)}
    {msn && msn.name && (<ErrorField> {msn.name} </ErrorField>)}

      <form  onSubmit={submitHandler} className="grid grid-cols-2 lg:grid-cols-4 gap-1 justify-center px-10 pt-4">

  <div className=''>
  <Input 
        type="text"
        color="lightBlue"
        size="regular"
        outline={true}
        placeholder="Name"   
        value={name}
        onClick={(e) => setMsn('')}
        onChange = {(e)=> setName(e.target.value)}       
      />
  </div>
  <div className=''>
  <Input 
        type="text"
        color="lightBlue"
        size="regular"
        outline={true}
        placeholder="Email"   
        value={email}
        onClick={(e) => setMsn('')}
        onChange = {(e)=> setEmail(e.target.value)}       
      />  
  </div>
  <div className=''>
  <Input 
        type="text"
        color="lightBlue"
        size="regular"
        outline={true}
        placeholder="Tel"   
        value={tel_no}
        onChange = {(e)=> setTel_no(e.target.value)}       
      />
  </div>
  <div className=''>
  <Input 
        type="text"
        color="lightBlue"
        size="regular"
        outline={true}
        placeholder="Address"   
        value={address}
        onChange = {(e)=> setAddress(e.target.value)}       
      />
  </div>
  <div className='py-8'>
  <button type='submit' className="w-36 text-white uppercase h-20 pt-2 pb-2 bg-blue-600">
        Add Address
  </button>
  </div>
</form>

 {addressBook && (
<div className="p-5 w-full">
  <div className="-my-2">
    <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-4">
      <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
        <table className=" table-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Name
              </th>
              <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Email
              </th>
              <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Telephone No
              </th>
              <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Address
              </th>
              <th scope="col" className="w-1/8 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Delete
              </th>
              <th scope="col" className="w-1/8 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Edit
              </th>
              <th scope="col" className="w-1/8 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Update
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          { (filterValue.length>0 ? addressBook.filter(address => address.name===filterValue[0].name) : addressBook).map((item) => (

            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-normal">
               <div className="text-sm text-gray-500">{item.name}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{item.email}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{item.tel_no}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{item.address}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
              <button 
              onClick={()=>deleteHandler(item.id)}
              className="bg-transparent hover:bg-red-500 
               text-red-700 font-semibold 
               hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                    DELETE
              </button>
              </td> 
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
              <button 
              onClick={()=>editHandler(item)}
              className="bg-transparent hover:bg-green-500 
               text-green-700 font-semibold 
               hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
                    EDIT
              </button>
              </td> 
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
              <button 
              onClick={()=>updateHandler(item.id)}
              className="bg-transparent hover:bg-blue-500 
               text-blue-700 font-semibold 
               hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    UPDATE
              </button>
              </td> 
            </tr>
          ))}

          {
            !addressBook && (<p className='text-3xl'> Empty Address Book</p>)
          }
          </tbody>
        </table>
        { addressBook &&
    <Pagination 
    activePage={current}
    totalItemsCount={total}
    itemsCountPerPage={perpage}
    onChange={(pageNum)=> setPageNum(pageNum)}
    itemClass="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
    linkClass='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
    firstPageText='First'
    lastPageText='Last'
    />
}
      </div>
    </div>
  </div>
</div>
            )} 
</div>
  )
}

export default AddressBook
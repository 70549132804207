import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {listProducts} from '../actions/productActions.js';
import { PublisherPageTheme } from '../theme/PublisherPageTheme.js';
import BreadCrumb from '../components/subComponents/BreadCrumb.js';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Terms from '../components/termsOfUse/Terms'
import PersonalData from '../components/termsOfUse/PersonalData'
import DeliveryCost from '../components/termsOfUse/DeliveryCost'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



export default function PublisherPage() {

  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

return (
  <div className='h-screen bg-white font-astana '>
  <div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
<BreadCrumb category = {t('aboutus')} t={t}  /> 
</div>
<div className="w-full font-myriad tracking-wider text-gray-800 px-[2vh] 
lg:px-[10vh] pt-10 md:w-1/2">
  <p  className = ''>
  Η βιβλιοθήκη του Ναυτίλου ξεκίνησε το 2000 από τους Δημήτρη Πιπίνη, Γιώργο Τσάκαλο και Κώστα Χριστόπουλο.
</p>

<p className='pt-4 md:pt-8'>
Σκοπός του βιβλιοπωλείου ήταν και είναι να προσφέρει στο κοινό επιλεγμένες εκδόσεις, νέες και παλαιές, σε όλους τους τομείς, που μπορεί να ενδιαφέρουν ένα ζωναντό κοινό.
</p>
<p className='pt-4 md:pt-8'>
Παράλληλα λειτουργεί και σαν χώρος συνάντησης παλαιών και νεότερων φίλων που έρχονται γα να ενημεωθούν για νέες και παλαιές εκδόσεις.
  </p>
  <p className='pt-4 md:pt-8'>
  Στην τρίτη δεκαετία του βιβλιοπωλείου γίνεται μια προσπάθεια να δημιουργηθεί ιστοσελίδα, που θα βοηθήσει να γίνει γνωστό και σε ευρύτερο κοινό.
  </p>

  <div className='w-full flex flex-col font-astanaM pt-8'>
    <p className='font-astana'> Επικοινωνία</p>
    <p className=''>Χαριλάου Τρικούπη 28, Αθήνα, Ελλάδα | 21 0361 6204 | naftilosbooks@gmail.com </p>
    
    <a href="https://www.facebook.com/profile.php?id=100057407681795" className=' my-2 w-4 h-auto'><svg xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 1714 3333" shape-rendering="geometricPrecision" 
    text-rendering="geometricPrecision" image-rendering="optimizeQuality" 
    fill-rule="evenodd" clip-rule="evenodd">
      <path d="M1163 607h456V94h-459c-182 0-348 74-467 194-120 120-194 285-194 467v304c0 26-21 47-47 47H94v513h358c26 0 47 21 47 47v1572h513V1666c0-26 21-47 47-47h466l85-513h-552c-26 0-47-21-47-47V755c0-40 17-78 44-105s64-44 105-44h2zm504 94h-508c-14 0-27 7-36 16-10 10-16 23-16 38v256h560c3 0 5 0 8 1 26 4 43 29 39 54l-101 604c-2 24-23 42-47 42h-459v1572c0 26-21 47-47 47H453c-26 0-47-21-47-47V1712H48c-26 0-47-21-47-47v-607c0-26 21-47 47-47h358V755c0-208 85-397 222-534S954-1 1162-1h506c26 0 47 21 47 47v607c0 26-21 47-47 47z" fill-rule="nonzero"/>
      </svg>
      </a>
  </div>
  <ul className='flex font-astanaM flex-col mt-2 w-full md:w-1/2'>
        <p className='font-astana'> Ωράριο Λειτουργίας</p>
        <li className='flex justify-between'> <p>Δευτέρα</p> <p>9:00 π.μ. - 5:00 μ.μ.</p> </li>
        <li className='flex justify-between'> <p>Τρίτη</p> <p>9:00 π.μ. - 8:00 μ.μ.</p> </li>
        <li className='flex justify-between'> <p>Τετάρτη</p> <p>9:00 π.μ. - 5:00 μ.μ.</p> </li>
        <li className='flex justify-between'> <p>Πέμπτη</p> <p>9:00 π.μ. - 8:00 μ.μ.</p> </li>
        <li className='flex justify-between'> <p>Παρασκευή</p> <p>9:00 π.μ. - 8:00 μ.μ.</p> </li>
        <li className='flex justify-between'> <p>Σάββατο</p> <p>9:00 π.μ. - 3:00 μ.μ.</p> </li>
        <li className='flex justify-between'> <p>Κυριακή</p> <p>κλειστά</p> </li>

  </ul>

  <div className=' mt-10'>
  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Οροι Χρήσης</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
        <Terms/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Χρήση - Προσωπικά Δεδομένα</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <PersonalData/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Αποστολή Δεμάτων</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
   <DeliveryCost/>
          </Typography>
        </AccordionDetails>
      </Accordion>
  </div>

</div>
</div>
    )
}

import React, {useState, useRef, useEffect, getState} from 'react'
import Input from "@material-tailwind/react/Input";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux' 
import Loader from './Loader';


export const SearchBiblionet = ({fetchedData, setFetchedData, handleChoice}) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [keyword, setKeyword] = useState('')
    const [showOk, setShowOk] = useState(false)
    const refOutside = useRef()
    const userLogin = useSelector((state)=> state.userLogin)
    const {userInfo} = userLogin
    // const [searchField, setSearchField ] = useState('title')

    const submitSearch = (field) => {
        setLoading(true)
        const getData = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.data.token}`,
                },
                params: {
                    keyword : keyword,
                    field : field
                }
            } 
            try {
          const {data} = await axios.get(`/api/biblionet`, config);
          setFetchedData(data);
          setLoading(false)
        }catch (err) {
            setError(err.response)
            setLoading(false)

          }
    }
        getData();
      };

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showOk && refOutside.current && !refOutside.current.contains(e.target)) {
              setShowOk(false)
              setKeyword('')
            }
          }
      
          document.addEventListener("mousedown", checkIfClickedOutside)
      
          return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
          }
    }, [fetchedData])

    return (
        <>
    <div ref={refOutside} className="flex items-center  border-teal-500 py-2 ">
        <Input
        value={keyword}
        type="text" 
        color="blueGray"
        onClick={(e)=>setShowOk(true)}
        onChange={(e)=>setKeyword(e.target.value)}  
        placeholder={t('search Biblionet')}
        />
        <div className='px-2'></div>
        { showOk && ( 
        <div className='flex flex-col'>
        <button onClick={(e)=>{submitSearch('title')}} className=" mb-4 flex-shrink-0 bg-transparent hover:bg-gray-800  text-md hover:text-white border  border-gray-300 text-theme-base-color2 px-3 rounded">
            Title
        </button>
        <button onClick={(e)=>{submitSearch('publisher')}} className=" mb-4 flex-shrink-0 bg-transparent hover:bg-gray-800 text-md hover:text-white border  border-gray-300 text-theme-base-color2 px-3 rounded">
            Publisher
        </button>
        <button onClick={(e)=>{submitSearch('author')}} className="flex-shrink-0 bg-transparent hover:bg-gray-800 text-md hover:text-white border  border-gray-300 text-theme-base-color2 px-3 rounded">
            Author
        </button>     
        </div>
        )}

    </div>
    { loading ? <Loader/> : fetchedData && (
        fetchedData.map( (book) =>  
            <button onClick={()=>handleChoice(book)} 
            className='my-2 border-2 border-gray-800 hover:border-red-600 w-full bg-gray-200' key={book.isbn13}>
            <div className="flex-shrink-0 h-10 w-auto">
             <img className="m-2 w-10 rounded-full" src={book.cover} alt=""/>
             </div> 
             {book.distinctive_title} ||  {book.pub_name} || {book.contr_role.role0 === "Συγγραφέας" ? book.contr_name.role0 : book.contr_name.role1 } || {book.category.role0} || {book.publishing_status}
            </button>
        )
        )
        }
    { !loading && fetchedData.length == 0 && 
    (<p> Not found </p> )
    }
        { !loading && error && 
    (<p> {error} </p> )
    }
</>
    )
}
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AnimatePresence,
  motion } from 'framer-motion/dist/framer-motion';
  import Textarea from "@material-tailwind/react/Textarea";

  const backdrop = {
    visible: { opacity : 1},
    hidden: {opacity: 0}
}

const modal = {
    hidden: {
        y:"100px",
        opacity:0
    },
    visible: {
        y:"100px",
        opacity:1,
        transition: { delay:0.5}
    }
}

// Banner Modal

const Modal = ({showModal, setShowModal, setUpdate, description, setDescription, image}) => {

    useEffect(()=> {
      setDescription(image.image_description)
    }, [])

   const handleSubmission = (e) => {
     e.preventDefault()
     setShowModal(false)
     
     const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
     const changeDescription = async () => {
       const config = {
           headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
           }
       }
       const data = {
         image_description: description
       }
       await axios.put(`api/banner/${image.id}`, data,  config)
        setUpdate(description) 
     }
     changeDescription()
   }

  return (
    <>
    <AnimatePresence exitBeforeEnter>
    { showModal && (
        <motion.div className="backdrop" 
        variants={backdrop}
        initial = "hidden"
        animate="visible"
        exit="hidden"
        >

      <motion.div className="modal"
                    variants={modal}
                    initial = "hidden"
                    animate="visible"
      >
<form  onSubmit={handleSubmission}>
      <Textarea
            value={description}
            onChange =  {(e)=> setDescription(e.target.value)}
            color="lightBlue"
            size="sm"
            outline={true}
            placeholder= "Description"
        />
     <button
     type="submit" 
     className="border-2 px-4 py-2 w-1/2 uppercase text-xl border-gray-800 hover:border-red-600" > Save </button>
</form>
      </motion.div>

      </motion.div>
    )}
</AnimatePresence>
</>
  )
}

// Banner Item

const BannerItem = ({image, setImages, images , setUpdate}) => {
  const [status, setStatus] = useState(image.isActive)
  const [showModal, setShowModal] = useState(false)
  const [description, setDescription] = useState('')

  useEffect(()=>
  {
  }, [image])

  const handleDelete = (id) => {
    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
    const deleteImage = async () => {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      await axios.delete(`api/banner/${id}`, config)
      setImages(images.filter((image)=> {return image.id !== id}))
    }
    deleteImage()
  }

  const toggleActivity = (id) => {
    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
    const changeStatus = async () => {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      const data = {
        isActive:!status
      }
      await axios.put(`api/banner/${id}`, data,  config)
      setStatus(!status)
    }
    changeStatus()
  }

  return (
    <>
    { showModal && ( 
  <Modal 
    showModal={showModal}
    description = {description}
    setDescription = {setDescription}
    setShowModal={setShowModal} 
    image={image}
    setUpdate = {setUpdate}
    /> 
    )}
                <tr key={image.id}>
              <td className="px-6 py-4 whitespace-normal">
                  <div className="flex-shrink-0 h-10 w-10">
                    <img className="h-10 w-10 rounded-full" src={`${process.env.REACT_APP_API_ENDPOINT}storage/${image.url}`} alt=""/>
                  </div>
              </td>
              <td  className="px-6 py-4 whitespace-nowrap">
                <div onClick={(e)=> setShowModal(true)} className="text-sm text-gray-500">{image.image_description && image.image_description.substring(0,15)} ...</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div onClick={ (e) => { toggleActivity(image.id) }} className={`hover:bg-white hover:text-gray-900 font-semibold text-center text-white py-2 px-2 border 
          ${status ?   'bg-blue-700 hover:border-blue-700' :
                               'bg-red-700 hover:border-red-700' }`}>{status ? 'Active' : 'Inactive'}</div>
              </td>
              <td className="pl-6 py-4 whitespace-nowrap text-left text-sm font-medium">
              <button onClick={(e) => handleDelete(image.id)}  className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                    DELETE
            </button>
              </td>             
            </tr>
    </>
  )
}

// Banner LIST

const BannerListImage = ({ addEvent}) => {

    const [images, setImages] = useState([''])
    const [update, setUpdate] = useState('')

    useEffect(() => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
        const fetchImages = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          const result = await axios.get(
            `api/banners`, config
          );
          setImages(result.data);
        };
        fetchImages();
      },[setImages, update, addEvent]);






    return (
        <>
  <div className="p-5 w-full">
  <div className="-my-2">
    <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-4">
      <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
        <table className=" table-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Image
              </th>
              <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Description
              </th>

              <th scope="col" className="w-1/8 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Active
              </th>
              <th scope="col" className="w-1/8 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
              {images.map(image => ( 
                  <BannerItem key={image.id}  setUpdate={setUpdate} image={image} images={images} setImages={setImages}/>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</>
    )
}

export default BannerListImage
import React from 'react'
import Loader from '../Loader'

const CreateProductButton = ({createProductHandler, successCreate, errorCreate, loadingCreate}) => {
  return (
        <>
         <div className="p-5 h-24 sticky top-0 w-1/4"> 
            <button onClick={createProductHandler} 
            className={`w-full text-lg 
            bg-transparent hover:bg-gray-500 text-gray-700 
            font-semibold hover:text-white py-3 px-4 border 
            border-gray-500 
            hover:border-transparent
            rounded
            ${ successCreate ? 'bg-green-300' : errorCreate ? 'bg-red-200' : '' } 
            `}>
            {
             <p>CREATE PRODUCT</p>}
            { 
            loadingCreate && <Loader/>
            }
            </button>
            </div>
        </>
    )
}

export default CreateProductButton
import React from 'react'
import axios from 'axios';

const SearchHidden = ({
    userInfo,
    setProducts,
    pageNum,
    setPerPage,
    setCurrent,
    setTotal, 
    setLoadingProducts,
    setError
  }) => {
  
    const hidden = () => {
      const getData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        } 
        try {
          const result = await axios.get(`api/searchHidden?page=${pageNum}`, config);
          setPerPage(result.data.data.meta.per_page)
          setCurrent(result.data.data.meta.current_page)
          setTotal(result.data.data.meta.total)
          setProducts(result.data.data.data)
          setLoadingProducts(false)
          } catch (err) {
            setError(err.response)
          }
    };
    getData();
    }
    
    return (
      <button onClick = {(e)=> hidden()}
      className='m-4 p-2 text-xl border-2 border-red-500 hover:bg-gray-800 hover:text-white'> SearchHidden 
      </button>
    )
  }

export default SearchHidden
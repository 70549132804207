import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import  {listCategories} from '../actions/categoryActions'
import { DropdownCategories } from './DropdownCategories'
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CategoriesProductSelectItem from './CategoriesProductSelectItem';

const CategoriesProductSelect = ({selectedCats, setSelectedCats}) => {
    
    const dispatch = useDispatch()
    const categoryList = useSelector((state) => state.categoryList)
    const { loading, error, categories } = categoryList
    const history = useHistory();
    
    useEffect(()=>{
        dispatch(listCategories())
      }, [selectedCats])

      const addingCats = (cat) =>  {
        
        if(selectedCats.filter( (item) => item.id === cat.id).length===0 && cat.id!=='') 
        { 
            let newArray = [...selectedCats, cat]
            setSelectedCats(newArray)
            }
    }

    const deleteCats = (cat) => {
      let newArray = selectedCats.filter((item)=> item.id !== cat.id)
      setSelectedCats(newArray)
    }

 return (
    <>
  {loading === false && selectedCats && (
    <ul className=''>
     { categories.map( (category) =>  (
       category.parent_id == 0 && 
       <CategoriesProductSelectItem 
       key={category.id}
       addingCats={addingCats}
       deleteCats={deleteCats}
       category={category}
       selectedCats={selectedCats}
       />
       ))}
    </ul>
  )}
    </>
      );
    }
    

export default CategoriesProductSelect
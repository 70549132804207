import React, { useState, useRef, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import { listProducts,
   deleteProduct,
    createProduct } from '../actions/productActions.js'
import  {SearchBiblionet}  from '../components/SeacrhBiblionet';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants.js';
import ProductEditComponent from '../components/ProductEditComponent.js';
import SearchAutoComp from '../components/SearchAutoComp.js';
import axios from 'axios';
import ProductListItem from '../components/dashboard/ProductListItem.js';
import Pagination from '@mui/material/Pagination';
import SearchDuplicates from '../components/dashboard/SearchDuplicates.js';
import { ArrowNarrowDownIcon } from '@heroicons/react/solid'
import CreateProductButton from '../components/dashboard/CreateProductButton.js';
import SearchHidden from '../components/dashboard/SearchHidden.js';

export const ProductListPage = ({history, match}) => {

    const dispatch = useDispatch()


    const productList = useSelector((state) => state.productList )
    const userLogin = useSelector((state)=> state.userLogin)
    const [filterValue, setFilterValue] = useState('')
    const [fetchedData, setFetchedData] = useState([]);
    const [filter, setFilter] = useState( JSON.parse(localStorage.getItem("filterProds")) ? 
    JSON.parse(localStorage.getItem("filterProds")) :
    'created_at')

    const [title,setTitle] = useState('title')
    const [translator,setTranslator] = useState('translator')
    const [author,setAuthor] = useState('author')
    const [year_published,setYear_published] = useState('year')
    const [published_at,setPublished_at] = useState('published place')
    const [publisher,setPublisher] = useState('publisher')
    const [pages_no,setPages_no] = useState('pages')
    const [isbn_no,setIsbn_no] = useState('isbn')
    const [condition,setCondition] = useState('condition')
    const [cond_cmnt,setCond_cmnt] = useState('Condition Comment')
    const [availability,setAvailability] = useState('availability')
    const [stock,setStock] = useState(10)
    const [price,setPrice] = useState('price')
    const [weight,setWeight] = useState(0)
    const [length,setLength] = useState(0)
    const [width,setWidth] = useState(0)
    const [height,setHeight] = useState(0)
    const [sku,setSku] = useState('sku number')
    const [content,setContent] = useState('Content')
    const [discount,setDiscount] = useState(0)
    const [discountPlan, setDiscountPlan] = useState(1)
    const [feature,setFeature] = useState(true)
    const [visibility,setVisibility] = useState(false)
    const [newProduct,setNewProduct] = useState(false)   
    const [image, setImage] = useState('')
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [products, setProducts] = useState('')
    const [error, setError] = useState('')


 //pagination
    const [pageNum, setPageNum] = useState( JSON.parse(localStorage.getItem("pageNumProds")) ? 
    JSON.parse(localStorage.getItem("pageNumProds")) :
    '')
    const [total, setTotal] = useState('')
    const [current, setCurrent] = useState('')
    const [perpage, setPerPage] = useState('')

  // product delete
    const productDelete = useSelector((state) => state.productDelete )
    const {
      loading: loadingDelete,
      success: successDelete,
      error: errorDelete
      } = productDelete

  // product create
  const [openAddProductModal, setOpenAddProductModal] = useState(false)

    const productCreate = useSelector((state) => state.productCreate)
    
    const {
      loading: loadingCreate,
      error: errorCreate,
      success: successCreate,
      product: createdProduct,
    } = productCreate
    
    // product list
    const {userInfo} = userLogin

    const getAllProducts = async (filter) => {
      const config = {
          headers: {
              'Content-Type': 'application/json',
          },
          params: {
            filter : filter ? filter : 'title'
          }
      }
      try {
      const result = await axios.get(`api/allproducts?page=${pageNum}`, config);
      setPerPage(result.data.data.meta.per_page)
      setCurrent(result.data.data.meta.current_page)
      setTotal(result.data.data.meta.total)
      setProducts(result.data.data.data)
      setLoadingProducts(false)
      } catch (err) {
        setError(err.response)
      }
    };

    
    useEffect( () => {
        dispatch({type: PRODUCT_CREATE_RESET })
        if(!userInfo || userInfo.data.role!== 1) {
          history.push('/login')
        }    

        else {
        window.localStorage.setItem('filterProds', JSON.stringify(filter))
        window.localStorage.setItem('pageNumProds', JSON.stringify(pageNum))
        getAllProducts(filter);
        }
    }, [
      dispatch,
      userInfo,
      successDelete,
      createdProduct,
      filterValue,
      pageNum,
      filter
    ])


    const deleteHandler = (id) => {
      if(window.confirm('Confirm this product delete')){
      dispatch(deleteProduct(id))
    }
    }

    const createProductHandler = () => {
      dispatch(createProduct({
        title,
        translator,
        author,
        year_published,
        published_at,
        publisher,
        pages_no,
        isbn_no,
        condition,
        cond_cmnt,
        availability,
        stock,
        price,
        weight,
        length,
        width,
        height,
        sku,
        content,
        discount,
        feature,
        newProduct,
        visibility,
        image
      }))
    }



    const handleChoice = (book) => {
      dispatch(createProduct({
        title : book.distinctive_title,
        translator : book.contr_role.role1 === "Μετάφραση" ? book.contr_name.role1 : 
        book.contr_role.role2 === "Μετάφραση" ? book.contr_name.role2  : 'translator',
        author : book.contr_role.role0 === "Συγγραφέας" ? book.contr_name.role0 
        : book.contr_role.role1 === "Συγγραφέας" ? book.contr_name.role1 : " author",
        year_published : book.pub_year ? book.pub_year : 'year',
        published_at :book.city ? book.city : 'city',
        publisher : book.pub_name ? book.pub_name : 'publisher',
        pages_no : book.pages ? book.pages : 'pages',
        isbn_no : book.isbn13 ? book.isbn13 : 'isbn',
        condition : 'Level1',
        cond_cmnt,
        availability: 'Level1',
        stock,
        price :book.price ? book.price : 'price',
        weight :book.weight ? book.weight : 'weight',
        length: book.lenghth ? book.length : 'length',
        width :book.width ? book.width : 'width',
        height :book.height ? book.height : 'height',
        sku,
        content :book.description ? book.description : 'content',
        discount,
        discountPlan: discountPlan,
        feature: 1,
        newProduct: 0,
        visibility: 0,
        image : book.cover ? book.cover : ''
      }))
    }

   const handleChangePage = (event, newPage) => {
    setPageNum(newPage);
  };

   return (
        <div>
          {  !openAddProductModal && (
            <div className='flex'>
            <div className="p-5 w-1/4"> 
            <SearchAutoComp
            labelOpts='products'
            userData=''
            apiUrl = '/api/quickSearch'
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
            />
            </div>
            <CreateProductButton 
            createProductHandler = {createProductHandler} 
            successCreate={successCreate} 
            errorCreate={errorCreate}
            loadingCreate={loadingCreate} 
            />
            <div className="p-5 w-2/4"> 
            <div className="w-full text-lg bg-transparent py-2 px-4 border border-gray-500">
            <SearchBiblionet 
            fetchedData={fetchedData} 
            setFetchedData={setFetchedData} 
            handleChoice={handleChoice}
            /> 
            </div>
            </div>

        </div>
          )}

{loadingProducts===false && !openAddProductModal && (
<div className="p-2">
  <div className="-my-1">
    <div className="py-2 align-middle inline-block min-w-screen sm:px-2 lg:px-4">
           <button  onClick={(e)=>{setFilter('created_at'); setPageNum('')}} scope="col" className="w-1/6 mb-4 py-3 text-md font-medium text-gray-500 border-2 border-gray-500 uppercase tracking-wide">
                Sort Date Creation
              </button>
      <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <Pagination
      count={Math.ceil(total/50)}
      page={pageNum}
      onChange={handleChangePage}
    />
    
        <table className=" table-auto divide-y px-2 w-full divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th   scope="col" className="w-40 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                <h6>TITLE</h6>
                <button>
                <ArrowNarrowDownIcon onClick={(e)=>{setFilter('title'); setPageNum('')}} className='h-4'/>
                </button>
              </th>
              <th scope="col" className="w-40  px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                <h6>Author</h6>
                <button>
                <ArrowNarrowDownIcon onClick={(e)=>{setFilter('author'); setPageNum('') }} className='h-4'/>
                </button>
              </th>
              <th onClick={(e)=>{setFilter('publisher'); setPageNum('') }} scope="col" className="w-40  px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                <h6>Publisher</h6>
                <button>
                <ArrowNarrowDownIcon onClick={(e)=>{setFilter('publisher'); setPageNum('') }} className='h-4'/> 
                </button>
              </th>
              <th scope="col" className="w-40 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Price
              </th>
              <th   scope="col" className="w-40 relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                <h6>Discount Plan</h6>
                <button>
                  <ArrowNarrowDownIcon onClick={(e)=>{setFilter('discount_id'); setPageNum('')}} className='h-4'/>
                </button>
              </th>
              <th scope="col" className="w-40  relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Discount
              </th>
              <th  scope="col" className="w-40  px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                <h6>Stock</h6>
                <button>
                  <ArrowNarrowDownIcon onClick={(e)=>{setFilter('stock'); setPageNum('')}} className='h-4' />
                </button>
              </th>
              <th scope="col" className="w-40  relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Delete
              </th>
              <th scope="col" className="w-40  relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Edit
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
              {(filterValue.length > 0 ? filterValue : products).map(product => (
           <ProductListItem  key={product.id} deleteHandler={deleteHandler} product={product}/>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <SearchDuplicates
  userInfo={userInfo}
  setProducts={setProducts}
  pageNum={pageNum}
  setPerPage={setPerPage}
  setCurrent={setCurrent}
  setTotal ={setTotal}
  setLoadingProducts = {setLoadingProducts}
  setError={setError}
  />

<SearchHidden
  userInfo={userInfo}
  setProducts={setProducts}
  pageNum={pageNum}
  setPerPage={setPerPage}
  setCurrent={setCurrent}
  setTotal ={setTotal}
  setLoadingProducts = {setLoadingProducts}
  setError={setError}
  />
</div>
    )}            
        </div>
    )
}

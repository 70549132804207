import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore  from 'swiper';
import ProductRelatedSwiper from './subComponents/ProductRelatedSwiper'

const ProductSwiper = ({products}) => {

  return (
    <div className='py-2 w-full h-full px-2 justify-content-center bg-transparent'>
<Swiper
    loop={false}
    autoplay={{
      delay: 2000,
  }}
    grabCursor={true}
    className = 'produi'
    breakpoints={{

        480: {
          slidesPerView: 1,
          spaceBetween:5
        },
        768: {
            slidesPerView: 2,
            spaceBetween:8
          },
        1280: {
            slidesPerView: 4,
            spaceBetween:10
          },   

      }}
>
    {products.map( (product) => (    
 <SwiperSlide className='h-full' key={product.id}>
 <ProductRelatedSwiper className='h-full'  key={product.id} product={product}/>
 </SwiperSlide>
))}

</Swiper>        
        </div>
  )
}

export default ProductSwiper
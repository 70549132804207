import React from 'react'

const LanguageSwitcher = ({changeLanguage}) => {

  return (
    <div className="flex font-astana mt-1 flex-row">
    <button className='text-xs md:text-sm lg:text-base px-1'  onClick={() => changeLanguage("gr")}>ελ.</button>
    <button className='text-xs md:text-sm lg:text-base px-1'  onClick={() => changeLanguage("en")}>en.</button>
   </div>
    )
}

export default LanguageSwitcher
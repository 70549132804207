import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {listProducts} from '../actions/productActions.js';
import { useTranslation } from "react-i18next";
import Product from '../components/Product.js';
import { GlobalButton } from '../theme/generalComponents.js';
import Loader from './Loader.js';
import axios from 'axios';
import useWindowDimensions from '../utils/useWindowDimensions.js';
import ProductPageCategoryTheme from '../theme/ProductPageCategoryTheme.js';

const ShowProducts = ({match, history}) => {

    const { t, i18n } = useTranslation();
    const [param, setParam] =  useState(match.params.slug); 
    const [pageNum, setPageNum] = useState(history.action === 'POP' ? Math.floor(localStorage.getItem("current")) : '')
    const [results, setResults] = useState('')
    const [total, setTotal] = useState('')
    const [current, setCurrent] = useState('')
    const [perPage, setPerPage] = useState('')
    const [loading, setLoading] = useState(true)
    const {width} = useWindowDimensions()

    let paginationSize = 0
    switch(true) {
      case (width < 768):
       paginationSize = 1
      break;
      case (width >= 768 && width < 1024):
       paginationSize = 2
      break;
      case (width >=  1024 && width < 1280):
      paginationSize = 8
      case (width >=  1280):
        paginationSize = 10
    }


    useEffect(()=> {
      let keyword = ''
      switch(true) {
        case (param === 'featured') :
          keyword = 'feature'
        break;
         case (param === 'new-products') :
          keyword = 'newProduct'
         break;
         default: 
         keyword = 'newList'
       }

      const fetchData = async (param) => {
       const {data} = await axios.get(`/api/products/${match.params.slug}?page=${pageNum}`, {
        params: { keyword:keyword, paginationSize:paginationSize}});
            setPerPage(data.data.meta.per_page)
            setCurrent(data.data.meta.current_page)
            setTotal(data.data.meta.total)
            setResults(data.data.data)
            setParam(match.params.slug)
            setLoading(false)
        }
        fetchData()
    },[pageNum])

        const handleChangePage = (event, newPage) => {
          setPageNum(newPage);
        };


        const data = {
          category: param,
          results: results,
          t:t,
          loading: loading,
          param:param,
          total:total,
          pageNum:pageNum,
          perPage:perPage,
          history: history
        }


        localStorage.setItem("current", current)
  
        return (
          <>
              <ProductPageCategoryTheme 
              data = {data} 
              handleChangePage={handleChangePage}/>
          </>
            )
}

export default ShowProducts
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BannerAddImage from './BannerAddImage';
import BannerListImage from './BannerListImage';

const BannerAdmin = () => {

const userLogin = useSelector((state)=> state.userLogin)
const {userInfo} = userLogin
const [addEvent, setAddEvent] = useState(false)

  return (
  <>
<p  className="bg-white shadow-md rounded text-xl px-8 pt-6 pb-8 mb-4"> Banner Images </p>
<BannerAddImage userInfo={userInfo} addEvent={addEvent} setAddEvent={setAddEvent} />
<BannerListImage userInfo={userInfo} addEvent={addEvent}  />
  </>
  );
};

export default BannerAdmin;

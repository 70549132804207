import tw from "tailwind-styled-components"


export const ErrorField = tw.div`
text-red-800
text-sm
lg:text-lg
m-1
`

export const GlobalButton = tw.button`
my-2
p-2
border-2
rounded-3xl
bg-transparent 
border-gray-800 
hover:bg-gray-700
hover:text-white
text-gray-900
text-lg
md:text-xl
uppercase
focus:outline-none
focus:ring
focus:ring-gray-600
`
export const PageTitle = tw.div`
w-full 
py-10
px-8
text-center  
tracking-wider  
text-base
md:text-lg 
lg:text-xl 
xl:text-2xl
capitalize
`

import React from 'react'
import Header from '../components/Header'
import Bottom from '../components/Bottom'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { PageTitle } from './generalComponents';
import { useHistory } from "react-router-dom";
import BreadCrumb from '../components/subComponents/BreadCrumb';



export const OrderPageTheme = ({
  order, 
  PayPalButton, 
  createOrder, 
  onApprove, 
  loading
}) => {
    const { t, i18n } = useTranslation();
    let history = useHistory();


    window.addEventListener("popstate", e => {
      // Nope, go back to your page
      history.go(1);
    });

  return (
 loading ? '' :
 <div className='h-screen bg-white font-astana'>
  <div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('BackTo')} t={t}  /> 
</div>
 <div className='p-5 md:p-10 py-10 h-auto font-astanaM tracking-wide capitalize text-xl grid place-items-center '>
  <p> {t('Address')} : {order.data.shippingAddress}, {order.data.shippingCity}, {order.data.shippingPostalCode}</p>
  <p> {t('Payment_Method')} : {order.data.payment_method === 'Cash' && order.data.shippingMethod === 'pick' ? t('Cash') : 
  order.data.payment_method === 'Cash' &&  order.data.shippingMethod === 'post' ?  t('payOnDelivery') : 'PayPal'  } </p>

    {order.data.shippingMethod ==="pick" &&  <p> {t('Shipping')} : {t('In-store PickUp')}</p>}
    {order.data.shippingMethod ==="post"  && <p> {t('Shipping')}  : {t('post')}</p>}


 <div className='py-4 text-2xl'>
         {order.data.payment_method ==='Bank' && <h1 className='border-r p-2'>    </h1>}
         <h2>  {order.data.notes} </h2>
         <h2> {t('Price')} : {order.data.totalPrice} &euro;</h2>
         <h1> {t('Name')} : {order.data.shippingName}</h1>
 </div>

 {  !order.data.payment_status && order.data.payment_method ==='PayPal' ? ( <div className="w-1/4">
 <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
    />
 </div>

 ) : !order.data.payment_status && (order.data.payment_method ==='Cash' || order.data.payment_method ==='Bank' ) 
     ? (
      <p> {t('nonpaypal')} </p>) 
     : order.data.shippingMethod ==="pick" ? <p> {t('nonpaypal')} </p> :  <p> {t('paypalPay')} </p>
} 
</div>
</div>
  )
}

import React, {useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import ProductPageCategoryTheme from '../theme/ProductPageCategoryTheme.js';
import useWindowDimensions from '../utils/useWindowDimensions.js';
import Seo from '../components/Seo.js';
import { Helmet } from 'react-helmet-async';

const ProductCategoryPage = ({history, match}) => {


    const {width} = useWindowDimensions()

    let paginationSize = 0
    switch(true) {
      case (width < 768):
       paginationSize = 1
      break;
      case (width >= 768 && width < 1024):
       paginationSize = 2
      break;
      case (width >=  1024 && width < 1280):
      paginationSize = 8
      case (width >=  1280):
        paginationSize = 10
    }

    const {categories} = useSelector((state)=> state.categoryList)

    const [param, setParam] =  useState(match.params.slug); 
    const [pageNum, setPageNum] = useState(history.action === 'POP' ? Math.floor(localStorage.getItem("current")) : '')
    const [results, setResults] = useState('')
    const [total, setTotal] = useState('')
    const [current, setCurrent] = useState('')
    const [perPage, setPerPage] = useState('')
    const [loading, setLoading] = useState(true)

    const { t, i18n } = useTranslation();



useEffect(()=> {
      const fetchData = async () => {
       const {data} = await axios.get(`/api/category-products/${match.params.slug}?page=${pageNum}`, {
        params: {term:paginationSize}});
            setPerPage(data.data.meta.per_page)
            setCurrent(data.data.meta.current_page)
            setTotal(data.data.meta.total)
            setResults(data.data.data)
            setParam(match.params.slug)
            setLoading(false)
        }
        fetchData()
    },[pageNum, width])

    const category = 
    categories.filter((item)=> item.slug === param)[0] &&
      categories.filter((item)=> item.slug === param)[0].name 

    const handleChangePage = (event, newPage) => {
      setPageNum(newPage);
    };

    const data = {
      category:category,
      results: results,
      t:t,
      loading: loading,
      param:param,
      total:total,
      pageNum:pageNum,
      perPage:perPage,
      history: history
    }

    localStorage.setItem("current", current)


  return (
!loading && (
<div>
  <Seo 
  title = {`${data.category}`}
  description = {`Ναυτίλος-${data.category}`}
  name="description"
  href={`/category-products/${param}`}
  />

    <ProductPageCategoryTheme data = {data} handleChangePage= {handleChangePage}/>
</div>
  ))
}

export default ProductCategoryPage
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ErrorField } from '../../theme/generalComponents';
import CouponItem from './CouponItem';

const Coupons = () => {
    
    const [type, setType] = useState('fixed')
    const [value, setValue] = useState('')
    const [couponState, setCouponState] = useState('Active')
    const [coupons, setCoupons] = useState('')
    const [notify, setNotify] =  useState(false)
    const [error, setError] = useState('')
    const [errorSubmit, setErrorSubmit] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      const fetchCoupons = async () => {
  
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,  
            }
        }
        try {
        const result = await axios.get('api/coupons', config);
        setCoupons(result.data.data)
        setLoading(false)
        } catch (err) {
          setError(err.response.data.message)
        }
      };
      setErrorSubmit('')
      fetchCoupons();
    },[notify]);



    const submitHandler = (e) => { 
        e.preventDefault()
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

        const addCoupon = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          const couponData = {
            type: type ,
            value: value,
            couponState: couponState
          }
          try {
          await axios.post('/api/coupons', couponData, config);
          setType('')
          setValue('')
        } catch (err) {
          setErrorSubmit(err.response.data.message)
        }
        };
        addCoupon();
        setNotify(!notify)
        }



  return (
    <>
    <p className="bg-white shadow-md rounded text-xl px-8 pt-6 pb-8 mb-4"> Coupon List </p>
    <div className="shadow py-4 overflow-auto border-b border-gray-200 sm:rounded-lg">
        <table className=" table-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-1/6 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Code
              </th>
              <th scope="col" className="w-1/8 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Type
              </th>
              <th scope="col" className="w-1/8 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Value
              </th>
              <th scope="col" className="w-1/8 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Cart Value
              </th>
              <th scope="col" className="w-1/8 relative px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Delete
              </th>
              <th scope="col" className="w-1/8 relative px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Email
              </th>
              <th scope="col" className="w-1/8 relative px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                Sent
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {error && <ErrorField> {error} </ErrorField>}
          {errorSubmit && <ErrorField> {errorSubmit} </ErrorField>}
              { !loading && coupons && coupons.map((coupon, index) => (
           <CouponItem  key={index}
             coupon={coupon}
             coupons={coupons}
             setCoupons={setCoupons}
             notify={notify}
             setNotify={setNotify}
             />
              ))}
          </tbody>
        </table>
      </div>

    <p className="bg-white shadow-md rounded text-xl px-8 pt-6 pb-8 mb-4"> Coupon Managment </p>
    <form onSubmit={submitHandler} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <button
            className="w-1/4 px-6 py-2 text-blue-200 bg-blue-600 hover:bg-blue-900" type='submit' > Add
           </button>

   <div className="space-x-0 py-8 lg:flex lg:space-x-4">
          <div className="w-full lg:w-1/2">
            <label 
            htmlFor="Address" 
            className="block mb-3 text-sm font-semibold text-gray-500">
              Coupon Type
            </label>
            <label>
            <input
            className="px-2 w-10 py-3 text-sm border border-gray-300 rounded lg:text-sm 
            focus:outline-none focus:ring-1 focus:ring-blue-600"
            name="Fixed-price" 
            type="radio" 
            value="fixed" 
            checked={type==='fixed'}
            onChange={(e)=>setType('fixed')} />
            Fixed
            </label>
            <label>
             <input
            className="px-2 w-10 py-3 text-sm border border-gray-300 rounded lg:text-sm 
            focus:outline-none focus:ring-1 focus:ring-blue-600"
            name="Percent" 
            type="radio" 
            value="percent"
            checked={type==='percent'}
            onChange={(e)=>setType('percent')} />
            Percent             
            </label>
          </div>

          <div className="w-20 lg:w-36">
            <label 
            htmlFor="Address" 
            className="block mb-3 text-sm font-semibold text-gray-500">
              Coupon value
            </label>
            <input
            className="w-full px-4 py-3 text-xs border border-gray-300 rounded lg:text-sm 
            focus:outline-none focus:ring-1 focus:ring-blue-600"
            name="value" 
            type='text' 
            value={value} 
            onChange={(e)=>setValue(e.target.value)}
             cols="20" rows="4" 
             placeholder="price">
             </input>
          </div>
        </div>


      </form>
    </>  
  )
}

export default Coupons
export const weightsZ1 = [17.5, 18.0]

export const weightsZ2 = [23.5, 32]

export const weightsZ3 = [28.5, 36]

export const inCity = [2.7, 0.8]

export const outCity= [3.7, 1]

export const payOnDelivery = [4.5, 1]

export const exportshippingDiscount = 0.057

export const Zone1 = ["Albania", "Turkey", "Cyprus", "Bulgaria", "Romania", "Macedonia, Republic of"];
export const Zone2 = [
"Austria", 
"Belgium", 
"Switzerland", 
"Germany", 
"Denmark", 
"France", 
"Estonia", 
"United Kingdom", 
"Ireland",
"Iceland",
"Spain",
"Italy",
"Croatia",
"Latvia",
"Lithuania",
"Luxembourg",
"Malta",
"Norway",
"Netherlands",
"Hungary",
"Poland",
"Slovakia",
"Slovenia",
"Sweden",
"Czech Republic",
"Finland"
];


export const Postals =[
"10431",
"10432",
"10433",
"10434",
"10435",
"10436",
"10437",
"10438",
"10439",
"10440",
"10441",
"10442",
"10443",
"10444",
"10445",
"10446",
"10447",
"10551",
"10552",
"10553",
"10554",
"10555",
"10556",
"10557",
"10558",
"10559",
"10560",
"10561",
"10562",
"10563",
"10564",
"10671",
"10672",
"10673",
"10674",
"10675",
"10676",
"10677",
"10678",
"10679",
"10680",
"10681",
"10682",
"10683",
"11141",
"11142",
"11143",
"11144",
"11145",
"11251",
"11252",
"11253",
"11254",
"11255",
"11256",
"11257",
"11361",
"11362",
"11363",
"11364",
"11454",
"11471",
"11472",
"11473",
"11474",
"11475",
"11476",
"11521",
"11522",
"11523",
"11524",
"11525",
"11526",
"11527",
"11528",
"11533",
"11551",
"11631",
"11632",
"11633",
"11634",
"11635",
"11636",
"11741",
"11742",
"11743",
"11744",
"11745",
"11835",
"11851",
"11852",
"11853",
"11854",
"11855",
"16121",
];

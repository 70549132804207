import React, { useState } from 'react';
import {useSelector} from 'react-redux' 
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect, useLocation} from 'react-router-dom'; 
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import ProfilePage from './pages/ProfilePage';
import ShippingPage from './pages/ShippingPage';
import PaymentPage from './pages/PaymentPage';
import { PlaceOrderPage } from './pages/PlaceOrderPage';
import { OrderPage } from './pages/OrderPage';
import PublisherPage from './pages/PublisherPage';
import {DashBoardPage} from './pages/DashBoardPage' 
import {AnimatePresence} from 'framer-motion/dist/framer-motion';
import ProductCategoryPage from './pages/ProductCategoryPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import axios from 'axios';
import SearchPage from './pages/SearchPage';
import "@material-tailwind/react/tailwind.css";
import Page404 from './components/Page404';
import { TextField, Button } from '@mui/material';
import InfoTermsPage from './pages/InfoTermsPage';
import ProductTagPage from './pages/ProductTagPage';
import LogoHeader from './components/LogoHeader';
import ShowProducts from './components/ShowProducts';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const userLogin = useSelector((state)=> state.userLogin)
  const {userInfo} = userLogin


  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

  const PrivateRoute = ({children, ...rest}) => {
    return (
      <Route {...rest} render={() => {
        return userInfo && userInfo.data.role === 1
          ? children
          : <Redirect to='/' component={HomePage} exact/>
      }} />
    )
  } 


  return (
    <>
    <HelmetProvider>
    <AnimatePresence exitBeforeEnter >
    <Switch location={location} key={location.key}>
    <Route path='/' component={HomePage} exact/>
    <Route path='/aboutUs' component={PublisherPage}/>
    <Route path='/search' component={SearchPage}/>
    <Route path='/products/:slug' component={ShowProducts}/>
    <Route path='/product/:id' component={ProductPage} />
    <Route path='/category-products/:slug' component={ProductCategoryPage} />
    <Route path='/tags-products/:slug' component={ProductTagPage}  />
    <Route path='/login' component={LoginPage}/>
    <Route path='/register' component={RegisterPage}/>
    <Route path='/profile' component={ProfilePage}/>
    <Route path='/cart/:id?' component={CartPage} />
    <Route path='/shipping' component={ShippingPage} />
    <Route path='/payment' component={PaymentPage} />
    <Route path='/placeorder' component={PlaceOrderPage} />
    <Route path='/order/:id' component={OrderPage} />
    <Route path='/reset-password' component={ResetPasswordPage}/>
    <Route path='/terms-information' component={InfoTermsPage}/> 
    <PrivateRoute path='/admin/dashboard'> <DashBoardPage/> </PrivateRoute>
    <Route path="/404" component={Page404} />
    <Route path="*" component={Page404} />
    </Switch>
    </AnimatePresence>
    </HelmetProvider>
    </>
  
  );
}

export default App;

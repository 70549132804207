import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DiscountPlansForm from './DiscountPlansForm'
import { ErrorField } from '../../theme/generalComponents'

const DiscountPlansList = () => {

    const [plans, setPlans] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [notify, setNotify] = useState(false)

    useEffect(() => {
        const fetchPlans = async () => {
    
          const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
  
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,  
              }
          }
          try {
          const result = await axios.get('api/discountplans', config);
          setPlans(result.data.data)
          setLoading(false)
          } catch (err) {
            setError(err.response.data.message)
          }
        }
        fetchPlans();
      },[notify]);

      console.log(plans)


  return (
    <div>
        {error && <ErrorField> {error} </ErrorField>}
        <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-4">
    <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <table className=" table-auto divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
              Discount Plan
            </th>
            <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
              Percentage
            </th>
            <th scope="col" className="w-1/6 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
              Duration(Days)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        { !loading && plans && plans.map((plan) => (
        <DiscountPlansForm key={plan.name} plan={plan}/>
        ))}
        </tbody>
        </table>
    </div>
    </div>
    </div>

  )
}

export default DiscountPlansList
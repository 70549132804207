import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import Header from '../components/Header.js'
import { getUserProfile, updateUserProfile } from '../actions/userActions.js'
import { getProfileOrder } from '../actions/orderActions.js'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants.js'
import { ProfilePageAccountTheme } from '../theme/ProfilePageAccountTheme.js'
import { ProfilePageOrdersTheme } from '../theme/ProfilePageOrdersTheme.js'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import BreadCrumb from '../components/subComponents/BreadCrumb.js'
import { useTranslation } from "react-i18next";


const ProfilePage = ({location, history}) => {

const { t, i18n } = useTranslation();  
const [showModal, setShowModal] = useState(false);
const [showOrder, setShowOrder] =useState('')
const [name, setName] = useState('')
const [address, setAddress] = useState('')
const [city, setCity] = useState('')
const [postalCode, setPostalCode] = useState('')
const [country, setCountry] = useState('')
const [email, setEmail] = useState('')
const [tel_no, setTel_no] = useState('')
const [password, setPassword] = useState()
const [password_confirmation, setPasswordConfirmation ] = useState()
const [message, setMessage] = useState('')


const dispatch = useDispatch()

const userDetails = useSelector((state) => state.userDetails)
const {error, user} = userDetails

 const userLogin = useSelector((state) => state.userLogin)
 const {userInfo} = userLogin

 const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
 const {error:errorUpdate, success} = userUpdateProfile

 const orderListProfile = useSelector((state) => state.orderListProfile || "" )
 const {loading:loadingProfileOrders, error: errorProfileOrders, orders} = orderListProfile
 

 useEffect(() => {
    if (!userInfo) {
    history.push('/login')
    } else {
      if (!user || !user.data || success ) {
        dispatch({type: USER_UPDATE_PROFILE_RESET})
        dispatch(getUserProfile())
        dispatch(getProfileOrder())
      } else {
        setName(user.data.name)
        setCity(user.data.city ? user.data.city : '')
        setAddress(user.data.address ? user.data.address : '')
        setPostalCode(user.data.postalCode ? user.data.postalCode : '')
        setCountry(user.data.country ? user.data.country : '')
        setEmail(user.data.email)
        setTel_no(user.data.tel_no ? user.data.tel_no : '')
        setPassword(user.data.password)
        setPasswordConfirmation(user.data.password_confirmation)
      }
      setMessage('')
      setPassword('')
      setPasswordConfirmation('')
    }
  }, [dispatch, history, userInfo, user])


const submitHandler = (e) => { 
    e.preventDefault()
    if(password !== password_confirmation) {
        setMessage('passwords not match')
    }
        else{
            dispatch(updateUserProfile({ 
              id:user.data.id,
              address,
              city,
              country,
              tel_no,
              postalCode,
              name, 
              email, 
              password
            }))
        }
}
    return (
      <div className='h-auto min-h-screen bg-white font-astana'>
                <div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[4vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('profile')} t={t}  /> 
</div>
 <div className="w-full my-1 md:my-6 md:flex">
           <ProfilePageAccountTheme 
           name={name} setName={setName}
           city={city} setCity={setCity}
           address={address} setAddress={setAddress}
           postalCode={postalCode} setPostalCode={setPostalCode}
           country={country} setCountry={setCountry}
           tel_no={tel_no} setTel_no={setTel_no}
           email={email} setEmail={setEmail}
           password={password} setPassword={setPassword}
           password_confirmation={password_confirmation} setPasswordConfirmation={setPasswordConfirmation}
           error={error}
           errorUpdate={errorUpdate}
           message={message}
           success={success}
           CountryDropdown={CountryDropdown}
           submitHandler={submitHandler}/>

          <ProfilePageOrdersTheme 
           showModal={showModal}
           setShowModal={setShowModal}
           orders={orders}
           showOrder={showOrder}
           loadingProfileOrders={loadingProfileOrders}
           setShowOrder={setShowOrder}/>
          </div>
        </div>
    )
}

export default ProfilePage
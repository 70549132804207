import React, {useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { savePaymentMethod } from '../actions/cartActions';
import { CheckoutSchema } from '../components/CheckoutSchema';
import { useTranslation } from "react-i18next";
import { PaymentPageTheme } from '../theme/PaymentPageTheme';

const PaymentPage = ({history}) => {
  const { t, i18n } = useTranslation();

  const cart = useSelector((state)=> state.cart)
  const {shippingAddress} = cart
  const {shippingMethod} = cart
  const {cartItems} =cart  
  if(cartItems.length ===0 ) {
    history.push('/')
  }
  else if(!shippingAddress){
        history.push('/shipping')
    }


  const [paymentMethod, setPaymentMethod] = useState('PayPal')

  const dispatch = useDispatch()

  const submitHandler = (e) => { 
      e.preventDefault()
      dispatch(savePaymentMethod(paymentMethod))
      history.push('/placeorder')
}
    return (
<PaymentPageTheme
country = {shippingAddress.country}
shippingMethod={shippingMethod} 
setPaymentMethod={setPaymentMethod} 
submitHandler={submitHandler}/>
    )
}

export default PaymentPage
import React from 'react'
import { CheckoutSchema } from '../components/CheckoutSchema'
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';
import { GlobalButton, PageTitle, ErrorField } from './generalComponents';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import Input from '@mui/material/Input';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';


const ShippingPageTheme = ({CountryDropdown, errors, shippingMethod, submitHandler, 
    name, setName, email, setEmail, address, setAddress, postalCode, setPostalCode,
    country, setCountry, city, setCity, notes, setNotes}) => {

const { t, i18n } = useTranslation();

  return (
      <div className='h-screen bg-white font-astana'>
        <div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('reservationInfo')} t={t}  /> 
</div>
    <CheckoutSchema step2={true}/>
    {Object.keys(errors).length!==0 ?
     (<ErrorField className=" px-10 py-10 text-red-900" >{errors.name}</ErrorField>) : ''}
    { shippingMethod==="pick" ? 
                ( <div className="px-10 flex flex-col md:w-full">
                <form onSubmit={submitHandler} className="justify-center w-full p-10 mx-auto">
                    <div className="">
                    <div className="space-x-0 uppercase pt-10 lg:flex lg:space-x-4">
   <div className="w-full lg:w-1/2">
        <TextField
              variant="standard"
              className="uppercase	w-full"
              type='text' 
              label={`${t('Name')} /`}
              value={name} 
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 700,
                    letterSpacing: '0.05em'
                    },
              }}
              onChange={(e)=>setName(e.target.value)} 
             >
        </TextField>
   </div>
            </div>
            </div>
            <div className='py-4'>
            <button className='mt-4 flex flex-row uppercase' type='submit' > <ArrowForwardIos/> {t('process')} </button>
            </div>
                </form> 
                </div> 
                ) : 
                ( 

          <div className="flex flex-col md:w-full">

            <form onSubmit={submitHandler} className="justify-center w-full p-10 mx-auto">
                <div className="">
       <div className="space-x-0 pt-10 lg:flex lg:space-x-4">
        <div className="w-full py-1 lg:w-1/2">
        <TextField
              type='text' 
              label={`${t('Name')} /`}
              value={name} 
              onChange={(e)=>setName(e.target.value)} 
              variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 700,
                    letterSpacing: '0.05em'
                    },
              }}
             >
        </TextField>
        </div>
      
        <div className="w-full py-1 lg:w-1/2 ">
        <TextField 
        type="email" 
        label={`${t('Email')} /`}
        value={email} 
        onChange={(e)=>setEmail(e.target.value)}                      
     variant="standard"
              className="uppercase	w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 700,
                    letterSpacing: '0.05em'
                    },
              }}/>
        </div>
       </div>

        <div className="space-x-0 pt-10 lg:flex lg:space-x-4">
     
        <div className="w-full py-1 lg:w-1/2">
        <TextField
         type='Address' 
         value={address} 
         onChange={(e)=>setAddress(e.target.value)} 
         label={t('Address')}
         className="uppercase	w-full"
         variant="standard"
         InputProps={{
            disableUnderline: true, // <== added this
            }}
            InputLabelProps={{
              style: { 
                  color: 'black',
                  fontWeight: 700,
                  letterSpacing: '0.05em'
                  },
            }}
         />
        </div>
     
        <div className="w-full py-1 lg:w-1/2 ">
        <TextField
        type="text" 
        label={t('PostCode')}
        value={postalCode} 
        onChange={(e)=>setPostalCode(e.target.value)} 
        variant="standard"
        InputProps={{
            disableUnderline: true, // <== added this
            }}
            InputLabelProps={{
              style: { 
                  color: 'black',
                  fontWeight: 700,
                  letterSpacing: '0.05em'
                  },
            }}    
         />
        </div>
        </div>
        <div className="space-x-0 pt-10 lg:flex lg:space-x-4">
        <div className="w-full py-1 lg:w-1/2">
        <TextField 
        type="text" label={t('City')} 
        value={city} 
        onChange={(e)=>setCity(e.target.value)}
        variant="standard"
        InputProps={{
            disableUnderline: true, // <== added this
            }}
            InputLabelProps={{
              style: { 
                  color: 'black',
                  fontWeight: 700,
                  letterSpacing: '0.05em'
                  },
            }}
        />
        </div>
        <div className="w-full py-1 lg:w-1/2">
        <CountryDropdown
        className="w-full px-4 py-4 text-xs border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
        value={country}
        onChange={(e)=>setCountry(e)} 
        variant="standard"
        InputProps={{
            disableUnderline: true, // <== added this
            }}
            InputLabelProps={{
              style: { 
                  color: 'black',
                  fontWeight: 700,
                  letterSpacing: '0.05em'
                  },
            }}
        >
        </CountryDropdown>
        </div>
        </div>
        <div className="relative pt-3 xl:pt-6">
         <TextField 
         className="w-full"
         variant="standard"
         multiline
         rows={2}
         maxRows={4}
         label="Notes for delivery" 
         value={notes} 
         onChange={(e)=>setNotes(e.target.value)}
         InputProps={{
            disableUnderline: true, // <== added this
            }}
            InputLabelProps={{
              style: { 
                  color: 'black',
                  fontWeight: 700,
                  letterSpacing: '0.05em'
                  },
            }}
         >
         </TextField>
         </div>
                    
                        <button className='mt-4 flex flex-row uppercase' type='submit' > <ArrowForwardIos/> {t('process')} </button>
                </div>
            </form>
        </div>)}
  </div>
  )
}

export default ShippingPageTheme
import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import { register } from '../actions/userActions.js'
import RegisterPageTheme from '../theme/RegisterPageTheme.js'

const RegisterPage = ({location, history}) => {

const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [password_confirmation, setPasswordConfirmation] = useState('')
const [message, setMessage] = useState(null)


const dispatch = useDispatch()
const userRegister = useSelector(state => state.userRegister)

const redirect = location.search ? location.search.split('=')[1] : '/'

const {loading, error, userInfo} = userRegister


useEffect(()=> {
    if(userInfo) {
        history.push(redirect)
    }
}, [history, userInfo, redirect])


const submitHandler = (e) => { 
    e.preventDefault()
    if(password !== password_confirmation) {
        setMessage('passwords do not match')
    } else
    dispatch(register(name, email, password, password_confirmation))
}



    return (
    <RegisterPageTheme 
    loading={loading}
    error={error} 
    message={message} 
    submitHandler={submitHandler}
    name={name} 
    setName={setName} 
    email={email} 
    setEmail={setEmail} 
    password={password} 
    setPassword={setPassword}
    password_confirmation={password_confirmation} 
    setPasswordConfirmation={setPasswordConfirmation}
    redirect={redirect} />    )
}


export default RegisterPage
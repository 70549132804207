import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const  DropdownCategories = ({
  Categories, 
  selectedCategory, 
  setSelectedCategory}) => {
  const [activeMenu, setActiveMenu] = useState('');
  const [menuHeight, setMenuHeight] = useState(null);
  const [before, setBefore] = useState(0)
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [activeMenu, dropdownRef, menuHeight])

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
    setActiveMenu('')
  }



  const categories = Categories.filter(item=>!item.parent_id)

  function DropdownItem(props) {
    return (
      <p className="menu-item" >
        <span  
           onClick={() => {
           if(before !== 0 && before !== null) { 
             setActiveMenu(Categories.find(item=>item.id==before).parent_id); 
             setBefore(Categories.find(item=>item.id==before).parent_id) }
          }
          } 
            className="icon-button">{ before!==0 && props.leftIcon}
        </span>
              {props.children}
        <span 
          onClick={ () =>  {
          props.goToMenu && setBefore(props.goToMenu);  
          setActiveMenu(props.goToMenu); 
          } 
          } 
          className="icon-right">{props.rightIcon}
       </span>
      </p>
    );
  }

  return (
    <div className="dropdown" 
     style={{ height: menuHeight+5 }}
     ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={10}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          { categories.map((cat, index) => (
          <DropdownItem
            key={index}
            leftIcon={<ArrowBackIosIcon/>}
            rightIcon={cat.children.length ? <ArrowForwardIosIcon/> : ''}
            goToMenu={cat.id}
            >
            {cat.name} 
          </DropdownItem>
          ))}
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === activeMenu}
        timeout={10}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
        {/* <DropdownItem goToMenu="main"/> */}
        { Categories.filter(item=> item.parent_id == `${activeMenu}` ).map((cat, index) => (
          <DropdownItem 
          key={index}
          goToMenu={cat.children.length ? cat.id : ''} 
          rightIcon={cat.children.length ? <ArrowForwardIosIcon/>: ''}
          leftIcon={<ArrowBackIosIcon className='cursor-pointer'/>}
          >
 <p className='tracking-normal cursor-pointer text-gray-800'  
 onClick={()=>setSelectedCategory( {
   id: cat.id,
   name: cat.name,
   slug: cat.slug
   })}>
 {cat.name}
 </p>
   </DropdownItem>
        ))}
        </div>
      </CSSTransition>
    </div>
  );
  }
import axios from "axios"
import {
    CATEGORY_LIST_FAIL,
    CATEGORY_LIST_SUCCESS, 
    CATEGORY_LIST_REQUEST,
    } from "../constants/categoryConstants"

export const listCategories = (keyword='') => async (dispatch, getState) => {
    try {
        dispatch({ type: 
            CATEGORY_LIST_REQUEST})

        const {data} = await axios.get(`/api/categories?keyword=${keyword}`)

        dispatch({ 
            type: CATEGORY_LIST_SUCCESS,
            payload: data
        })
        localStorage.setItem('categories', JSON.stringify(getState().categoryList.categories))
    } catch (error) {
        dispatch({
        type: CATEGORY_LIST_FAIL,
        payload: error?.response?.data.message
        })
        
    }
 }
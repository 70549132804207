import React, { useState, useEffect } from "react";
import axios from "axios";
import Textarea from "@material-tailwind/react/Textarea";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { Alert } from "@mui/material";


const AboutPage = ({userInfo}) => {

    const [keyword, setKeyword] = useState('')
    const [content, setContent] = useState('')
    const [image, setImage] = useState('')
    const [link, setLink] = useState('')
    const [isActive, setIsActive] = useState(false)

    const pages = [
        {key: 1, value: 'page_1'}, 
        {key: 2, value: 'page_2'},
        {key: 3, value: 'show_1'},
        {key: 4, value: 'show_2'},
        {key: 5, value: 'show_3'},
        {key: 6, value: 'show_4'},
        {key: 7, value: 'show_5'}
    ]

    const [responseMsg, setResponseMsg] = useState( {
        status:"",
        message:"",
        error:{},
    })

    const fileValidate = (file) => {
        if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg"
            )
         {
              return true;
            } else {
                setResponseMsg( {error: "File allowed jpg,png, jpeg"})
                return false;
            }
        }

         const submitHandler = (e) => {
            e.preventDefault();

            const postAttr =  async () => { 
                try {
                    const imageData = new FormData();
                    imageData.append("image", image)
                    imageData.append("keyword", keyword)
                    imageData.append("content", content)
                    imageData.append("link", link)   
                    imageData.append("isActive", isActive ? 1: 0) 

                   const config = {
                       headers: {
                           'Content-Type': 'application/json',
                          Authorization: `Bearer ${userInfo.data.token}`,
                       }
                   }

                const resp = await axios.post('api/attribute', imageData, config)
                       if (resp.status === 200) {
                       setResponseMsg({
                           status:  resp.data.status,
                           message: resp.data.message,
                           error:   resp.data.errors ? resp.data.errors : {}
                       })
                       setIsActive(false)
                   }
               } catch(error) {
        }
           }
           postAttr()
        }

    const handlePicInput = (e) => {
        if(fileValidate(e.target.files[0])) {
        setImage(e.target.files[0]) 
    }
    }


    return (
        <>
        <p  className="bg-white shadow-md rounded text-xl px-8 pt-6 pb-8 mb-4"> Manage About Content </p>
     { responseMsg.status === "success" && <Alert severity="info"> {responseMsg.status} content updated</Alert>}    
     { responseMsg.status === "failed" && <Alert severity="error"> {responseMsg.status}</Alert>} 
     {Object.keys(responseMsg.error).length !==0 &&   <Alert severity="error"> {responseMsg.message} {Object.values(responseMsg.error)} </Alert>}

      <form onSubmit={submitHandler} encType="multipart/form-data" id="imageForm" >
         <div className="pt-4 w-full">
        {pages.map(({key, value}) =>
        <p className="px-2" key={key}>
        <label className="px-2">
        <input
        type="radio"
        name={value}
        value={keyword}
        checked={keyword===value}
        onChange={(e)=>setKeyword(value)}
        />
        {   value ==='page_1' ? 'page_1' : 
            value==='page_2' ? 'page_2' : 
            value==='show_1' ? 'showRoom1':
            value==='show_2' ? 'showRoom2':
            value==='show_3' ? 'showRoom3':
            value==='show_4' ? 'showRoom4':
            'showRoom5'
             }        
             </label>
        </p>
        )}
        </div> 
        <div className="py-8">
        <Textarea
            color="lightBlue"
            size="regular"
            outline={true}
            value={content}
            onChange={(e)=>setContent(e.target.value)}  
            placeholder="Content"
        />
        </div>
        <TextField
  id="outlined-name"
  label="link To"
  value={link}
  onChange={(e)=>{setLink(e.target.value)}}
/>
    <div className="py-4">
        <Checkbox
        checked={isActive}
        onChange={(e) => {setIsActive(!isActive)}}
        /> 
        isActive
    </div>

    <div className="flex  justify-center m-2">
      <div className="rounded-lg shadow-xl bg-gray-50 w-full">
        <div className="m-2">
            <label className="flex mb-2 text-gray-500">
                |jpg,png,svg,jpeg|</label>
            <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-12 border-4 border-dashed hover:border-red-600">
                    <div className="flex flex-col items-center justify-center ">

                        <p className="text-sm tracking-wider text-gray-400 group-hover:text-red-800">
                            Select </p>
                    </div>
                    <input type="file" name="image" multiple onChange={handlePicInput} className="opacity-0" />
                </label>
            </div>
        </div>
        <div className="flex p-2 space-x-4">
     
     </div>
     </div>
   </div>
   <button className="w-1/4 px-6 py-2 text-blue-200 bg-blue-600 hover:bg-blue-900" 
   type="submit" > Add
    </button> 
 </form> 
        </>
    )

}

export default AboutPage


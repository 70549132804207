import React from 'react'
var greekUtils = require('greek-utils');

const BreadCrumb = ({category, t}) => 
{
  const str = `${category && category.toString()}`

  return (
    <div className='text-xs md:text-base lg:text-lg flex flex-row  uppercase'>
       {t(greekUtils.sanitizeDiacritics(str))}   
       <a href="/" className=" px-2 text-xs md:text-base lg:text-lg
        cursor-pointer  text-theme-base-color2">
              ΝΑΥΤΙΛΟΣ
  </a>
    </div>
  )
}

export default BreadCrumb
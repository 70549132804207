import React, {useEffect, useState} from 'react'
import { t } from 'i18next'
import axios from 'axios'
import ProductSwiper from './ProductSwiper'
import Loader from './Loader'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase'

  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '0px solid rgba(0, 0, 0, .125)',
}));


const RelatedProducts = ({author, id, publisher, categories}) => {
    const [loadingAuth, setLoadingAuth] = useState(true)
    const [loadingPub,  setLoadingPub] = useState(true)
    const [loadingCat,  setLoadingCat] = useState(true)
    const [authResult, setAuthResult] = useState('')
    const [pubResult, setPubResult] = useState('')
    const [catResult, setCatResult] = useState('')
    const [sizeOfProds, setSizeOfProds] = useState(20)
    
    useEffect(() => {

      if (author) {
        const fetchAuth = async () => {
        const result = await axios.get('/api/searchBooks',{
          params: {
            keyAuthor:author,
          }
        } );
        setAuthResult(result.data.filter(item=> item.slug !== id).slice(0,sizeOfProds))
        setLoadingAuth(false)
    }
fetchAuth()
  }
   if (publisher) { 
    const fetchPub = async () => {
        const result = await axios.get('/api/searchBooks',{
          params: {
            keyPublisher:publisher,
          }
        } );
        setPubResult(result.data.filter(item=> item.slug !== id).slice(0,sizeOfProds))
        setLoadingPub(false)
    }
    fetchPub()
  }

if (categories.length > 0) {
    const fetchCat = async () => {
      const {data} = await axios.get(`/api/category-products/${categories[0].slug}`);
      setCatResult(data.data.data.slice(0,sizeOfProds))
      setLoadingCat(false)
  }
  fetchCat()
}    }, [])


const [expanded, setExpanded] = useState('panel3');

const handleChange = (panel) => (event, newExpanded) => {
  setExpanded(newExpanded ? panel : false);
};



  return (

 <div className='h-full'>
{ loadingAuth ? <Loader /> : !loadingAuth && authResult && authResult.length>0 && (
   <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
   <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
     <Typography>{t('relatedProducts')} | {t('author')}</Typography>
   </AccordionSummary>
   <AccordionDetails>
   <ProductSwiper products={authResult}/>
   </AccordionDetails>
 </Accordion>
)}

{loadingPub ? <Loader /> : !loadingPub && pubResult && pubResult.length > 0 && ( 
    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
      <Typography>{t('relatedProducts')} | {t('publisher')}</Typography>
    </AccordionSummary>
    <AccordionDetails>
    <ProductSwiper products={pubResult}/>
    </AccordionDetails>
  </Accordion>
)}

{loadingCat && categories.length > 0 && <Loader/>} 
{!loadingCat && catResult && catResult.length>0 && (
  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
  <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
    <Typography>{t('relatedProducts')} | {t('category')}</Typography>
  </AccordionSummary>
  <AccordionDetails>
  <ProductSwiper products={catResult}/>
  </AccordionDetails>
</Accordion>
)}
    </div>
  )

}

export default RelatedProducts

import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import { login } from '../actions/userActions.js'
import { saveShippingAddress } from '../actions/cartActions';
import {
  AnimatePresence,
  motion } from 'framer-motion/dist/framer-motion';
  import { useTranslation } from "react-i18next";
import axios from 'axios';
import  LoginPageTheme  from '../theme/LoginPageTheme.js';


  const containerVariants = {
    hidden: {
      opacity:0,
    },
    visible : {
      opacity:1,
      transition: {delay:0, duration:1}
    },
    exit: {
      x: '-100vw',
      transition: {duration:1,}
    }
  }



const LoginPage = ({location, history}) => {
  const { t, i18n } = useTranslation();

const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [message, setMessage] = useState('')

const dispatch = useDispatch()
const userLogin = useSelector(state => state.userLogin)

const redirect = location.search ? location.search.split('=')[1] : '/'



const {loading, error, userInfo} = userLogin

const postData = async (email) => {
  await axios.post('/api/forgot-password', {'email':email})
}



const handleForgotPassword = () => {
  if (email ==='') {setMessage(t('please_fill_in_your_email'))}
  else {
  postData(email)
  setMessage(`${t('An_email_has_been_sent_to')} ${email} ${t('to_reset_your_password')}`)
  }
}

useEffect(()=> {
    if(userInfo) {
        history.push(redirect)
    }
}, [history, userInfo, redirect])

const submitHandler = (e) => { 
    e.preventDefault()
    dispatch(login(email, password))
}

    return (
      <LoginPageTheme
      loading={loading}
      error={error} 
      email={email} setEmail={setEmail} 
      password={password} setPassword={setPassword} 
      submitHandler={submitHandler}
      handleForgotPassword={handleForgotPassword}
      message={message}/>
    )
}

export default LoginPage
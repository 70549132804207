import React, {useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { listProductDetails, createReview} from '../actions/productActions';
import { PRODUCT_REVIEW_RESET } from '../constants/productConstants';
import {
  AnimatePresence,
  motion } from 'framer-motion/dist/framer-motion';
import ProductPageTheme from '../theme/ProductPageTheme';
import { Redirect} from 'react-router-dom'; 
import Seo from '../components/Seo';


  const backdrop = {
    visible: { opacity : 1},
    hidden: {opacity: 0},
}

const modal = {
    hidden: {
        y:"50px",
        x:"200px",
        opacity:0
    },
    visible: {
        y:"50px",
        opacity:1,
        x:"200px",
        height:"800px",
        width: "800px",
        transition: { delay:0.5}
    }
}

const Modal = ({showModal, setShowModal, selectedImagePathway}) => {

  useEffect(()=> {
    
  }, [])


return (
  <AnimatePresence exitBeforeEnter>
  { showModal && (
      <motion.div className="backdrop" 
      variants={backdrop}
      initial = "hidden"
      animate="visible"
      exit="hidden"
      >

    <motion.div className="modal"
                  variants={modal}
                  initial = "hidden"
                  animate="visible"
    >
      <img 
          className='h-4/5 my-4 w-auto'
          alt="slide"
          src={`${process.env.REACT_APP_API_ENDPOINT}storage/${selectedImagePathway}`}
      />
   <button
   onClick={(e)=> setShowModal(false)}
   className="border-2 px-4  w-1/3 uppercase text-xl border-gray-800 hover:border-red-600" > Close </button>
    </motion.div>

    </motion.div>
  )}
</AnimatePresence>
)
}

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible : {
      opacity: 1,
      transition: {delay:0.5, duration:1}
    },
    exit: {
      x: '-100vw',
      transition: {duration:1}
    }
  }

const ProductPage = ({history, match}) => {

   const productDetails = useSelector((state) => state.productDetails);

   const [qty, setQty] = useState(1);

   const [review, setReview] = useState('')
   
   const {loading, error, product} = productDetails;

   const [showModal, setShowModal] = useState(false)


   const [selectedImagePathway, setSelectedImagePathway] = useState('')

const productReview = useSelector((state)=>state.productReview)

const {loading: loadingReview, success: successReview, error:errorReview} = productReview

const userLogin = useSelector((state)=>state.userLogin)
const { userInfo} = userLogin

   const dispatch = useDispatch();

   useEffect(()=> {

     if(successReview) {
       alert('Review Submitted')
       setReview('')
     }
    if (!product.data.id || product.data.slug !== match.params.id) {
    dispatch(listProductDetails(match.params.id))
    dispatch({type: PRODUCT_REVIEW_RESET})
    }
     },
    [match.params.id, history, dispatch, successReview]
    );

  const addToCartHandler = () => {
      history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  const reviewSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(createReview(
      match.params.id, {
      review: review
    }))
  }


return (
<section>

{error && error.response && error.response.data && 
  <Redirect
            to={{
            pathname: "/404",
            state: {message: error.response.data.message}
          }}
        />
}

<Modal 
    showModal={showModal}
    setShowModal={setShowModal}
    selectedImagePathway={selectedImagePathway}
    /> 

{loading===false && product && product.data.slug == match.params.id && (

<motion.div 
  variants  = {containerVariants}
  initial="hidden"
  animate="visible"
  exit="exit"
  className = "text-gray-700 body-font overflow-hidden bg-white"
>
     
  <Seo 
   title = {`${product.data.title}-${product.data.author}`}
   description = {`${product.data.content.substring(0,150)}`}
   name="description"
   href={`/product/${match.params.id}`}
   /> 
    <ProductPageTheme qty={qty} 
    setQty={setQty} 
    product={product} 
    addToCartHandler={addToCartHandler}
    pdf={  product.data.media.filter((item) => item.pathway.split('.').pop() === "pdf").length > 0
       ? product.data.media.filter((item) => item.pathway.split('.').pop() === "pdf")[0].pathway 
       : ''
    }
    />
</motion.div>)}
</section>
    )
}

export default ProductPage
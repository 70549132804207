import React from 'react'
import { useTranslation } from "react-i18next";

const Bottom = () => {
    const { t, i18n } = useTranslation();

    return (
      <div className='tracking-wider text-gray-600 flex justify-center text-sm md:text-base p-5 '>
            <p className="px-2">2022&copy; Η Βιβλιοθήκη του Ναυτίλου.  All rights reserved</p>

            <p className="px-2"> Developed by IstIo </p>
        </div>
    )
}

export default Bottom

import { TextField } from '@mui/material';
import React from 'react'
import { useTranslation } from "react-i18next";
import { GlobalButton } from './generalComponents';
import { ErrorField } from './generalComponents';

export const ProfilePageAccountTheme = ({
    error,
    errorUpdate,
    message,
    success,
    submitHandler,
    name, setName, 
    email, setEmail,
    address, setAddress,
    city, setCity,
    postalCode, setPostalCode,
    country, setCountry,
    tel_no, setTel_no,
    password,
    setPassword,
    password_confirmation,
    CountryDropdown,
    setPasswordConfirmation}) => {

        const { t, i18n } = useTranslation();

  return (
    <div className="w-full md:w-1/4">
    {error && <p className='text-red-800 text-xl'>{error.message}</p>}
    {errorUpdate && errorUpdate.password && <ErrorField>{errorUpdate.password}</ErrorField>}
    {errorUpdate && errorUpdate.name && <ErrorField>{errorUpdate.name}</ErrorField>}
    {message && <p className='text-red-800 text-xl'>{message}</p>}
    {success && <h3>Profile updated</h3>}
    <form onSubmit={submitHandler} > 
<div className="bg-grey-lighter flex flex-col">
    <div className="container max-w-sm  flex-1 flex flex-col items-center justify-center px-2">
        <div className="px-6 py-2 rounded shadow-md text-black w-full">
            <TextField 
                type="name"
                className="w-full"
                margin='dense'
                value={name}
                label={t('Name')}
                onChange={(e)=> setName(e.target.value)}
                />
            <TextField 
                type="text"
                className="w-full"
                margin='dense'
                value={address}
                label={t('Address')}
                onChange={(e)=> setAddress(e.target.value)}
                />
            <TextField 
                type="text"
                className="w-full"
                margin='dense'
                value={tel_no}
                label={t('Phone Number')}
                onChange={(e)=> setTel_no(e.target.value)}
                />     
                <TextField 
                type="text"
                className="w-full"
                margin='dense'
                value={postalCode}
                label={t('PostCode')}
                onChange={(e)=> setPostalCode(e.target.value)}
                />  
             <TextField 
                type="text"
                className="w-full"
                margin='dense'
                value={city}
                label={t('City')}
                onChange={(e)=> setCity(e.target.value)}
                />
        <div className="w-full py-2">
        <CountryDropdown
        className="w-full px-4 py-4 text-xs border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
        value={country}
        onChange={(e)=>setCountry(e)} >
        </CountryDropdown>
        </div>

            <TextField 
                type="email"
                className="w-full"
                value={email}
                margin='dense'
                label="Email" 
                onChange={(e)=> setEmail(e.target.value)}
                />
            <TextField 
                type="password"
                className="w-full"
                label="Password"
                margin='dense'
                value={password}
                onChange={(e)=> setPassword(e.target.value)} />
            <TextField 
                type="password"
                className="w-full"
                margin='dense'   
                value={password_confirmation}
                label="Confirm Password"
                onChange={(e)=> setPasswordConfirmation(e.target.value)}
                />
            <button
                type="submit"
                className="w-full my-4">
                {t('Update')}
            </button>
        </div>

    </div>
</div>
</form>
</div>
  )
}

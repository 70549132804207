import React, {useState} from 'react'
import {Link } from 'react-router-dom';
import {TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {updateProduct} from "../../actions/productActions";
import {useDispatch} from 'react-redux';

const ProductListItem = ({
    product,
    deleteHandler
    }) => {
      const dispatch = useDispatch()

const [stock, setStock] = useState(product.stock)
const [price, setPrice] = useState(product.price)
const [discount, setDiscount] = useState(product.discount)
const [discountPlan, setDiscountPlan] = useState(product.discountPlan ? product.discountPlan : null)

const [planId, setPlanId] = useState(discountPlan ? discountPlan.id : null)

const [stockTxt, setStockTxt] = useState(false)
const [priceTxt, setPriceTxt] = useState(false)
const [discountTxt, setDiscountTxt] = useState(false)
const [discountPlanTxt, setDiscountPlanTxt] = useState(false)


const updateInfo = () => {
  setPlanId(planId)
  setStock(stock)
  setDiscount(discount)
  setStockTxt(false)
  setPriceTxt(false)
  setDiscountPlanTxt(false)
  setDiscountTxt(false)
  dispatch(
    updateProduct({
    id: product.id,
    stock,
    price,
    discount,
    discountPlan: planId ? planId : ''
}))
}
const mainImage =
    product.media.filter((el) => el.isMain).length ?  product.media.filter((el) => el.isMain ).map(el=>el.pathway) 
    : product.media.length > 0 ?  product.media[0].pathway : ""
    const [hoverProduct, setHoverProduct] = useState(false)
  return (

    <tr>
    <td className="px-6 py-4 whitespace-normal">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" 
          src={`${process.env.REACT_APP_API_ENDPOINT}storage/${mainImage}`}
          alt=""/>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {product.id}
          </div>
          <div className="text-sm text-gray-500">
            {product.title}
          </div>
        </div>
      </div>
    </td>
    <td className="px-2 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">{product.author}</div>
    </td>
    <td className="px-2 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">{product.publisher}</div>
    </td>
    <td className="px-8 py-4 whitespace-nowrap">
      <div  onDoubleClick={(e) => {setPriceTxt(true)}} className="text-sm text-gray-500">{!priceTxt ? price : ''}</div>
      {
          priceTxt && (
            <ClickAwayListener onClickAway={updateInfo}>
            <TextField 
            className='w-full' 
            type="text"
            margin="dense"  
            variant="outlined"
            label="Price"     
            value={price}
            onChange = {(e)=> setPrice(e.target.value)}       
            >
            </TextField>
            </ClickAwayListener>

          )
      }
    </td>
    <td className="px-8 py-4 whitespace-nowrap">
      <div onDoubleClick={(e) => {setDiscountPlanTxt(true)}} className={`text-sm text-gray-500 ${ planId ? 'bg-blue-300' : 'bg-gray-200'}`}>
        {(!discountPlanTxt && planId) ? 
        (
          planId === 1 ? 'planA' : planId === 2 ? 'planB' : planId === 3 && 'planC' 
         ) :
        
        (!discountPlanTxt && planId==undefined) && 'None' }
        
        </div>
      {
        discountPlanTxt && (
          <ClickAwayListener
          mouseEvent = 'onMouseUp'
          onClickAway={updateInfo}>
          <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Discount Plan</InputLabel>
            <Select
              id="plan"
              value={planId}
              label="Discount Plan"
              onChange={e => {setPlanId(e.target.value)}}
            >
              <MenuItem value={undefined}>None</MenuItem>
              <MenuItem value={1}>planA</MenuItem>
              <MenuItem value={2}>planB</MenuItem>
              <MenuItem value={3}>planC</MenuItem>
            </Select>
          </FormControl>
        </Box>
        </ClickAwayListener>

        )
      }
    </td>
    <td className="px-8 py-4 whitespace-nowrap">
      <div onDoubleClick={(e) => {setDiscountTxt(true)}} className="text-sm text-gray-500">  {!discountTxt ? discount : ''}</div>
      {
          discountTxt && (
            <ClickAwayListener onClickAway={updateInfo}>

            <TextField 
            className='w-full' 
            type="text"
            margin="dense"  
            variant="outlined"
            label="Discount"     
            value={discount}
            onChange = {(e)=> setDiscount(e.target.value)}       
            >
            </TextField>
            </ClickAwayListener>

          )
      }
    </td>
    <td className={ `${ stock<4 && stock>1 ? 'bg-red-200' : stock===1 ? 'bg-red-300 text-black' :  stock===0 ? 'bg-gray-700 text-white'  :''}  px-8 py-4  whitespace-nowrap`}>
    <div onDoubleClick={(e) => {setStockTxt(true)}} className="text-sm ">  {!stockTxt ? stock : ''}</div>
    { stockTxt && (
      <ClickAwayListener onClickAway={updateInfo}>
      <TextField 
        className='w-full' 
        type="text"
        margin="dense"  
        variant="outlined"
        label="Stock"     
        value={stock}
        onChange = {(e)=> setStock(e.target.value)}
      />
      </ClickAwayListener>
    )
    }
    </td>

    <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
    <button onClick={()=>deleteHandler(product.id)}
     className="bg-transparent hover:bg-red-500 
     text-red-700 font-semibold hover:text-white py-2 px-4 border 
     border-red-500 hover:border-transparent rounded">
          DELETE
    </button>
    </td>             
    <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
          <Link className="bg-transparent hover:bg-blue-500 text-blue-700 
          font-semibold hover:text-white py-2 px-4 border 
          border-blue-500 hover:border-transparent rounded" 
          to={`/admin/dashboard/product/${product.id}/edit`}> 
          EDIT 
          </Link>
    </td>
  </tr>

    )
}

export default ProductListItem
import React from 'react'
import { Link } from 'react-router-dom'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import {AccordionDetails} from '@mui/material'
import MuiAccordionSummary, {
    AccordionSummaryProps,
  } from '@mui/material/AccordionSummary';
import {Typography} from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion 
    disableGutters
    elevation={0} 
    square 
    {...props} />
  ))(({ theme }) => ({
    backgroundColor: 'transparent',
    border: `0px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
      p:0,
      m:0
    },
    '&:before': {
      display: 'none',
      p:0
    },
  }));
  
  export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({ 
    fontFamily: theme.typography,
    minHeight: '30px',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, 0)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      [theme.breakpoints.up('md')]: {margin: 0, padding: 0},
    },
  
  
  }));

  const theme = createTheme({
    typography: {
      subtitle1: {
        fontSize: 19,
        fontFamily: ['']
      },
      body1: {
        fontFamily: [''],
        fontWeight: 800,
      },
      button: {
        backgroundColor: 'transparent',
      },
    },
  });


const CartPageMenuTheme = ({
    t,
    totalPrice, 
    totalPlusCouponPrice,
    coupon,
    couponCode,
    setCouponCode,
    submitCoupon,
    shippingMethod,
    cartItems,
    error,
    errorCode,
    saveShippingMethodHandler,
    checkOutHandler
}) => {
  return (
       <div id="summary" className="flex px-20 pt-10 capitalize  flex-col">
        <Accordion expanded={true}>
        <AccordionSummary>
        <ThemeProvider theme={theme}>
        <svg   xmlns="http://www.w3.org/2000/svg" width="25px" height="auto" viewBox="0 0 24 24">
  <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
</svg>
  <Typography > {t('Shipping')}</Typography> <p className='text-xs lowercase md:text-sm text-theme-base-color2 mx-1 '> ({t('freefor')} 50&euro;) </p>
</ThemeProvider>
        </AccordionSummary>
        <AccordionDetails>  
      <RadioGroup
       onChange={(e)=> saveShippingMethodHandler(e.target.value)}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={shippingMethod ? shippingMethod : "pick"}
        name="radio-buttons-group"
        value={shippingMethod}
      >
        <FormControlLabel value="pick" control={<Radio size='small' sx={{'&.Mui-checked':{color:'#0d0d0d'}}}/>} label={t('In-store PickUp')}/>
        <FormControlLabel value="post" control={<Radio size='small'sx={{'&.Mui-checked':{color:'#0d0d0d'}}} />} 
        label={t('ACS-COURIER')} />
      </RadioGroup>
          
        </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>
            <ThemeProvider theme={theme}>
            <svg   xmlns="http://www.w3.org/2000/svg" width="25px" height="auto" viewBox="0 0 24 24">
  <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
</svg>
  <Typography > {t('PromoCode')} </Typography>
</ThemeProvider>
            </AccordionSummary>
            <AccordionDetails>
            {coupon && <p className='text-red-500'> - {coupon.data.value}</p>}
         {error && <p className='text-red-500'>{error}</p>}
         {!coupon && <> 
                 <input  type="text" 
                  id="promo"
                  value={couponCode}
                  placeholder={t('PromoCode')} 
                  className="p-4 border-2 text-sm w-1/2"
                  onChange={(e)=> setCouponCode(e.target.value)}
                  />
         <button className='px-2' onClick={submitCoupon} >{t('Apply')}</button>
</>
              }
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary>
            <ThemeProvider theme={theme}>
  <Typography>  <span className='ml-6'>{t('totalCost')}</span>  <span>{ totalPlusCouponPrice } &euro;	</span>
</Typography>
</ThemeProvider>
            </AccordionSummary>
        </Accordion>
        <Accordion>
            <AccordionSummary>
            <ThemeProvider theme={theme}>
<svg   xmlns="http://www.w3.org/2000/svg" width="25px" height="auto" viewBox="0 0 24 24">
  <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
</svg>

  <Typography disabled={cartItems.length===0} onClick={checkOutHandler} > {t('Checkout')}</Typography>
</ThemeProvider>
            </AccordionSummary>
        </Accordion>
</div>  
  )}


export default CartPageMenuTheme
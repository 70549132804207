import React from 'react'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';


const UserInfoDisplay = ({userInfo, logoutHandler, t}) => {

  return (
    <div className="flex px-1 items-center">
    {userInfo ? (
        <div className="dropdown inline-block  relative">
          <PermIdentityIcon/>
        <ul className="dropdown-menu text-xs w-32 md-text-sm absolute hidden text-gray-700 pt-1">
          <li className="">
          <a href="/profile" className="rounded-t bg-gray-200 pt-2 hover:bg-gray-400  px-4 block whitespace-no-wrap" 
          role="menuitem"  id="menu-item-0">{t('profile')}</a>
          </li>
          <li onClick={logoutHandler} 
          className="bg-gray-200 font-astana hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" role="menuitem"  id="menu-item-3">
          {t('signout')}
          </li>
        </ul>
      </div>
    ) : ( 
<div className="w-full text-xs md:text-sm font-astana lg:text-base xl:text-lg 2xl:text-xl flex flex-row">
  <a className="px-1" href="/login">{t('login')}</a>
  </div>
    )
    }
    {
      userInfo && userInfo.data.role === 1  && (
        <div className="dropdown inline-block relative">
        <button className="bg-gray-100 text-gray-700 font-semibold  px-4 rounded inline-flex items-center">
          <span className="mr-1">AdminPanel</span>
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
        </button>
        <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
          <li className=""><a className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/admin/dashboard/userlist">Users</a></li>
          <li className=""><a className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/admin/dashboard/productlist">Products</a></li>
          <li className=""><a className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/admin/dashboard/orderlist">Orders</a></li>
        </ul>
      </div>
      )
    }
</div>
  )
}

export default UserInfoDisplay
import React from 'react'
import { CheckoutSchema } from '../components/CheckoutSchema'
import { useTranslation } from "react-i18next";
import { GlobalButton, PageTitle } from './generalComponents';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

export const PaymentPageTheme = ({
  setPaymentMethod, 
  country, 
  shippingMethod, submitHandler}) => {
    const { t, i18n } = useTranslation();



  return (
<div className='h-screen bg-white font-astana'>
<div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('Payment_Method')} t={t}  /> 
</div>
  <CheckoutSchema step3={true}/>

  <form className="text-center font-astana w-full py-10 px-16 mx-auto" onSubmit={submitHandler}>
  <div className="mt-4 justify-center w-full">
  <div className="mt-2 pb-10 justify-center pt-10">
  <label className="inline-flex items-center">
  <input type="radio" className="form-radio" name='paymentMethod' value="PayPal" checked onChange={(e) => setPaymentMethod(e.target.value)}/>
  <span className="ml-2 text-base md:text-xl my-2 md:my-0 lg:text-2xl">{t('Paypal | Credit Card')}</span>
  </label>
{ country === 'Greece' && 
<label className="inline-flex items-center pl-10">
<input type="radio" className="form-radio" name="paymentMethod"
 value="Cash" onChange={(e) => setPaymentMethod(e.target.value)}/>
{ shippingMethod === 'pick' ? <span className="ml-2  text-base md:text-xl my-2 md:my-0 lg:text-2xl">{ t('Cash')}</span> :
 <span className="ml-2  text-base md:text-xl my-6 md:my-0 lg:text-2xl">{ t('payOnDelivery')}</span>}
</label>
}
{/* <label className="inline-flex items-center pl-10">
<input type="radio" className="form-radio" name="paymentMethod" value="Bank" onChange={(e) => setPaymentMethod(e.target.value)}/>
<span className="ml-2 text-xl lg:text-2xl">{t('Bank Transfer')}</span>
</label> */}
</div>
</div>           
        <button
        className="w-1/4 uppercase flex flex-row"
        type = 'submit' 
        variant='primary'>
          <ArrowForwardIos/>
         {t('Continue')}
        </button>
    </form>
    </div>
  )
}

import React, { useEffect, useState } from 'react'

const Page404 = (props) => {
  const [message, setMessage]= useState()
  
  useEffect(()=> {
  props.location.state && setMessage(props.location.state.message)
  },[])


  return (
    <>
    <div className='w-full h-full '>Η σελίδα αυτή δεν υπάρχει</div>
    { message && <p> {message} </p> }
   </>
  )
}

export default Page404
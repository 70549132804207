import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import ListPlugin from '@fullcalendar/list'
import AddCalendarEventModal from './AddCalendarEventModal'

export const Scheduler = () => {
  
  const [updatePage, setUpdatePage] = useState(false)
  const calendarRef = useRef()
  const [isEdit, setIsEdit] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [dateInfo, setDateInfo] = useState('')
  const [events, setEvents] = useState([])
  const [checked, setChecked] = useState(false);
  const [weekendsVisible, setWeekendsVisible] = useState(true)
  const [refresh, setRefresh] = useState(false)

  const onEventAdded = event => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.unselect()

    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)

    const postEvent = async (event) => {

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      await axios.post(
        'api/store-calendar-event', event, config
      );
      setEvents([...events, event])
      setRefresh(!refresh)
    };

    postEvent(event);
  }


  const onEventEdit = event => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.unselect()

    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)

    const editEvent = async (event) => {

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      await axios.put(
        `api/update-calendar-event/${event.id}`, event, config
      );
      setRefresh(!refresh)
    };

    editEvent(event);

  }


  const onEventDelete = eventId => {
    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)

    const deleteEvent = async (eventId) => {

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      await axios.delete(
        `api/delete-calendar-event/${eventId}`, config
      );
      setEvents(events.filter(event => event.id !== eventId))
      setRefresh(!refresh)
    };

    deleteEvent(eventId);
  }
  


const  renderSidebarEvent = (event) =>  {
    return (
      <li className= "px-2 py-2" key={event.id}>
        <button class="w-1/2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
        <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
        <i>{event.title}</i>
        </button>
      </li>
    )
  }

 const handleDateClick = (dateClickInfo) => {
   const addInfo = {
     title:'',
     active:'',
     dateStart:dateClickInfo.date.toISOString().substring(0, 16),
     dateEnd:dateClickInfo.date.toISOString().substring(0, 16)
  }
   setDateInfo(addInfo)
   setIsEdit(false)
   setOpenModal(true)
 }

 // remove the renderSidebar for now
  const renderSidebar = () => {

    return (
      <div className='demo-app-sidebar'>
        <div className='demo-app-sidebar-section'>
          <label>
            <input
              type='checkbox'
              defaultChecked={checked}
              onChange={() => { setChecked(!checked); setWeekendsVisible(!weekendsVisible) }}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className='demo-app-sidebar-section'>
          <h2>All pending Orders ({events.length})</h2>
          <ul>
            {events.map(renderSidebarEvent)}
          </ul>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////

  const handleEventAdd =  (event) => {
  }
  
  const renderEventContent = (eventInfo) => {

    return (
      <div className={`${eventInfo.event.extendedProps.active ? 'bg-blue-400' : 'bg-red-600'} w-full px-2`} >
        <b className= 'px-2'>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </div>
    )

  }


  const handleEventClick = (info) => {
    let dateEnd=''
    if(info.event.end) { dateEnd=info.event.end.toISOString().substring(0, 16)}
    const updateInfo = {
         title:info.event.title,
         active:info.event.extendedProps.active,
         dateStart:info.event.start.toISOString().substring(0, 16),
         dateEnd:info.event.end 
         ? info.event.end.toISOString().substring(0, 16)
         : info.event.start.toISOString().substring(0, 16),
         eventId: info.event.id
       }
       setIsEdit(true)
       setDateInfo(updateInfo)
       setOpenModal(true)
  }

  const handleEvents =() => {

  }

  const handleDateSelect = () => {

  }
  

  useEffect(() => {
    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)

    const fetchEvents = async () => {

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      const result = await axios.get(
        'api/get-calendar-events', config
      );
      setEvents(result.data.events);
    };

    fetchEvents();
  }, [refresh]);



  return(
    <>

    <div className='p-10 flex justify-center'>
      <p className='uppercase text-xl'> List Of events</p>
    </div>
     <div className=' p-8 demo-app'>
        <div className='demo-app-main'>
          <FullCalendar
            ref={calendarRef}
            plugins={[ListPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'listWeek, dayGridMonth, timeGridWeek, timeGridDay'
            }}
            timeZone= 'UTC'
            initialView='dayGridMonth'
            events={events}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            dateClick={handleDateClick}
            select={handleDateSelect()}
            eventContent={renderEventContent} // custom render function
            eventClick={(info)=>handleEventClick(info)}
            eventsSet={handleEvents()} // called after events are initialized/added/changed/removed
            eventReceive={(event)=> handleEventAdd(event)}
          />
  { openModal && dateInfo!=='' && <AddCalendarEventModal 
                                isEdit={isEdit}
                                id={isEdit}
                                setIsEdit={setIsEdit}
                                refresh={refresh}
                                setRefresh={setRefresh}
                                dateInfo = {dateInfo} 
                                setShowModalCode={setOpenModal} 
                                onEventAdded={event=>onEventAdded(event)}
                                onEventDelete={event=>onEventDelete(event)}
                                onEventEdit={event=>onEventEdit(event)}
                                /> }
        </div>
      </div>
    </>
  )
}
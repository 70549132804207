import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import CategoriesCard from './CategoriesCard';
import axios from 'axios';
import { Alert } from '@mui/material';

const CategoriesInput = () => {

  const [name, setName] = useState('')
  const [errorUpdate, setErrorUpdate] =useState(false)
  const [successUpdate, setSuccessUpdate]=useState(false)
  const [error, setError] =useState(false)
  const [success, setSuccess]=useState(false)
  const [selectedCategory, setSelectedCategory] = useState({id: '0', name: '' })
  const [updatedCategory, setUpdatedCategory] = useState({id: '', name: ''})

  const isAdmin = true;
  


  const addHandler = (e) => { 
    e.preventDefault()
    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
    const addCategory = async () => {

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      const categoryData = {
        name: name ,
        parent_id: selectedCategory.id
      }
      try {         
        const response = await axios.post('/api/categories', categoryData, config);
        setSelectedCategory({id:'0', name:''})
        setName('')
        setSuccess(true)
        setError('')
         } catch(err) {
          setSuccess(false)
          setError(err.response)
    }
  }
    addCategory();
    }


  const updateHandler = () => { 

    const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

    const updateCategory = async () => {
      if (updateCategory) { 
      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      }
      const categoryData = {
        name: name ? name : updatedCategory.name ,
        parent_id: selectedCategory.id
      }
      try {         
        const response = await axios.put(`/api/categories/update/${updatedCategory.id}`, categoryData, config);
        setSelectedCategory({id:'0', name:''})
        setUpdatedCategory({id:'', name:''})
        setName('')
        setSuccessUpdate(true)
        setErrorUpdate('')
         } catch(err) {
          setSuccessUpdate(false)
          setErrorUpdate(err.response)
    }
  }
  else { setErrorUpdate('Select a category to update')}
  }
  
    updateCategory();
    }

    console.log(updatedCategory.id, name, selectedCategory.id)

  return (
    <>
    <p className="bg-white shadow-md rounded text-xl px-8 pt-6 pb-8 mb-4"> Category Managment </p>
    {error && <Alert severity="error">{error}</Alert>}
    {success && <Alert severity="success"> Category  added </Alert> }
    <button onClick={(e)=>{addHandler(e)}}
            className="w-1/4 px-6 py-2 text-blue-200 bg-blue-600 hover:bg-blue-900"> Add
    </button>

   <div className="space-x-0 py-8 lg:flex lg:space-x-4">
          <div className="w-full lg:w-1/2">
            <label 
            htmlFor="Address" 
            className="block mb-3 text-sm font-semibold text-gray-500">
              Category Name
            </label>
            <input
            className="w-full px-4 py-3 text-xs border border-gray-300 rounded lg:text-sm 
            focus:outline-none focus:ring-1 focus:ring-blue-600"
            name="Name" 
            type='text' 
            value={name} 
            onChange={(e)=>setName(e.target.value)}
             cols="20" rows="4" 
             placeholder="Name">
             </input>
          </div>

            <div className="w-full lg:w-1/2 ">
            <label 
            htmlFor="parent_id" 
            className="block mb-3 text-sm font-semibold text-gray-500">
             Parent Category
             <p>{selectedCategory.name}</p>
             </label>
             <div className="pl-4">
             <CategoriesCard
                isAdmin={isAdmin}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                />
             </div>
           </div>
            </div>

<div className='mt-12 '>
  <p className='p-4 bg-gray-200'> UPDATE CATEGORY</p>
  {errorUpdate && <Alert severity="error">{error}</Alert>}
  {successUpdate && <Alert severity="success"> Category  updated </Alert> }
  <div className='my-2 w-1/4' >
              <CategoriesCard
                isAdmin={isAdmin}
                selectedCategory={updatedCategory}
                setSelectedCategory={setUpdatedCategory}
                />
  </div>
  {updatedCategory && <p className='my-2'> {updatedCategory.name} </p>}
            <button onClick={(e)=>{updateHandler()}}
            className="w-1/4 px-6 py-2  bg-orange-500"> Update
            </button>
            </div>
    </>  
  );
};

export default CategoriesInput;

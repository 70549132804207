import axios from "axios"
import { 
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_RESET,
    USER_LIST_SUCCESS,
    USER_LIST_REQUEST,
    USER_LIST_FAIL,
    USER_DELETE_SUCCESS,
    USER_DELETE_REQUEST,
    USER_DELETE_FAIL,
    USER_DETAILS_RESET
} from "../constants/userConstants.js"
import {ORDER_LIST_PROFILE_RESET} from "../constants/orderConstants.js"



export const login = (email, password) => async(dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'          
            }
        }
        const { data } = await axios.post(
            'api/signin', 
            {email, password},
            config
            )
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

    localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL, 
            payload: 
            error.response.data.errors ? [error.response.data.errors.password, error.response.data.errors.email] :
            error.response && error.response.data.message
            ? [error.response.data.message]
            : [error.message],
        })
    }
}

export const logout = () =>  

async(dispatch, getState) => {
    try {
    const {
        userLogin: { userInfo },
      } = getState()

    const {data} = await axios.post(
        'api/signout', 
         {},
         {headers: {"Authorization" : `Bearer ${userInfo.data.token}`}});

    dispatch({
        type: USER_LOGOUT
    });

    dispatch({
      type: USER_DETAILS_RESET
  });

  dispatch({
    type: ORDER_LIST_PROFILE_RESET
});

    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('shippingMethod');
    localStorage.removeItem('paymentMethod')

    document.location.href = '/login'

} catch(error) {
    }
}



    export const register = (name, email, password, password_confirmation) => async(dispatch) => {
        try {
            dispatch({
                type: USER_REGISTER_REQUEST
            })
    
            const config = {
                headers: {
                    'Content-Type': 'application/json'          
                }
            }
            const { data } = await axios.post(
                'api/register', 
                {name, email, password, password_confirmation},
                config
                )
            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: data
            })
    
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: data,
            })
    
        localStorage.setItem('userInfo', JSON.stringify(data))
        } catch (error) {
            dispatch({
                type: USER_REGISTER_FAIL, 
                payload: 
                error.response.data.errors ? [
                  error.response.data.errors.password,
                  error.response.data.errors.email,
                  error.response.data.errors.name
                ] :
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    } 


    export const getUserDetails = (id) => async (dispatch, getState) => {
        try {
          dispatch({
            type: USER_DETAILS_REQUEST,
          })
      
          const {
            userLogin: { userInfo },
          } = getState()

          const config = {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
            },
          }
    
          const { data } = await axios.get(`/api/user-details/${id}`, config)
          dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
          })
        } catch (error) {
          const message =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          if (message === 'Not authorized, token failed') {
            dispatch(logout())
          }
          dispatch({
            type: USER_DETAILS_FAIL,
            payload: message,
          })
        }
      }

      export const getUserProfile = () => async (dispatch, getState) => {
        try {
          dispatch({
            type: USER_DETAILS_REQUEST,
          })
      
          const {
            userLogin: { userInfo },
          } = getState()

          const config = {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
            },
          }

          const { data } = await axios.get(`/api/user/profile`, config)
          dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
          })
        } catch (error) {
          const message =
            error.response && error.response.data.message
              ? JSON.parse(error.response.data.message)
              : error.message
          if (message === 'Not authorized, token failed') {
            dispatch(logout())
          }
          dispatch({
            type: USER_DETAILS_FAIL,
            payload: message,
          })
        }
      }


      export const updateUserProfile = (user) => async (dispatch, getState) => {
        try {
          dispatch({
            type: USER_UPDATE_PROFILE_REQUEST,
          })
          const {
            userLogin: { userInfo },
          } = getState()
      
          const config = {
            headers: {
                'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.data.token}`,
            },
          }
          const { data } = await axios.put('/api/user/update', user, config)   
                    
          dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
            payload: data,
          })

          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
          })

          localStorage.setItem('userInfo', JSON.stringify(data))

        } catch (error) {
          const message = error.response && error.response.data.message
          if (message === 'Not authorized, token failed') {
            dispatch(logout())
          }
          dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: 
            error.response && error.response.data.message
            ? JSON.parse(error.response.data.message)
            : [error.response],
          })
        }
      }


      export const listUsers = () => async(dispatch, getState) => {
        try {
          dispatch({
            type: USER_LIST_REQUEST,
          })
          const {
            userLogin: { userInfo },
          } = getState()
      
          const config = {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
            },
          }

          const { data } = await axios.get('/api/users', config)   

          dispatch({
            type: USER_LIST_SUCCESS,
            payload: data,
          })
        } catch (error) {
          const message =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          if (message === 'Not authorized, token failed') {
            dispatch(logout())
          }
          dispatch({
            type: USER_LIST_FAIL,
            payload: message,
          })
        }
      }


      export const deleteUser = (id) => async(dispatch, getState) => {
        try {
          dispatch({
            type: USER_DELETE_REQUEST,
          })
          const {
            userLogin: { userInfo },
          } = getState()
      
          const config = {
            headers: {
              Authorization: `Bearer ${userInfo.data.token}`,
            },
          }

          const { data } = await axios.delete(`/api/users/${id}`, config)   

          dispatch({type: USER_DELETE_SUCCESS})
        } catch (error) {
          dispatch({
            type: USER_DELETE_FAIL,
            payload:error
          })
        }
      }



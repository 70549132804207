import React, { useState } from "react";
import axios from "axios";
import Loader from "./Loader";


const ProductAddImage = ({productId, userInfo, notifyImg, setNotifyImg}) => {

    const [images, setImages] = useState([])
    const [loadingUpload, setLoadingUpload] = useState(false)

    const [responseMsg, setResponseMsg] = useState( {
        status:"",
        message:"",
        error:"",
    })

    const fileValidate = (file) => {
        if (
            (file.type === "image/png" && file.size < 150000) ||
            (file.type === "image/jpg" && file.size < 150000) ||
            (file.type === "image/jpeg" && file.size < 150000) ||
            file.type === "application/pdf" 
          ) {
              return true;
            } else {
                setResponseMsg( {error: "Files allowed jpg,png, pdf, jpeg and image size less than 150 KB"})
                return false;
            }
        }

    const submitHandler = (e) => {
        e.preventDefault();
     const sentImage =  async () => { 
        setLoadingUpload(true)

         try {
            const imageData = new FormData();
            imageData.append("images[]", images)
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                   Authorization: `Bearer ${userInfo.data.token}`,
                }
            }
         const resp = await axios.post(`api/products/${productId}/media`, imageData, config)
                if (resp.status === 200) {
                setResponseMsg({
                    status: resp.data.status,
                    message: resp.data.message
                })    
            }
        } catch(error) { 
      }
    }
    setLoadingUpload(false)
    sentImage()
    setNotifyImg(!notifyImg)
}

 const handlePicInput = (e) => {
    if(fileValidate(e.target.files[0])) {
    setImages(e.target.files[0])
    }
}


    return (
        <div className="pt-6 pb-6">
     <div className="flex justify-center mt-8">
      <div className="rounded-lg shadow-xl bg-gray-50 w-full">
        {loadingUpload && <Loader/>}
      <form onSubmit={submitHandler} encType="multipart/form-data" id="imageForm" >
        <div className="m-2">
            <label className="inline-block mb-2 text-gray-500">Upload Main
                Image(jpg,png,svg,jpeg)</label>
            <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                    <div className="flex flex-col items-center justify-center pt-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            className="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fillRule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clipRule="evenodd" />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                            Select a photo</p>
                    </div>
                    <input type="file" name="image" multiple onChange={handlePicInput} className="opacity-0" />
                    <span className="bg-red-200"> {responseMsg.error} {responseMsg.message} </span>
                </label>
            </div>
        </div>
        <div className="flex p-2 space-x-4">
            <button  type="submit" className="px-4 py-2 text-white bg-green-500 rounded shadow-xl">UPLOAD</button>
        </div>
        </form>
     </div>
   </div>
        </div>
    )
}

export default ProductAddImage
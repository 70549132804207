import React, {useState} from 'react'
import { TextField } from '@mui/material';
import axios from 'axios';

const CouponItem = ({
    coupon,
    error,
    setError,
    setNotify,
    notify,
    setCoupons,
    coupons
}) => {

    const [email, setEmail] = useState('')


    const sentEmailHandler = (email, code) => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   

        const sentCoupon = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          const couponData = {
            email: email ,
            code: code,
          }
          try { 
      await axios.post('/api/coupons/sent', couponData, config);
      
    } catch (err){
             setError(err.response.data.message)
          }
        
        };
        sentCoupon();
        setEmail('')
        setNotify(!notify)
        }

        const deleteHandler = (code) => {
            const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
      
            const deleteCoupon = async () => {
              const config = {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                  }
              }
              try {
              await axios.delete(`api/coupons/${code}`, config)
              setCoupons(coupons.filter((coupon)=> {return coupon.code !== code}))
            } catch (err) {
              setError(err.response.data.message)
            }
            }
            deleteCoupon()
            setNotify(!notify)
          }

  return (
    <tr >
    <td className="px-6 py-4 whitespace-normal">
      <div className="flex items-center">
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {coupon.code}
          </div>
        </div>
      </div>
    </td>
    <td className="px-1 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">{coupon.type}</div>
    </td>
    <td className="px-1 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">{coupon.value}</div>
    </td>
    <td className="px-1 py-4 whitespace-nowrap">
      <div className={` text-sm text-gray-500 text-center 
      ${coupon.couponState==='Active' ? 'bg-blue-200' : 
      coupon.couponState==='Used' ? 'bg-green-200' : 
      'bg-red-200'}`} >
       {coupon.couponState==='Used' ? coupon.recipient : coupon.couponState}
       </div>
    </td>
    {coupon.couponState !== 'Used' && (
    <td className="px-1 py-4 whitespace-nowrap text-left text-sm font-medium">
    <button onClick={()=>deleteHandler(coupon.code)}  className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
          DELETE
   </button>
    </td>
    )}
    {coupon.couponState === 'Active' && (
     <td className="px-1 py-4 whitespace-nowrap text-left text-sm font-medium">
     <TextField         
     className='w-full'
     margin='dense' 
     id="email" 
     type="email" 
     label="Email" 
     variant="outlined" 
     onChange={(e)=>setEmail(e.target.value)}
      />
    </td>               
    )}
    <td className="px-1 py-4 whitespace-nowrap text-left text-sm font-medium">
  { email &&  <button onClick={()=>sentEmailHandler(email, coupon.code)}  
    className="bg-transparent hover:bg-green-500 text-green-700 font-semibold 
    hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
          SENT
   </button>
  }
    </td>
  </tr>

    )
}

export default CouponItem
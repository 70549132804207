import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion";

const CarouselDescription = ({slideDescription, loading}) => {
  
    useEffect (()=> {
            if(loading) {return};
      }, [slideDescription, loading])

  return (
    <>
    {!loading && (
      slideDescription.map((review) => (
       <motion.p
       key={review.id}
       animate={{ opacity: 1, scale: 1 }}
       transition={{
           duration: 3,
           delay: 0.1,
       }}
       initial={{ opacity: 0, scale: 0.1 }}
       className="text-[0.5rem] md:text-xs lg:text-sm font-myriad px-4 md:px-0 xl:text-base"
       > 
       {review.review}  
       </motion.p>
      ))
        )}
    </>
  )
}

export default CarouselDescription
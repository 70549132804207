import React from 'react'
import Header  from '../components/Header.js';
import Footer from '../components/Footer.js';
import {motion} from 'framer-motion/dist/framer-motion';
import Product from '../components/Product'
import Bottom from '../components/Bottom.js';

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: { duration:1.5}
    },
    exit:{
        opacity: 0,
        transition: {ease: 'easeInOut', duration:1}
    }
  }

export const PublisherPageTheme = ({data, filteredProducts, publisher}) => {
  return (
      <>
    { data && (
        <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
<Header/>    
<div className=" h-auto  px-20 mt-20">
<div className="text-3xl bg-white align-center text-center pb-5"> Εκδόσεις {publisher} </div>
  <div className="px-10 flex border bg-gray-100  py-10">
    <div className="py-10 w-full lg:w-1/2">
      <img className="rounded-lg shadow-xl lg:hidden" src="https://picsum.photos/id/866/800/640" alt=""/>
      <p className="p-4 text-xl">{data.content}</p>
    </div>

    <div className="hidden lg:block lg:w-1/2 lg:relative">
      <img className="absolute inset-0 h-full w-full object-cover object-center" src={`http://api.alfeios.gr/storage/${data.url}`} alt=""/>
    </div>
</div>

<div className="grid grid-cols-2 gap-1 px-20 lg:grid-cols-5 lg:gap-2 py-4 lg:px-40">
   {filteredProducts.map((product) => (
       <Product key={product.id} product={product}/>
   ))}
</div>
</div>
<Footer/>
<Bottom/>
        </motion.div>
  )}
  </>
  )
}

import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { saveShippingAddress } from '../actions/cartActions';
import { createOrder } from '../actions/orderActions';
import {CheckoutSchema} from '../components/CheckoutSchema';
import { useTranslation } from "react-i18next";
import {Zone1} from  '../presentationData/shippingData'
import {Zone2} from  '../presentationData/shippingData'
import { Postals } from '../presentationData/shippingData';
import { weightsZ1 } from '../presentationData/shippingData';
import { weightsZ2 } from '../presentationData/shippingData';
import { weightsZ3 } from '../presentationData/shippingData';
import { inCity, payOnDelivery } from '../presentationData/shippingData';
import { outCity } from '../presentationData/shippingData';
import { exportshippingDiscount } from '../presentationData/shippingData';
import { PlaceOrderPageTheme } from '../theme/PlaceOrderPageTheme';
import Footer from '../components/Footer.js';
import Bottom from '../components/Bottom';

export const PlaceOrderPage = ({history}) => {

    const { t, i18n } = useTranslation();

    const dispatch=useDispatch()

    const formatNum = (num) => {
        return ((num*100/100).toFixed(2))
    }

    const CalcShippingPrice = (country, payMethod, postal, totalW) => {
      if (country === 'Greece') {
          if (payMethod === 'Cash') {
    
              return (totalW <=2 ? 
                 payOnDelivery[0] :
                 payOnDelivery[0]+((Math.ceil(totalW)-2)*payOnDelivery[1]))
          }
            else {   
              if (postal[0]==='1')
              {  // "am in athens"
        
                return (totalW <=2 ? inCity[0] : inCity[0]+((Math.ceil(totalW)-2)*inCity[1]))
              }
                else {
                // ("out of athens")
                return (totalW <=2 ? outCity[0] : outCity[0]+((Math.ceil(totalW)-2)*outCity[1]))
                }
            }
        }
        else if (Zone1.includes(country)) {
            // ("am in zone 1")
             return (weightsZ1[totalW-1]-(exportshippingDiscount*weightsZ1[Math.ceil(totalW)-1]))
        }
        else if (Zone2.includes(country))
          {  // ("am in zone 2")
             return (weightsZ2[totalW-1]-(exportshippingDiscount*weightsZ1[Math.ceil(totalW)-1]))           
          }
           else { // ("am in 3rd zone")
            return (weightsZ3[totalW-1]-(exportshippingDiscount*weightsZ1[Math.ceil(totalW)-1]))                     
        }
      }
    


    const cart = useSelector(state=>state.cart)

     const {cartItems} = cart

     const {coupon} = cart

     // multiply 0.001 since it is in grams
     const totalWeight = Math.ceil(cartItems.reduce((acc, item) => acc+(item.weight*item.qty*0.001), 0))

     cart.itemsPrice = formatNum(cartItems.reduce((acc, item) => acc+((
      item.discountPlan ? Math.round(((item.price-(item.discountPlan.percentage*item.price*0.01)) + Number.EPSILON) * 100) / 100
      : item.discount > 0 ? Math.round(((item.price-item.discount) + Number.EPSILON) * 100) / 100 
      : item.price
       ))*item.qty, 0))
     
  cart.shippingPrice = cart.shippingMethod!=="pick" && (cart.shippingAddress.country === 'Greece' ? cart.itemsPrice< 50 : true) ? 
  CalcShippingPrice(cart.shippingAddress.country, cart.paymentMethod, cart.shippingAddress.postalCode, totalWeight).toFixed(2) 
  : 0

     cart.taxPrice = Math.round((0.0*cart.itemsPrice))

     const couponDiscount = coupon.data ? coupon.data.value : 0;


       cart.totalPrice = formatNum(Number(cart.itemsPrice)+Number(cart.shippingPrice)+Number(cart.taxPrice) - couponDiscount) > 0 ?
       formatNum(Number(cart.itemsPrice)+Number(cart.shippingPrice)+Number(cart.taxPrice) - couponDiscount) : 0

     const orderCreate = useSelector(state=>state.orderCreate)

     const {order, success, error} = orderCreate

     useEffect(()=> {
       if(cartItems.length ===0 ) {
         history.push('/')
       }
       else if(!cart.shippingMethod) {
        history.push(`/shipping`)
       }
       else if(Object.keys(cart.paymentMethod).length == 0) {
         history.push('/payment')
       }
        else if(success) {
            history.push(`/order/${order.data.order_id}`)
        }
                // eslint-disable-next-line
    }, [history, success])

    const placeOrderHandler = () => {
        dispatch(createOrder({
            cartItems:cart.cartItems,
            shippingName:cart.shippingAddress.name,
            shippingAddress:cart.shippingAddress.address,
            shippingCity:cart.shippingAddress.city,
            shippingPostalCode:cart.shippingAddress.postalCode,
            shippingCountry:cart.shippingAddress.country,
            notes:cart.shippingAddress.notes,
            payment_method: cart.paymentMethod,
            shippingMethod: cart.shippingMethod,
            shippingPrice:cart.shippingPrice,
            itemsPrice: cart.itemsPrice,
            totalPrice: cart.totalPrice,
            coupon: cart.coupon
        }))    
    }

    return (
      <>
        <PlaceOrderPageTheme cart={cart} cartItems={cartItems} placeOrderHandler={placeOrderHandler}/>
      </>
    )
}

import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux' 
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from '../components/Loader';
import Pagination from 'react-js-pagination';
import Product from '../components/Product';
import Bottom from '../components/Bottom';
import CategoriesMenuItem from '../components/CategoriesMenuItem';
import ProductSwiper from '../components/ProductSwiper';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import ProductRelatedSwiper from '../components/subComponents/ProductRelatedSwiper';
import Seo from '../components/Seo';

const ProductTagPage = ({match}) => {
    const {categories} = useSelector((state)=> state.categoryList)
    const [param, setParam] =  useState(match.params.slug); 
    const [pageNum, setPageNum] = useState('')
    const [results, setResults] = useState('')
    const [total, setTotal] = useState('')
    const [current, setCurrent] = useState('')
    const [perpage, setPerPage] = useState('')
    const [loading, setLoading] = useState(true)
    const [selectedCategory, setSelectedCategory] =useState('')

    const showBox=false
    const { t, i18n } = useTranslation();

    useEffect(()=> {
        const fetchData = async () => {
            const {data} = await axios.get(`/api/tags-products/${match.params.slug}?page=${pageNum}`);
            // setPerPage(data.data.meta.per_page)
            // setCurrent(data.data.meta.current_page)
            // setTotal(data.data.meta.total)
            setResults(data.data.data)
            setLoading(false)
        }
        fetchData()
    },[pageNum])

    useEffect(()=> {

    },[selectedCategory])

  return (
<div className='h-screen bg-white font-astana'>

<Seo 
   title = {`${match.params.slug}`}
   description = {`Ναυτίλος βιβλία ${match.params.slug}`}
   name="description"
   href={`/tags-products/${match.params.slug}`}
   /> 

<div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[4vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
<BreadCrumb category = {match.params.slug} t={t}  /> 
</div>
 <div className='flex  px-[4vh] 
lg:px-[10vh]  w-full'>
 {loading === false && (
    <ul className='w-40 md:w-1/6'>
     { categories.map( (category) =>  (
       category.parent_id == 0 && 
       <CategoriesMenuItem 
       key={category.id}
       selectedCategory={selectedCategory} 
       setSelectedCategory={setSelectedCategory} 
       category={category} 
       />
       ))}
    </ul>
  )}

{loading ? <Loader/> : <>
<div className='w-4/5'>
<div className="grid grid-cols-1 sm:grid-cols-2 gap-1 px-10 lg:grid-cols-3 lg:gap-2 py-6 lg:px-20">
{ results ? 
selectedCategory ?
results.filter((product)=> product.categories.some((item) => item.id === selectedCategory.id)).map((product) =>(<Product  key={product.id} product={product}/>))
: 
results.map((product) =>(<ProductRelatedSwiper  key={product.id} product={product}/>))
:
<p className='text-2xl p-40 p-2'> {t('notFound')}</p>
}
</div>
</div>
</>
}
</div>
</div>
  )
}

export default ProductTagPage
import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import Carousel from './Carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';
import CarouselDescription from './subComponents/CarouselDescription';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from './Loader';

const DisplayCarousel = () => {

    const [productId, setProductId] = useState('')
    const [slideDescription, setSlideDescription] = useState([])
    const [loading, setLoading] = useState(true)
    const [slides, setSlides] = useState([])  
    const [slidesData, setSlidesData] = useState('')
    const history = useHistory();
    const [reviews, setReviews] = useState([])

    useEffect(() => {

        const fetchImages = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
              }
          }
          const result = await axios.get(
            `api/banner`, config
          );
          result.data.map((item) => (
            propSlide(item)
          ))
          setSlidesData(result.data)
        };
        fetchImages();
      },[]);



  useEffect(() => {
    if(!slidesData) {return}
        const fetchReviews = async () => {
          for (const item of slidesData) {
             const {data} =  await axios.get(`/api/product/${item.image_description}`)
             setReviews(reviews =>[...reviews, data.data.reviews])
             item.slug = data.data.slug;

            }
            setLoading(false)
        }
        fetchReviews();
  }, [slidesData])

   
      const propSlide = (item, index) => {
        slides.push(
            <SwiperSlide
            id="home"
            className='w-full h-[100%]' 
            key={`slide-${index}`}
            tag="li"
            >
              <div className='flex relative cursor-pointer justify-center h-[100%] '>
              <img 
              style={{
                width:"100%",
                height: "100%",
                objectFit: "contain",  
                display: "block",
                position: "absolute",
                padding: "2px"
              }}
              alt={`slide-${index}`}
              src={`${process.env.REACT_APP_API_ENDPOINT}storage/${item.url}`}
              onClick={()=>{ item.image_description && history.push(`/product/${item.slug}`)}}
              />
              </div>
            </SwiperSlide>
          )  
        }

return (
<>
    { loading ? <Loader/> : (
    <div className='md:grid md:grid-cols-3 h-full md:h-screen'>
        <div className='w-full grid content-start md:content-center col-span-1 mt-4 md:my-0 lg:relative h-16 md:h-full'>
        <div className='text-center items-center  w-full text-xs sm:text-sm md:text-base lg:absolute  lg:bottom-[6%]   lg:text-right'>
    { slideDescription  && (
       <CarouselDescription
        slideDescription={slideDescription}
        loading={loading}
        />          
    )}
        </div>
    </div>
    <div className='md:col-span-2  w-full h-3/4 md:h-[100%]'>
    <a className="custom_next z-30 absolute cursor-pointer h-6 md:h-8 lg:h-10 xl:h-12 w-auto top-1/2 lg:top-32 right-4">
    <svg   xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
  <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
</svg>

    </a>
        <div className='relative z-20 h-[90%]  lg:w-[76%] xl:w-[74%] 2xl:w-[68%] mx-auto'>
        <Carousel
        reviews= {reviews}
        loading = {loading} 
        slides = {slides}
        slidesData = {slidesData}
        setSlideDescription={setSlideDescription}/>
        </div>
    </div>
    </div>
    )
}
    </>
  )
}

export default DisplayCarousel
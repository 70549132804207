import React, {useState} from 'react'
import { FacebookShareButton } from "react-share";
import { useTranslation } from "react-i18next";
import ProductPdfViewer  from '../components/ProductPdfViewer';
import { GlobalButton } from './generalComponents';
import { Disclosure } from '@headlessui/react'
import { useHistory } from "react-router-dom";
import BreadCrumb from '../components/subComponents/BreadCrumb';
import RelatedProducts from '../components/RelatedProducts';

export default function ProductPageTheme({
  addToCartHandler,
  product,
  tagsList,
  qty,
  setQty, 
  categoriesList,
  pdf
  }) {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const mainImage =
    product.data.media.filter((el) => el.isMain).length ?  product.data.media.filter((el) => el.isMain ).map(el=>el.pathway) 
    : product.data.media.length > 0 ?  product.data.media[0].pathway : ""
    const icon = `${process.env.REACT_APP_URL}images/icons/cart-icon.png`



  return (
 <div className="min-h-screen font-astana bg-bgImage">
  <div className='w-full 
h-20
lg:h-32 
pt-12
z-20
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-white'>
 <BreadCrumb 
 category = {product.data.title.substring(product.data.title,30).replace(/<\/?[^>]+(>|$)/g, "")+ '...'} 
 t={t} /> 
</div>
  <div className='h-full px-20 py-10'>
  <div className='grid grid-cols-1 h-full lg:grid-cols-2'>

  <div className='w-full grid gap-2 lg:grid-cols-2 h-full '>
  <div className='w-full h-full'>
  <img 
  src={`${process.env.REACT_APP_API_ENDPOINT}storage/${mainImage}`} 
  alt="product"
  width="auto"
  height="auto"
  className="object-contain max-h-[80%] w-full rounded"
  />
  { pdf &&
  <div className='absolute p-8'> 
  <ProductPdfViewer pdfPath={pdf}/>
   </div>
  }
      <div className="flex mt-4 flex-col">
          <div className="flex">
            <div className="space-x-2 text-sm pb-1 title-font text-gray-500">
            {product &&  product.data.tags.map((obj) =>
        <button onClick={(e)=>{history.push(`/tags-products/${obj.slug}`)}} 
        className="inline-block" 
        key={`${obj.id}`}>{obj.name}
        </button>
        )}
           </div>
          </div>
          {product.data.stock >0 && 
          <>
         <div className="flex ml-6 items-center">
          </div>
          <div className='px-2'> 
            <p> {product.data.condition ==='Level1' ? 'Αριστη' : product.data.condition ==='Level2' ? 'Πολύ καλή' : 'Καλή'
            } | {  product.data.cond_cmnt !== 'Condition Comment' && product.data.cond_cmnt} </p>
          
          </div> 
              </>
           }
        </div>
  </div>
  <div className='w-full'>
        <div className="space-x-1 text-sm  title-font text-gray-500">
        {product &&  product.data.categories.map((obj) =>
        <button onClick={(e)=>{history.push(`/category-products/${obj.slug}`)}} 
        className=" inline-block" key={`${obj.id}`}>{obj.name} |</button>
        )}
        </div>
        <p className="text-gray-900 text-sm lg:text-lg  title-font font-medium mb-1">{product.data.author}</p>
        <p className="text-gray-900  text-sm lg:text-lg  title-font font-medium mb-1">{product.data.publisher}</p>
        <p className="text-gray-900  text-sm lg:text-lg  title-font font-medium mb-1">&euro; 
        { 
        product.data.discountPlan ? Math.round(((product.data.price-(product.data.discountPlan.percentage*product.data.price*0.01)) + Number.EPSILON) * 100) / 100
        : product.data.discount > 0 ? Math.round(((product.data.price-product.data.discount) + Number.EPSILON) * 100) / 100 
        : product.data.price
        }</p>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button 
              className="flex w-full justify-between
              py-1 text-left text-md font-medium 
              hover:bg-gray-200 
              focus:outline-none focus-visible:ring 
              focus-visible:ring-gray-200 
              focus-visible:ring-opacity-75">
          {product.data.content && product.data.content !== 'Content' ?<span>{product.data.content.substring(0,450).replace(/<\/?[^>]+(>|$)/g, "")} ...</span> : ''}
                <a
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pb-2 text-md text-gray-700">
              {product.data.content.substring(450,product.data.content.length).replace(/<\/?[^>]+(>|$)/g, "")}   
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className='w-full flex'>
        <div className='w-1/2 mt-8'>
          {product.data.stock >0 ?
        <button
        className="w-10"
        onClick={addToCartHandler} >
        <img 
        width="60%" 
        height="100%" 
        src={icon}
        alt="add to Card"
        />
        </button> : <p className='text-sm md:text-md lg:text-lg text-red-800 italic'> {t('expired')}</p>
          }
        </div>

      </div>
      </div>
      </div>
      <div className="h-2/3">
      <RelatedProducts 
      author={product.data.author}
      id={product.data.slug} 
      publisher = {product.data.publisher}
      categories = {product.data.categories ? product.data.categories : ''}/>
      </div>
      </div>
      </div>
      </div>
        )
}
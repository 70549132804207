import React, {useState, useEffect} from 'react'
import { listProductDetails, createReview} from '../../actions/productActions'
import { PRODUCT_REVIEW_RESET } from '../../constants/productConstants';
import { ProductPageReviewTheme } from '../../theme/ProductPageReviewTheme'
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';

const ProductAddReviewComponent = ({product, userInfo}) => {

    const [review, setReview] = useState('')
    const productReview = useSelector((state)=>state.productReview)
    const {loading: loadingReview, success: successReview, error:errorReview} = productReview
    const dispatch = useDispatch();

    useEffect(()=> {

        if(successReview) {
          alert('Review Submitted')
          setReview('')
        }
        },
       [dispatch, successReview]
       );

       const reviewSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(createReview(
          product.data.id, {
          review: review
        }))
      }

      const deleteReview = async (id) => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
        const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,  
          }
        }
        try {    
     const response = await axios.delete(`api/reviews/${id}`, config);
        } catch (err) {
        console.log(err)
      }
      }

  return (
     <ProductPageReviewTheme
    deleteReview={deleteReview} 
    review={review}
    setReview={setReview}
    errorReview={errorReview} 
    product={product} 
    userInfo={userInfo} 
    reviewSubmitHandler={reviewSubmitHandler}/>
  )
}

export default ProductAddReviewComponent
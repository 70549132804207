import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { saveShippingAddress } from '../actions/cartActions';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ShippingPageTheme from '../theme/ShippingPageTheme';
import { getUserProfile } from '../actions/userActions.js';


const  ShippingPage = ({history}) => {
    const userLogin = useSelector((state) => state.userLogin)
    const {userInfo} = userLogin

    const userDetails = useSelector((state) => state.userDetails)
    const {error, user} = userDetails

    const [isValidForm, setIsValidForm] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const cart = useSelector(state => state.cart)
    const {shippingMethod} = cart
    const {shippingAddress} = cart
    const [errors, setErrors]= useState({})
    const [name, setName] = useState(shippingAddress.Name ? shippingAddress.Name : user.data ? user.data.name : '')
    const [email, setEmail] = useState(shippingAddress.Email  ? shippingAddress.Email : user.data ? user.data.email : '')
    const [address, setAddress] = useState(shippingAddress.address ? shippingAddress.address : user.data ? user.data.address : '')
    const [city, setCity] = useState(shippingAddress.city ? shippingAddress.city : user.data ?  user.data.city : '')
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode ? shippingAddress.postalCode :user.data ? user.data.postalCode : '')
    const [country, setCountry] = useState(shippingAddress.country ? shippingAddress.country : user.data ? user.data.country : '')
    const [notes, setNotes] = useState(shippingAddress.notes)
    const dispatch = useDispatch()


    const handleValidation = () => {
      if(shippingMethod==="pick") { name ?  setIsValidForm(true) : setErrors({
            ...errors, 
            name: "the name is required"
        }); 
    } else {        
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const postalRegex = /^\d+$/;
        if(name && email && regex.test(email) && address && city && postalCode && postalRegex.test(postalCode) && country) {
            setIsValidForm(true)
        } else { setErrors({...errors, name: "Παρακαλώ συμπληρώστε όλα τα πεδία. Ταχ κωδικός μόνο ψηφία"})}
    }
}

useEffect(() => {
    if (!userInfo) {
    history.push('/login')
    } else {
      if (!user || !user.data) {
        dispatch(getUserProfile())
      } else {
         setName(shippingAddress.Name ? shippingAddress.Name : user.data ? user.data.name : '')
         setEmail(shippingAddress.Email  ? shippingAddress.Email : user.data ? user.data.email : '')
         setAddress(shippingAddress.address ? shippingAddress.address : user.data ? user.data.address : '')
         setCity(shippingAddress.city ? shippingAddress.city : user.data ?  user.data.city : '')
         setPostalCode(shippingAddress.postalCode ? shippingAddress.postalCode :user.data ? user.data.postalCode : '')
         setCountry(shippingAddress.country ? shippingAddress.country : user.data ? user.data.country : '')
      }
    }
  }, [user])

    useEffect(() => {
        if (isSubmit && isValidForm) {
         dispatch(saveShippingAddress({name, email, address, city, postalCode, country, notes}));
           history.push('/payment');
        }
        
    }, [isValidForm, isSubmit, errors])

    const submitHandler = (e) => {
        e.preventDefault()
        handleValidation()
        setIsSubmit(true)
    }

    return (
        <div>
            <ShippingPageTheme
            errors={errors}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            shippingMethod={shippingMethod}
            shippingAddress={shippingAddress}
            address={address}
            setAddress={setAddress}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            note={notes}
            setNotes={setNotes}
            submitHandler={submitHandler}
            isSubmit={isSubmit}
            isValidForm={isValidForm}
            CountryDropdown={CountryDropdown}/>
        </div>
    )
}


export default ShippingPage;
import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";

export default function Product({ history, product}) {
  const { t, i18n } = useTranslation();

    const mainImage =
    product.media.filter((el) => el.isMain).length ?  product.media.filter((el) => el.isMain ).map(el=>el.pathway) 
    : product.media.length > 0 ?  product.media[0].pathway : ""

    const [hoverProduct, setHoverProduct] = useState(false)
    
    const icon = `${process.env.REACT_APP_URL}images/icons/cart-icon.png`

    return (
        <>
 <div className="h-[75%] mx-auto md:mx-0 mt-4"
 onMouseEnter={()=>setHoverProduct(true)}
 onMouseLeave={()=>setHoverProduct(false)} 
 >

    <div className="flex relative h-full w-auto">
        { mainImage ?
          <img className='object-cover w-auto h-full'
            src={`${process.env.REACT_APP_API_ENDPOINT}storage/${mainImage}`} 
            alt={product.slug}
            width="auto"
            height="auto"
            />  : <div className='w-full border-2 flex flex-col items-center border-theme-base-color2 h-full'>
              <p className='pt-14 px-2'> {product.title} </p>
              <p className='pt-2 px-2'> {product.author} </p>
              <p className='pt-2 px-2'> {product.publisher} </p>
              <p className='pt-2 px-2 text-theme-base-color2'> Μή διαθέσιμο εξώφυλλο </p>
              </div>
        }

        {
        product.discountPlan && 
        <p className='absolute m-1 p-2 text-sm rounded-full border-2 text-white bg-theme-base-color2 '> -{product.discountPlan.percentage}%
        </p> 
        }

    { hoverProduct && 
        <div className="absolute
         font-astanaM
         border-2 bg-theme-base-color3
         h-full w-full
         place-content-center
         z-10">
        <div className="pt-2 md:pt-4 px-2 md:px-4 text-xs md:text-sm">
          <p className='xl:pt-4 pt-2
           uppercase
           md:text-sm
           text-xs  
           italic 
           font-extrabold
           tracking-wide
           flex'>{product.title}
           </p>        
          <p className='pt-1 text-xs lg:text-sm italic font-extrabold'>
            {product.author} 
          </p>
          <p className='pt-1 text-xs lg:text-sm italic font-extrabold flex '>
            {product.publisher} {product.year_published}
           </p> 
           <p className='pt-1 text-xs lg:text-sm italic font-extrabold flex '>
             {t('pages')} {product.pages_no} 
           </p> 
          <p className='py-2  flex'>
              
              { product.discountPlan ? 
                <h1>{Math.round(((product.price-(product.discountPlan.percentage*product.price*0.01)) + Number.EPSILON) * 100) / 100} </h1>
                : product.discount > 0 ? 
                <h1>{Math.round(((product.price-product.discount) + Number.EPSILON) * 100) / 100} &euro;</h1> : ''
              }

          <h1  className={`px-1 ${(product.discountPlan || product.discount>0) && ' line-through text-gray-800'}`} >{product.price} &euro;</h1>
          </p>
          <h2 className='py-1 font-bold flex'>{product.stock >0 ? "Διαθέσιμο" : "Εξαντλημένο"}</h2>
          </div>
         <div className=' flex flex-row pt-6 mx-10'>
         <button className='w-6 mx-2 h-auto'>
        <VisibilityIcon onClick={()=> {history.push(`/product/${product.slug}`)}}/>
        </button>     
        {product.stock>0 ? 
        <button className='w-6 mx-2  h-auto'
         onClick={()=> {history.push(`/cart/${product.id}?qty=1`)}}> 
        <img className='w-auto  h-full' src={icon}/>
        </button>
: null }

        
        </div>
        </div> 
     }
        </div>
</div>
        </>

    )
}

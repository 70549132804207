import React from 'react'

const CartHeaderIcon = ({
     cartItems,
     icon
    }) => {

  return (
    <div className='flex flex-row mb-1 mx-2'>
    <h3>
      {cartItems.length>0 ? cartItems.length : null}
    </h3>   
    <a className='w-6' href="/cart"> 
    <img className='w-6 h-auto' src={icon}/>
    </a>
    </div>
  )
}

export default CartHeaderIcon
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {addToCart, removeFromCart, saveShippingMethod, addCouponToCart} from '../actions/cartActions';
import { useTranslation } from "react-i18next";
import {
  AnimatePresence,
  motion } from 'framer-motion/dist/framer-motion';
import Header from '../components/Header';
import { CartPageTheme } from '../theme/CartPageTheme';


  const containerVariants = {
    hidden: {
      opacity:0,
    },
    visible : {
      opacity:1,
      transition: {delay:0, duration:1}
    },
    exit: {
      x: '-100vw',
      transition: {duration:1,}
    }
  }

export default function CartPage({history, match, location}) {

const { t, i18n } = useTranslation();
const userLogin = useSelector((state)=> state.userLogin)
const {userInfo} = userLogin
const productId = match.params.id;
const qty = location.search ? Number(location.search.split('=')[1]) : 1;
const cart = useSelector(state=>state.cart);
const {shippingMethod} = cart
const {cartItems} = cart;
const [couponCode, setCouponCode] = useState('')
const [errorCode, setErrorCode] = useState('')
const {coupon} = cart;

const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
}

const checkOutHandler = () => {
  !userInfo ? history.push('/login?redirect=shipping') : history.push('/shipping')
}

const dispatch = useDispatch()

useEffect(()=> {
    if (productId) {
        dispatch(addToCart(productId, qty))
        dispatch(saveShippingMethod('pick'))
    }
}, [dispatch, productId, qty])

const addToCartHandler = (productId, qty)  => {
    dispatch(addToCart(productId, qty))
  }

  const saveShippingMethodHandler= (method) => {
    dispatch(saveShippingMethod(method))
  }


  const submitCoupon = () => {
    setErrorCode('')
    couponCode && !coupon ?  
    dispatch(addCouponToCart(couponCode))
     : setErrorCode('please provide a coupon Code')
    setCouponCode('')
  }

  const totalPrice = cartItems.reduce((acc, item)=> acc+ item.qty*( 
    item.discountPlan ? Math.round(((item.price-(item.discountPlan.percentage*item.price*0.01)) + Number.EPSILON) * 100) / 100
    :   
    item.discount > 0 ? 
    Math.round(((item.price-item.discount) + Number.EPSILON) * 100) / 100 : 
    item.price ), 0).toFixed(2)

  const totalPlusCouponPrice =              (
    cartItems.reduce((acc, item)=> acc+ item.qty*(
     item.discountPlan ? Math.round(((item.price-(item.discountPlan.percentage*item.price*0.01)) + Number.EPSILON) * 100) / 100
     :   
     item.discount > 0 ? 
     Math.round(((item.price-item.discount) + Number.EPSILON) * 100) / 100 : 
     item.price ), 0)- 
      (coupon ? coupon.data.value : 0)).toFixed(2) 


    return (   
   <motion.div 
      variants  = {containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit">  
      <CartPageTheme
       error={cart.error}
       coupon={coupon}
       errorCode = {errorCode}
       couponCode={couponCode}
       submitCoupon={submitCoupon}
       setCouponCode={setCouponCode}
       totalPrice = {totalPrice}
       totalPlusCouponPrice = {totalPlusCouponPrice}
       cartItems={cartItems}
       shippingMethod={shippingMethod}
       saveShippingMethodHandler={saveShippingMethodHandler}
       addToCartHandler={addToCartHandler}
       checkOutHandler={checkOutHandler} 
       removeFromCartHandler={removeFromCartHandler}
     />
  </motion.div>
    )
}

import React, {useState} from 'react'
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/solid'
import Cursor from '../locales/cursor.png'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'

const CategoriesMenuItem = ({category, selectedCategory, setSelectedCategory}) => {
  
  return (
    <Disclosure
    >
    {({ open }) => (
      <>
        <Disclosure.Button 
        className="flex m-2 p-2 w-full justify-between text-left 
        text-sm lg:text-base font-medium text-gray-900 hover:bg-gray-200 
        focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
          <span onClick={(e)=> setSelectedCategory(category)}>{category.name}</span>
          { category.children.length>0 && (
    
       <ArrowForwardIos
       className={`${
        open ? 'rotate-90 transform' : ''
      } h-5 w-5`}/>

)}
        </Disclosure.Button>

        <Disclosure.Panel className="px-2 flex flex-col md:w-full text-sm  lg:text-base text-gray-500">
              { category.children.length>0 && category.children.map( (cat) =>  (
              <CategoriesMenuItem
              key={category.id} 
              selectedCategory={selectedCategory} 
              setSelectedCategory={setSelectedCategory} 
              category={cat} />
       ))
              }  
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
  )
}

export default CategoriesMenuItem
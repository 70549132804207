import React, {useEffect, useRef, useState} from 'react';
import Header from '../components/Header.js';
import { Menu } from '../components/Menu.js';
import DisplayCarousel from '../components/DisplayCarousel.js';
import Loader from '../components/Loader.js';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos.js';
import Seo from '../components/Seo.js';

export default function HomePage({match}) {

    const [showMessage,setShowMessage] =useState(()=> {
        const saved = localStorage.getItem("showMessage");
        const initialValue = JSON.parse(saved);
        return initialValue !== null ? initialValue : true;} )

        useEffect(()=> {
            
        },[showMessage])
            
// Homepage return content 
return (
<>
<Seo 
  title = {`Ναυτίλος Βιβλία`}
  description = {`Βιβλιοπωλείο-Bookstore`}
  name="description"
  href={`/`}
  />
 
<div className="min-h-screen bg-bgImage tracking-wide font-astanaM">
<Header/>
<div className='md:grid  md:grid-cols-4 w-full min-h-screen -mt-32'>
    <div className=' md:col-span-1 h-fit font-astana pt-32'>
    <Menu/>
    </div>
    <div className='md:col-span-3 h-96 md:h-full'>
    <DisplayCarousel/>
    </div>
</div>
</div>
</>
)

}

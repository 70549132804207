import React, { useState } from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";



const AddCalendarEventModal = ({
  isEdit, setIsEdit,
  setRefresh,
  refresh,
  dateInfo, 
  setShowModalCode, 
  onEventAdded, 
  onEventDelete,
  onEventEdit
}) => {
  const [title, setTitle] = useState(dateInfo.title)
  const [active, setActive] = useState(dateInfo.active)
  const [startDate, setStartDate] = useState(dateInfo.dateStart)
  const [endDate, setEndDate] = useState(dateInfo.dateEnd)
  const [eventId] = useState(dateInfo.eventId)

    const onSubmit = (e) =>  {
        e.preventDefault()
        onEventAdded({
            id: new Date().toISOString(),
            title,
            active:active,
            start:startDate,
            end:endDate
        })
        setShowModalCode(false)
    }
  
    const onEditEvent = (e) =>  {
      e.preventDefault()
      onEventEdit({
          id:eventId,
          title,
          active:active,
          start:startDate,
          end:endDate
      })
      setShowModalCode(false)
  }
    

    return (
        <>
  <Modal size="lg" active={true} toggler={() => setShowModalCode(false)}>
            <ModalHeader toggler={() => setShowModalCode(false)}>
               {isEdit ? (<p> EDIT EVENT</p>) : (<p> ADD EVENT</p>)} 
            </ModalHeader>
            <ModalBody>
 <form onSubmit={!isEdit ? onSubmit: onEditEvent} className="w-full max-w-lg">
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <Input 
        type="datetime-local"
        color="lightBlue"
        size="regular"
        outline={true}
        placeholder="Event Start"
        value={startDate}
        onChange = {(e)=> setStartDate(e.target.value)}
      />
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <Input 
        type="datetime-local"
        color="lightBlue"
        size="regular"
        outline={true}
        placeholder="Event End"   
        value={endDate}
        onChange = {(e)=> setEndDate(e.target.value)}       
      />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <Input 
       type="text"
       color="lightBlue"
       size="regular"
       outline={true}
       placeholder="Event Title"
       value={title}
       onChange = {(e) =>  setTitle(e.target.value)}          
      />
      <p className="text-gray-600 text-xs italic"></p>
    </div>
  </div>

  <p className="w-1/4" onClick={(e) => {setActive(!active)}}>
                      {active ? 'ΕΝΕΡΓΟ' : 'ΑΝΕΝΕΡΓΟ'}
                     </p>

  <div className="w-full flow-root">
                       <Button className="float-right"
                    color="red"
                    buttonType="link"
                    onClick={(e) => { onEventDelete(eventId); setShowModalCode(false); setRefresh(!refresh)}}
                    ripple="dark"
                         >
                      Delete
                     </Button>

</div>                     
                 <Button className="float-left"
                    type="submit"
                    color="green"
                    ripple="light"
                >
                    Save Changes
                </Button>
  
    </form>
            </ModalBody>
        </Modal>
    </>
    )
}

export default AddCalendarEventModal

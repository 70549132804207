import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { composeWithDevTools} from 'redux-devtools-extension';
import { productListReducer, 
  productDetailsReducer,
  productCreateReducer,
  productDeleteReducer,
  productReviewReducer,
  productUpdateReducer
 } from '../reducers/productReducers';
import { userLoginReducer,
         userRegisterReducer,
         userDetailsReducer,
         userUpdateProfileReducer,
         userListReducer,
         userDeleteReducer,
      } from '../reducers/userReducers.js';
import {  orderCreateReducer, orderDetailsReducer, orderPayReducer, orderListReducer, orderStatusReducer, orderListProfileReducer, orderDeleteReducer } from '../reducers/orderReducers'
import { cartReducer } from '../reducers/cartReducers';
import { categoryListReducer } from '../reducers/categoryReducers';


const reducer = combineReducers({
    userLogin:userLoginReducer,
    userRegister:userRegisterReducer,
    productList:productListReducer,
    productDetails:productDetailsReducer,
    productCreate:productCreateReducer,
    productUpdate:productUpdateReducer,
    productDelete:productDeleteReducer,
    orderDelete:orderDeleteReducer,
    productReview:productReviewReducer,
    categoryList:categoryListReducer,
    userDetails:userDetailsReducer,
    userList:userListReducer,
    userDelete:userDeleteReducer,
    cart:cartReducer,
    userUpdateProfile:userUpdateProfileReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay:orderPayReducer,
    orderList:orderListReducer,
    orderStatus:orderStatusReducer,
    orderListProfile:orderListProfileReducer
});

const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const shippingAddressFromStorage= localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : {}

const shippingMethodFromStorage= localStorage.getItem('shippingMethod') ? JSON.parse(localStorage.getItem('shippingMethod')) : {}

const paymentMethodFromStorage= localStorage.getItem('paymentMethod') ? JSON.parse(localStorage.getItem('paymentMethod')) : {}

const couponFromStorage = localStorage.getItem('coupon') ? JSON.parse(localStorage.getItem('coupon')) : ''

const categoriesFromStorage = localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [];

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage, 
    shippingMethod: shippingMethodFromStorage,
    paymentMethod: paymentMethodFromStorage,
    coupon: couponFromStorage
  },
  categoryList: {categories: categoriesFromStorage},
  userLogin: {userInfo: userInfoFromStorage}
}

const middleware = [thunk]

  const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
  export default store;
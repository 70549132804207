import React from 'react'
import { Link } from 'react-router-dom';
import { CheckoutSchema } from '../components/CheckoutSchema'
import { useTranslation } from "react-i18next";
import { GlobalButton, PageTitle } from './generalComponents';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

export const PlaceOrderPageTheme = ({cart, cartItems, placeOrderHandler}) => {
    const { t, i18n } = useTranslation();

  return (
<div className='h-screen bg-white font-astana'>
<div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('confirmation')} t={t}  /> 
</div>
   <CheckoutSchema  step4 = {true} />
<div class=" font-myriad w-1/2 px-[4vh] 
lg:px-[10vh]  flex flex-col">
 <div class="inline-block">
 <div class="overflow-hidden">             
  <table class="min-w-full text-left text-base lg:text-lg">                  
    <tr className=''>
      <td className=' p-1'>{t('Name')}</td>
      <td className='p-1'>{cart.shippingAddress.name}</td>
    </tr>
    <tr className=''>
      <td className='  p-1' >{t('Address')}</td>
      <td className=' p-1' >{cart.shippingAddress.address}</td>
    </tr>
    <tr className=''>
      <td className='p-1' >{t('City')}</td>
      <td className='p-1'>{cart.shippingAddress.city} {cart.shippingAddress.postalCode}</td>
    </tr>
    <tr className=''>
      <td className='  p-1' >{t('Payment_Method')}</td>
      <td className=' p-1'>{
      cart.paymentMethod ==='Cash' && cart.shippingMethod === 'post' ?
       t('payOnDelivery') : cart.paymentMethod ==='Cash' && cart.shippingMethod === 'pick' ? t('Cash') : 'PayPal'  }</td> 
    </tr>
    {/* { cart.paymentMethod==="Bank" && (
      <tr className=''>
      <td className=' p-1' >{t('AccountNum')}</td>
      <td className=' p-1'>  Eurobank: GR00019223474746565666</td> 
      </tr>)
    } */}
    <tr className=''>
      <td className=' p-1'>{t('Price')}</td>
      <td className='p-1'>{cart.itemsPrice} &euro;	</td>
    </tr>
    <tr className=''>
      <td className=' p-1' >{t('Shipping')}</td>
      <td className=' p-4' >{cart.shippingPrice} &euro;	</td>
    </tr>
    <tr className=''>
      <td className=' p-1' >{t('taxPrice')}</td>
      <td className='border-r p-1'>{t('includedVat')}</td>
    </tr>
    {/* <tr className=''>
      <td className='  p-1' >{t('couponDiscount')}</td>
      <td className=' p-1'> - {cart.coupon.data ? cart.coupon.data.value : 0}</td>
    </tr>    */}
    <tr className=''>
      <td className='  bg-gray-200 p-1' >{t('totalPrice')}</td>
      <td className=' bg-gray-200 p-1'>{cart.totalPrice} &euro;	</td>
    </tr>
  </table>
  </div>
  </div>
  
 </div>
 <div className="pt-10 px-[4vh] 
lg:px-[10vh]  w-full">
            { cartItems.length === 0 ? <h3>your cart is Empty</h3> : (
                <>
            <p className=' text-theme-base-color2 uppercase'>{t('books')}</p>
            {cartItems.map((item, index) => (
                <div className='py-2'>
                <Link key={index}  to={`/product/${item.product}`}> 
                <p className=''>{index+1} | {item.title}</p>
                </Link>
                    </div>
                ))}
               </>
            ) }
                 <button className='mt-10 font-astana uppercase flex flex-row' type='button' 
                 
                    disabled={cartItems.length ===0} 
                    onClick={placeOrderHandler}>
                      <ArrowForwardIos/>
                     {t('confirmation')}           
                 </button>             
</div>
    </div>
  )
}

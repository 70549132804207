import React, { useState } from "react";
import axios from "axios";


const BannerAddImage = ({userInfo, addEvent, setAddEvent}) => {
    const [images, setImages] = useState([''])

    const [responseMsg, setResponseMsg] = useState( {
        status:"",
        message:"",
        error:"",
    })

    const fileValidate = (file) => {
        if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg"
          ) {
              return true;
            } else {
                setResponseMsg( {error: "File allowed jpg,png, jpeg"})
                return false;
            }
        }

    const submitHandler = (e) => {
     e.preventDefault();
     const sentImage =  async () => { 
         try {
            const imageData = new FormData();
            imageData.append("images[]", images)
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                   Authorization: `Bearer ${userInfo.data.token}`,
                }
            }
         const resp = await axios.post('api/banner', imageData, config)

                if (resp.status === 200) {
                setResponseMsg({
                    status: resp.data.status,
                    message: resp.data.message
                })
                setAddEvent(true)   
            }
        } catch(error) { 
 }
    }
    sentImage()
}

 const handlePicInput = (e) => {
    if(fileValidate(e.target.files[0])) {
    setImages(e.target.files[0]) 
  setAddEvent(false)
}
}


    return (
        
     <div className="flex justify-center mt-2">
      <div className="rounded-lg shadow-xl bg-gray-50 w-full">
      <form onSubmit={submitHandler} encType="multipart/form-data" id="imageForm" >
        <div className="m-2">
            <label className="flex mb-2 text-gray-500">
                |jpg,png,svg,jpeg|</label>
            <div className="flex items-center justify-center w-full">
                <label className="flex flex-col w-full h-12 border-4 border-dashed hover:border-red-600">
                    <div className="flex flex-col items-center justify-center ">

                        <p className="text-sm tracking-wider text-gray-400 group-hover:text-red-800">
                            Select </p>
                    </div>
                    <input type="file" name="image" multiple onChange={handlePicInput} className="opacity-0" />
                    <span className="bg-red-200"> {responseMsg.error} {responseMsg.message} </span>
                </label>
            </div>
        </div>
        <div className="flex p-2 space-x-4">
            <button  type="submit" className="px-2 py-2 text-white bg-green-500 rounded shadow-xl">UPLOAD</button>
        </div>
        </form>
     </div>
   </div>
    )
}

export default BannerAddImage
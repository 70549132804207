import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/effect-fade/effect-fade.min.css'
import SwiperCore, {Navigation, EffectFade, Pagination, Autoplay} from 'swiper';
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { display, height, padding } from '@mui/system';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

// Swiper vertical slider 
const Carousel = ({
  reviews,
  loading,
  slideDescription, 
  setSlideDescription,
  slides,
  slidesData
}) => {
  
  const [swiper, setSwiper] = useState('');

    return (
      <>
      {!loading  && (
         <Swiper 
         modules={[EffectFade]}
         effect="fade"
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
      }}
        speed={2000}
         className='w-auto relative h-[100%] md:mt-16 '
         id="carousel"
         tag="section"
         wrapperTag="ul"
         navigation={{
          nextEl: ".custom_next",
        }}
         rewind={true}
         onSwiper={(s) => {setSwiper(s)}}
         onSlideChange={() => {
         setSlideDescription( reviews[swiper.realIndex])}}
         breakpoints={{
    640: {
      slidesPerView: 1,
      spaceBetween:0, 
    },
  }}
         >
          {slides}
      </Swiper> 
 )}
      </>
    );
}

export default Carousel;

import {Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import BreadCrumb from '../components/subComponents/BreadCrumb'
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';

export const ResetPasswordPage = ({location, history}) => {
    const { t, i18n } = useTranslation();

    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')
    const [message, setMessage] = useState('')

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    const submitHandler = (e) => { 
        e.preventDefault()
        if(password && password !== password_confirmation) {
            setMessage('passwords not match')
        } else {
            const reset = async () => {
              axios.post('api/reset-password', {email, token, password, password_confirmation}).then(response => {
                  history.push('/login')
              }).catch(error=> {
                setMessage(error.response.data.msg)
            })
        }
            reset()
        }
    }

  return (
<div className='h-screen bg-white font-astana'>
  <div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[4vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('to_reset_your_password')} t={t}  /> 
</div>
    {token && (
<form className="my-10" onSubmit={submitHandler}>
    <div className="flex justify-center  w-1/2 mx-auto flex-col">
            <div className="bg-white my-10  text-black w-full">
                   {message && (  <p className='border-2 border-red-600 p-2 text-center'>  {message} </p>)}
              <TextField
              type='password' 
              label={`${t('password')} /`}
              value={password} 
              onChange={(e)=>setPassword(e.target.value)} 
              variant="standard"
              className="w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             ></TextField>
                           <TextField
              type='password' 
              label={`${t('password')} ${t('confirm')} /`}
              value={password_confirmation} 
              onChange={(e)=>setPasswordConfirmation(e.target.value)} 
              variant="standard"
              className="w-full"
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 900,
                    letterSpacing: '0.05em'
                    },
              }}
             ></TextField>
                <button
                    type="submit"
                    className="w-20 mt-4 uppercase"
                >{t('Update')}
                </button>
            </div>
    </div>
</form>
    )}
</div>
  )
}

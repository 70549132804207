import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { GlobalButton, PageTitle } from './generalComponents';
import BreadCrumb from '../components/subComponents/BreadCrumb';
import CartPageProductsTheme from '../components/subComponents/CartPageProductsTheme';
import CartPageMenuTheme from '../components/subComponents/CartPageMenuTheme';
import FormControlLabel from '@mui/material/FormControlLabel';

export const CartPageTheme = ({
    error,
    coupon,
    errorCode,
    setCouponCode,
    couponCode,
    totalPrice,
    totalPlusCouponPrice,
    submitCoupon,
    cartItems, 
    saveShippingMethodHandler, 
    shippingMethod, 
    removeFromCartHandler, 
    checkOutHandler, 
    addToCartHandler}) => {
        
    const { t, i18n } = useTranslation();

    return (
    <div className='h-screen bg-white font-astana'>
<div className='w-full 
h-20
lg:h-32 
pt-12
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-bgImage
z-20'>
 <BreadCrumb category = {t('Shopping Cart')} t={t}  /> 
</div>
{cartItems.length === 0 && (
<div className='ml-20 h-40 mt-20'>
    <Link to="/" 
    className='text-xl italic my-2
     text-theme-base-color2'>{t('Your cart is empty')} {t('Back to Bookstore')} 
    </Link>
 </div>
  )}
{cartItems.length !== 0 && (
  <div className='grid grid-cols-1 -mt-32 px-[4vh] lg:px-[10vh] h-full lg:grid-cols-3'>
      <div className='mt-32 pb-4'>
        <CartPageProductsTheme 
        t={t}
        cartItems={cartItems} 
        addToCartHandler={addToCartHandler} 
        removeFromCartHandler={removeFromCartHandler}/>
         <div className='my-2 lg:my-4'>
         <Link to="/" className=' text-theme-base-color2'> {t('Back to Bookstore')}</Link>
      </div>
      </div>
      <div className='lg:pt-32 col-span-2	'>
        <CartPageMenuTheme 
            t={t}
            totalPrice ={totalPrice} 
            totalPlusCouponPrice={totalPlusCouponPrice}
            coupon={coupon}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            submitCoupon={submitCoupon}
            shippingMethod={shippingMethod}
            cartItems={cartItems}
            error={error}
            errorCode={errorCode}
            saveShippingMethodHandler={saveShippingMethodHandler}
            checkOutHandler={checkOutHandler}
        />
      </div>

  </div>
)}
    </div>
     )
  }
import React from 'react'
import Header from '../components/Header'
import DeliveryCost from '../components/termsOfUse/DeliveryCost'
import PersonalData from '../components/termsOfUse/PersonalData'
import Terms from '../components/termsOfUse/Terms'

const InfoTermsPage = () => {
  return (
    <>
    <Header/>
    <div  className='conditions px-6 md:px-20'>
      <section id='terms' className='pt-40  h-auto'>
          <div className='px-10'>
            <h2 className='pt-10 pb-10'> Όροι / Προϋποθέσεις Χρήσης </h2>
            <hr className='py-4 w-3/4' ></hr>
            <Terms/>
          </div>
      </section>
      <section id='data' className='h-auto'>
          <div className='px-10'>
            <h2 className='pt-10 pb-10'> Προσωπικά Δεδομένα </h2>
            <hr className='py-4 w-3/4' ></hr>
            <PersonalData/>
          </div>
      </section>
      <section id='delivery' className='h-auto'>
          <div className='px-10'>
            <h2 className='pt-10 pb-10'> Έξοδα Αποστολής</h2>
            <hr className='py-4 w-3/4' ></hr>
            <DeliveryCost/>
          </div>
      </section>
    </div>
    </>
  )
}

export default InfoTermsPage
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteOrder, listOrders} from '../actions/orderActions'
import {ORDER_DELIVER_RESET} from '../constants/orderConstants'
import Orderitem from '../components/Orderitem'
import SearchAutoComp from '../components/SearchAutoComp'

export const OrderListPage = ({ history }) => {

  const dispatch = useDispatch()
  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders } = orderList
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [filterValue, setFilterValue] = useState('')

    // product delete
    const orderDelete = useSelector((state) => state.orderDelete )
    const {
      loading: loadingOrder,
      success: successDelete,
      error: errorDelete
      } = orderDelete


  useEffect(() => {
    if (userInfo && userInfo.data.role) {
      dispatch(listOrders(userInfo.isAdmin))
    } else {
      history.push('/login')
    }
  }, [filterValue, successDelete])

//  orders && filterValue && console.log(orders.data.filter(item=>item.order_id === filterValue.id))

const deleteHandler = (id) => {
  if(window.confirm('Confirm this order will delete')){
   dispatch(deleteOrder(id))
}
}


  return (
    <>

    {loading===false && orders && (      
<div className=" p-10 w-full h-auto flex flex-col">
<div className="py-2 align-middle inline-block  sm:px-2 lg:px-4">
<div className="p-5 w-1/4"> 
            <SearchAutoComp
            labelOpts='orders'
            userData={userInfo.data}
            apiUrl = '/api/orderSearch'
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
            />
            </div>
<table className="table-auto divide-y divide-gray-200">
  <thead className="bg-gray-50">
    <tr>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Order Number 
       </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Payment Method
      </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        TotalPrice
      </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Date
      </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Order Status
      </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Payment Status
      </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Delete
      </th>
      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        Message
      </th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
     { orders && filterValue.length > 0 ? 
     <Orderitem 
     key = {orders.data.filter(item=>item.order_id === filterValue[0].id).order_id} 
     order = {orders.data.filter(item=>item.order_id === filterValue[0].id)[0]} 
     deleteHandler = {deleteHandler}
     />
     : orders ? orders.data.map(
      (order) => ( <Orderitem 
        key = {order.order_id}     
         deleteHandler = {deleteHandler}
        order={order}/>)
      )
     : ''
    }
  </tbody>
</table>
</div>
</div>
    )}
    </>
  )
}
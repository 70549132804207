import React from 'react';
import {useSelector} from 'react-redux';

import CategoriesInput from '../../components/dashboard/CategoriesInput'
import BannerAdmin from '../../components/dashboard/BannerAdmin';
import AboutPage  from '../../components/dashboard/AboutPage';
import TagsPage from '../../components/dashboard/TagsPage';


const Attributes = () => {
    const userLogin = useSelector((state)=> state.userLogin)
    const {userInfo} = userLogin
  return (

  <div className='grid w-full px-2 grid-cols-1 lg:gap-2  lg:grid-cols-2'>
      <div className='w-full text-sm p-8 bg-gray-100'>
          <CategoriesInput/>
      </div>
      <div className='w-full  text-sm p-8 bg-gray-100'>
          <BannerAdmin/>
      </div>
      <div className='w-full text-sm p-8 bg-gray-100'>
          <AboutPage userInfo={userInfo}/>
      </div>
      <div className='w-full text-sm p-8 bg-gray-100'>
          <TagsPage userInfo={userInfo}/>
      </div>
  </div>
  )};

export default Attributes;
import React from 'react'
import Loader from '../components/Loader'
import Product from '../components/Product'

import BreadCrumb from '../components/subComponents/BreadCrumb';
import PaginationComponent from '../components/subComponents/PaginationComponent';


const ProductPageCategoryTheme = ({data, handleChangePage}) => {

  return (
<div className="min-h-screen font-astana bg-bgImage">
{data.loading ? <Loader/> : <>
<div className='w-full
items-center
h-20
lg:h-32 
pt-12
z-20
lg:pt-20 
px-[2vh] 
lg:px-[10vh] 
flex 
flex-row 
justify-between 
bg-white'>
 <BreadCrumb category = {data.category} t = {data.t}/> 
{ data.results &&
 <PaginationComponent data = {data} handleChangePage = {handleChangePage}/>
}
</div>

<div className="
 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5
 pb-10
 min-h-screen
 gap-1
 md:gap-1
 w-auto
 px-2
 md:px-[2vh]
 lg:px-[10vh]">
{ data.results ? data.results.map((product) => 
( <Product 
 t = {data.t}
 history={data.history} 
 key={product.id} 
 product={product}/>
 )) : 
<p className='text-2xl p-2'> {data.t('notFound')}</p>
}
</div>

</>
}
</div>
  )
}

export default ProductPageCategoryTheme
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_SHIPPING_METHOD,
    CART_SAVE_PAYMENT_METHOD,
    CART_CLEAR_ITEMS,
    CART_ADD_COUPON,
    ADD_COUPON_FAIL
 } from '../constants/cartConstants'

export const cartReducer = (state = {cartItems: [], shippingMethod:'', shippingAddress:{}}, action) => {
    switch(action.type) {
        case CART_ADD_ITEM:
            const item = action.payload
            const existItem = state.cartItems.find(x=>x.product === item.product)

            if(existItem) {
                return {
                    ...state,
                    cartItems:state.cartItems.map(x=>x.product === existItem.product ? item : x)
                }
            }
            else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                }
            }
        case CART_REMOVE_ITEM:
            return {
                    ...state,
                    cartItems:state.cartItems.filter((x)=>x.product !== action.payload)
            }
        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                    ...state,
                    shippingAddress: action.payload,
                }
        case CART_SAVE_SHIPPING_METHOD:
            return {
                    ...state,
                    shippingMethod: action.payload,
                }          
        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                 paymentMethod: action.payload,
                 }   
        case CART_CLEAR_ITEMS:
           return {
               ...state,
               cartItems: [],
               }
        case CART_ADD_COUPON:
                return {
                    ...state,
                     error:'',
                     coupon: action.payload,
                     }
        case ADD_COUPON_FAIL:
                        return {
                    ...state,
                error:action.payload,
                             }                             
        default:
            return state
    }
}
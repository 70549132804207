import React, {useState, useRef, useEffect} from 'react'
import Input from "@material-tailwind/react/Input";
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';

export const SearchBox = ({history}) => {
    const { t, i18n } = useTranslation();

    const [keyword, setKeyword] = useState('')
    const [showOk, setShowOk] = useState(false)

    const refOutside = useRef()

    const submitHandler = (e) => {
        e.preventDefault()
        if(keyword.trim()) {
            history.push(`/search?t=${keyword}`)
        } else {
            history.push('/')
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showOk && refOutside.current && !refOutside.current.contains(e.target)) {
              setShowOk(false)
              setKeyword('')
            }
          }

          document.addEventListener("mousedown", checkIfClickedOutside)
      
          return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
          }
    }, [showOk, keyword])

    return (
        <>
<form onSubmit = {submitHandler} className="w-full">
    <div ref={refOutside} className="flex items-center  border-teal-500 py-2 ">
<TextField
              variant="standard"
              className="lowercase text-xs md:text-base	w-full"
              type='text' 
              label={`${t('title')} | ${t('author')} /`}
              value={keyword} 
              InputProps={{
              disableUnderline: true, // <== added this
              }}
              InputLabelProps={{
                style: { 
                    color: 'black',
                    fontWeight: 700,
                    letterSpacing: '0.05em'
                    },
              }}
              onClick={(e)=>setShowOk(true)}
              onChange={(e)=>setKeyword(e.target.value)}   
             />

        <div className='px-2'></div>
        { showOk && (
        <button type='submit' className=" uppercase flex-shrink-0 bg-transparent 
         text-sm  text-theme-base-color2 px-2 rounded">
            {t('ok')}
        </button>
        )}
    </div>
</form>
</>
    )
}

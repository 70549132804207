import  ReactDOM  from 'react-dom';
import React, {useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getOrder, payOrder } from '../actions/orderActions';
import { ORDER_PAY_RESET } from '../constants/orderConstants';
import { OrderPageTheme } from '../theme/OrderPageTheme';
import Header from '../components/Header';
import Bottom from '../components/Bottom';

export const OrderPage = ({match}) => {


    const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

    const orderId = match.params.id

    const dispatch=useDispatch()

    const orderDetails = useSelector((state)=>state.orderDetails)
    const {order, loading, error} = orderDetails
    const orderPay = useSelector((state)=>state.orderPay)
    const {loading:loadingPay, sucess:successPay} = orderPay
    if(order) {order.data.totalPrice = (Math.round(order.data.totalPrice*100)/100).toFixed(2)}
    //Paypal

    const createOrder = (data, actions) =>{
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: order.data.totalPrice,
                currency_code: "EUR",
              },
            },
          ],
        });
      };
    
      const onApprove = (data, actions) => {
        return actions.order.capture().then(function(paymentResult) {
          dispatch(payOrder(orderId, paymentResult))
        });
      };
    

     useEffect(()=> {
         if(!order || successPay) {
        dispatch({type: ORDER_PAY_RESET})
        dispatch(getOrder(orderId))
        localStorage.removeItem('cartItems');
         }
    }, [dispatch, orderId, order, successPay])


    return (
      <>
    <OrderPageTheme 
    order={order} 
    loading={loading}
    PayPalButton={PayPalButton}
    onApprove={onApprove}
    createOrder={createOrder}/>
    </>
    )
}

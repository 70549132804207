import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import  {listCategories} from '../../actions/categoryActions'
import { DropdownCategories } from '../DropdownCategories'
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from "react-i18next";

const CategoriesCard = ({ isAdmin, selectedCategory, setSelectedCategory}) => {

  const dispatch = useDispatch()
  const categoryList = useSelector((state) => state.categoryList)
  const { loading, error, categories } = categoryList

  useEffect(()=>{
    dispatch(listCategories())
  }, [])

  return (
    <>
  {loading === false && (
    <div id="categories">
            <DropdownCategories 
            Categories = {categories} 
            selectedCategory={selectedCategory}
            setSelectedCategory = {setSelectedCategory}
            ></DropdownCategories>
    </div>
  )}
    </>
      );
    }
    
    function CategoryList(props) {

      const { t, i18n } = useTranslation();

      const [open, setOpen] = useState(true);
      return (
        <div >
            {open && props.children}
        </div>
      );    
    }
export default CategoriesCard;

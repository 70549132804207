import axios from "axios";
import { 
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_SAVE_SHIPPING_METHOD,
    CART_ADD_COUPON,
    ADD_COUPON_FAIL
    } from "../constants/cartConstants";

export const addToCart = (id,qty) => async (dispatch, getState) => {
    const {data} = await axios.get(`/api/product/${id}`)
    
    let image = data.data.media.filter((el) => el.isMain).length ?  
    data.data.media.filter((el) => el.isMain ).map(el=>el.pathway) 
    : data.data.media.length > 0 ?  data.data.media[0].pathway : ""

    if (data.data.stock>0) {
    dispatch({
        type:CART_ADD_ITEM,
        payload: {
            product: data.data.id,
            title: data.data.title,
            image: image,
            discountPlan: data.data.discountPlan,
            discount:data.data.discount,
            price: data.data.price,
            author:data.data.author,
            stock:data.data.stock,
            weight:data.data.weight,
            qty
        }
    })
localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
    }
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
      type:CART_REMOVE_ITEM,
      payload: id 
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data
    })

    localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const saveShippingMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_METHOD,
        payload: data
    })

    localStorage.setItem('shippingMethod', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data
    })

    localStorage.setItem('paymentMethod', JSON.stringify(data))
}

export const addCouponToCart = (coupon) => async (dispatch) => {
    try {
    const {data} = await axios.get(`/api/coupons/${coupon}`)
    dispatch({
        type: CART_ADD_COUPON,
        payload: data
    })
    localStorage.setItem('coupon', JSON.stringify(data))
}
catch(error){
    dispatch({
        type: ADD_COUPON_FAIL,
        payload:'invalid coupon Code'
    })
} 
}

import axios from "axios";
import { ORDER_CREATE_REQUEST,
         ORDER_CREATE_SUCCESS,
         ORDER_CREATE_FAIL,
         ORDER_DETAILS_REQUEST,
         ORDER_DETAILS_SUCCESS,
         ORDER_DETAILS_FAIL,
         ORDER_PAY_SUCCESS,
         ORDER_PAY_RESET,
         ORDER_PAY_REQUEST,
         ORDER_PAY_FAIL, 
         ORDER_LIST_FAIL,
         ORDER_LIST_SUCCESS,
         ORDER_LIST_REQUEST,
         ORDER_STATUS_SUCCESS,
         ORDER_STATUS_REQUEST,
         ORDER_STATUS_FAIL,
         ORDER_STATUS_RESET,
         ORDER_LIST_PROFILE_SUCCESS,
         ORDER_LIST_PROFILE_REQUEST,
         ORDER_LIST_PROFILE_FAIL,
         ORDER_DELETE_SUCCESS,
         ORDER_DELETE_REQUEST,
         ORDER_DELETE_FAIL,
        } from "../constants/orderConstants";

export const createOrder = (order) => async(dispatch, getState) => {
    try {

        dispatch({
            type: ORDER_CREATE_REQUEST
        })

        const { userLogin:{userInfo}} = getState()
        
        const {coupon} = order

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        }
        const {data} = await axios.post(`/api/order`, order, config)
        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload:data,
        })
        
        axios.put(`/api/coupons/${coupon.data.code}`, config)
        localStorage.removeItem('cartItems');
        localStorage.removeItem('coupon')

    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload:error
        })
    } 
}

export const getOrder = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST
        })

        const { userLogin:{userInfo}} = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        }

        const {data} = await axios.get(`/api/order/${id}`, config)
        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload:data,
        })
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload:error
        })

    } 
}

export const getProfileOrder = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_LIST_PROFILE_REQUEST,
        })

        const { 
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        }

        const { data } = await axios.get(`/api/orders/user`, config)

        dispatch({
            type: ORDER_LIST_PROFILE_SUCCESS,
            payload:data,
        })
    } catch (error) {
        dispatch({
            type: ORDER_LIST_PROFILE_FAIL,
            payload:error
        })

    } 
}

export const payOrder = (orderId, paymentResult) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_PAY_REQUEST
        })

        const { userLogin:{userInfo}} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        }

        const {data} = await axios.put(`/api/order/${orderId}/pay`, paymentResult, config)
        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload:data,
        })
    } catch (error) {
        dispatch({
            type: ORDER_PAY_FAIL,
            payload:error
        })
    } 
}


export const listOrders = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_LIST_REQUEST
        })

        const { userLogin:{userInfo}} = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        }

        const {data} = await axios.get(`/api/orders`, config)
        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload:data,
        })
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload:error
        })

    } 
}

export const statusOrder = (orderId, status) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_STATUS_REQUEST
        })

        const { userLogin:{userInfo}} = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.data.token}`,
            }
        }

        const {data} = await axios.put(`/api/order/${orderId}/status`, status, config)

        dispatch({
            type: ORDER_STATUS_SUCCESS,
            payload:data,
        })
    } catch (error) {
        dispatch({
            type: ORDER_STATUS_FAIL,
            payload:error
        })
    } 
}


export const deleteOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: 
            ORDER_DELETE_REQUEST})

            const {
                userLogin: {userInfo},
            } = getState()

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.data.token}`,
                },
            }       

        await axios.delete(`/api/orders/${id}`, config)

        dispatch({ 
            type: ORDER_DELETE_SUCCESS
        })
    } catch (error) {
        dispatch({
        type: ORDER_DELETE_FAIL,
        payload: error.response.data.message
        })
        
    }
 }
import React from 'react'
import {Link} from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useTranslation } from "react-i18next";


export const CheckoutSchema = ({step1, step2, step3, step4}) => {

  const { t, i18n } = useTranslation();

    return (
  <section className='px-[4vh] 
  lg:px-[10vh] py-6'>
  <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
  <div className=''>
  <Link className="w-1/2 md:w-1/4 text-left" to='/profile'>
      <div className=" rounded-lg flex flex-row items-center justify-left ">
        <ArrowForwardIosIcon/>
          <h2 className={`font-bold  text-sm ${step1 ? 'text-theme-base-color2' : 'text-bg-gray-800' }`}>{t('PersonalInfo')}</h2>
      </div>
    </Link>
    </div>
    <div className='px-1'>
    <Link className="w-1/2 md:w-1/4" to='/shipping'>
      <div className=" rounded-lg flex flex-row items-center justify-left">
      <ArrowForwardIosIcon/>
          <h2 className={`font-bold  text-sm ${step2 ? 'text-theme-base-color2' : 'text-bg-gray-800' }`}>{t('shippingInfo')}</h2>
      </div>
    </Link>
    </div>
    <div className='px-1'>
    <Link className="w-1/2 md:w-1/4" to='/payment'>
      <div className=" rounded-lg flex flex-row justify-left">
      <ArrowForwardIosIcon/>
          <h2 className={`font-bold  text-sm ${step3 ? 'text-theme-base-color2' : 'text-bg-gray-800' }`}>{t('PaymentInfo')}</h2>
        </div>
    </Link>

    </div>
    <div className='px-1'>
    <Link className="w-1/2 md:w-1/4 " to='/placeOrder'>   
      <div className="rounded-lg flex flex-row items-center justify-left">
      <ArrowForwardIosIcon/>
          <h2 className={`font-bold  text-sm ${step4 ? 'text-theme-base-color2' : 'text-bg-gray-800' }`}>{t('confirmation')}</h2>
      </div>
  </Link>
  </div>
  </div>
</section>
    )
}

import React from 'react'

const PersonalData = () => {
  return (
    <div className=''>
<h3> Πολιτική προστασίας δεδομένων προσωπικού χαρακτήρα - Ασφάλεια συναλλαγών </h3>
<h3> Δεδομένα προσωπικού χαρακτήρα </h3>
Υπεύθυνος επεξεργασίας δεδομένων προσωπικού χαρακτήρα (προσωπικών δεδομένων) είναι η εταιρεία με την επωνυμία «Γ. ΤΣΑΚΑΛΟΣ-Κ. ΧΡΙΣΤΟΠΟΥΛΟΣ Ο.Ε» ηλεκτρονική διεύθυνση naftilosbooks@gmail.com, εφεξής «η εταιρεία».

Η εταιρεία συμμορφώνεται με όλους τους νόμους, συμπεριλαμβανομένου του Γενικού Κανονισμού για την Προστασία των φυσικών προσώπων έναντι επεξεργασίας δεδομένων προσωπικού χαρακτήρα (2016/679/EE). Η εταιρεία δεσμεύεται να προστατεύει τα δεδομένα προσωπικού χαρακτήρα των επισκεπτών/πελατών του ηλεκτρονικού καταστήματος και να τηρεί τις σχετικές ρυθμίσεις του δικαίου της ΕΕ και του εθνικού δικαίου για την προστασία του ατόμου από την επεξεργασία δεδομένων προσωπικού χαρακτήρα, όπως εκάστοτε ισχύουν.

 

<h4> Τί δεδομένα συλλέγουμε </h4>
Ο κάθε επισκέπτης μπορεί να περιηγηθεί στο ηλεκτρονικό κατάστημα χωρίς να κληθεί ή να υποχρεωθεί να δώσει οποιαδήποτε προσωπική πληροφορία. Όμως, για την υποβολή παραγγελίας, απαραίτητη προϋπόθεση είναι η γνωστοποίηση από τον χρήστη κάποιων προσωπικών στοιχείων. Η εταιρεία δεν συλλέγει πληροφορίες που αφορούν σε προσωπικά δεδομένα παρά μόνο αν τις παρέχει ο ενδιαφερόμενος επισκέπτης οικειοθελώς και με δική του πρωτοβουλία. Για τον λόγο αυτό, θα ζητηθούν: ονοματεπώνυμο/εταιρική επωνυμία (εφόσον ο πελάτης είναι νομικό πρόσωπο), ταχυδρομική διεύθυνση/έδρα, τηλέφωνο, ηλεκτρονική διεύθυνση (e-mail), ΑΦΜ (για αγορές με τιμολόγιο) και - σε περίπτωση επιλογής της πιστωτικής κάρτας ως τρόπου εξόφλησης, - ο αριθμός της πιστωτικής κάρτας, η ημερομηνία λήξεως αυτής και ο αριθμός CVC. Με την υποβολή της παραγγελίας ο χρήστης θα κληθεί να παράσχει τη ρητή συγκατάθεσή του για την επικείμενη επεξεργασία των προσωπικών του δεδομένων σύμφωνα με τον νόμο.

Μέσω της ιστοσελίδας της εταιρείας ο χρήστης μπορεί επίσης να επιλέξει να συμπληρώσει τη φόρμα της λίστας επιθυμιών αλλά και τη δυνατότητα ενημέρωσής του για τα νέα του εκδοτικού χώρου, τις νέες κυκλοφορίες, τυχόν προσφορές καθώς και τις εκδηλώσεις που λαμβάνουν χώρα στα βιβλιοπωλεία Ευριπίδης μέσω της αποστολής διαφημιστικών – ενημερωτικών μηνυμάτων στην ηλεκτρονική ή ταχυδρομική διεύθυνση του. Παρέχεται πάντοτε στους χρήστες η δυνατότητα ανάκλησης της συγκατάθεσης και διακοπής της λήψης ενημερωτικών - διαφημιστικών μηνυμάτων. Με την υποβολή παραγγελιών η εταιρία συγκεντρώνει το ιστορικό παραγγελιών κάθε χρήστη. Σε καμία περίπτωση δεν συλλέγονται δεδομένα που ο Κανονισμός χαρακτηρίζει ως ειδικές κατηγορίες δεδομένων («ευαίσθητα δεδομένα»), δηλαδή δεδομένα που αποκαλύπτουν τη φυλετική ή εθνοτική καταγωγή, τα πολιτικά φρονήματα, τις θρησκευτικές ή φιλοσοφικές πεποιθήσεις ή τη συμμετοχή σε συνδικαλιστική οργάνωση, την υγεία ή τη σεξουαλική ζωή φυσικού προσώπου ή τον γενετήσιο προσανατολισμό ενός προσώπου.

 

<h4> Πώς χρησιμοποιούμε αυτά τα δεδομένα; </h4>
Η συλλογή, επεξεργασία, τήρηση και χρήση των προσωπικών δεδομένων γίνεται μόνο εφόσον και στην έκταση που θεμελιώνεται σε μία από τις περιπτώσεις που προβλέπονται από τη νομοθεσία (συγκατάθεση, σύμβαση, νομική υποχρέωση, δημόσιο συμφέρον, έννομο συμφέρον) και πάντα σύμφωνα με τους προβλεπόμενους από αυτούς όρους και με την τήρηση των αντίστοιχων εγγυήσεων. Συλλέγουμε μόνο τα δεδομένα που είναι απαραίτητα για τον νόμιμο σκοπό για τον οποίο συλλέγονται.

Τα προσωπικά δεδομένα χρησιμοποιούνται αποκλειστικά για τους σκοπούς για τους οποίους συλλέγονται. Οι σκοποί αυτοί είναι:

1) παροχή ποιοτικών υπηρεσιών,

2) η ανταπόκριση στις συμβατικές υποχρεώσεις μας, όπως η εκτέλεση των παραγγελιών,

3) η πώληση προϊόντων,

4) η επεξεργασία των εντολών των επισκεπτών,

5) η βελτίωση του σχεδιασμού και του περιεχομένου του δικτυακού τόπου,

6) η επικοινωνία με τους πελάτες με σκοπό την ενημέρωσή τους για τα προϊόντα και τις υπηρεσίες του ηλεκτρονικού καταστήματος, καθώς και για τις προσφορές, τις προωθητικές ενέργειες και τις εκδηλώσεις που πραγματοποιούνται από την εταιρεία.

Η «Γ. ΤΣΑΚΑΛΟΣ-Κ. ΧΡΙΣΤΟΠΟΥΛΟΣ Ο.Ε» σεβόμενη απόλυτα το απόρρητο των στοιχείων αυτών και ακολουθώντας απαρέγκλιτα τις αρχές της προστασίας προσωπικών δεδομένων, δεν πρόκειται να προβεί σε οποιαδήποτε αθέμιτη χρήση ή γνωστοποίηση των προσωπικών στοιχείων που συλλέγει, παρά μόνο κατόπιν προηγούμενης ρητής συγκατάθεσης του χρήστη ή στην περίπτωση που αυτό επιβάλλεται από διάταξη νόμων.

 

<h4> Συγκατάθεση χρήστη </h4>
Με την εγγραφή του στο ηλεκτρονικό κατάστημα, ο χρήστης θα κληθεί να παρέχει την συγκατάθεσή του στην επικείμενη επεξεργασία των προσωπικών του δεδομένων από την Εταιρεία, ως υπεύθυνης για την επεξεργασία τους σε έντυπη, αυτοματοποιημένη ή τηλεματική μορφή (ειδικότερα μέσω ταχυδρομείου ή ηλεκτρονικού ταχυδρομείου, τηλεφώνου και κάθε άλλου μέσου ηλεκτρονικής επικοινωνίας) είτε από την ίδια είτε μέσω οποιουδήποτε άλλου προσώπου στο οποίο η Εταιρεία θα αναθέσει για λογαριασμό της την επεξεργασία βάσει ρητών συμβατικών προβλέψεων και σύμφωνα με τους όρους και τη διαδικασία που προβλέπεται από τον Κανονισμό και σε κάθε περίπτωση με σεβασμό στο απόρρητο και στις βασικές αρχές επεξεργασίας των προσωπικών δεδομένων.

 

<h4> Δικαιώματα υποκειμένου δεδομένων προσωπικού χαρακτήρα </h4>
Κάθε πρόσωπο το οποίο αφορούν τα δεδομένα που συλλέγονται και υπόκεινται σε επεξεργασία (υποκείμενο των δεδομένων») έχει τα παρακάτω δικαιώματα:

Το δικαίωμα ενημέρωσης
Το δικαίωμα πρόσβασης
Το δικαίωμα στη διόρθωση
Το δικαίωμα στη διαγραφή (δικαίωμα στη λήθη)
Το δικαίωμα στον περιορισμό της επεξεργασίας
Το δικαίωμα του υποκειμένου στη φορητότητα
Το δικαίωμα αντίρρησης
Το δικαίωμα αντίρρησης στις περιπτώσεις profiling
Το δικαίωμα υποβολής καταγγελίας σε εποπτική αρχή
Το ενδιαφερόμενο πρόσωπο δικαιούται δηλαδή να έχει άμεση πρόσβαση στην πληροφόρηση για τα θέματα των προσωπικών του δεδομένων, να αντιτίθεται στη χρήση αυτών σε μελλοντικές προωθητικές ενέργειες, να ζητά και να επιβεβαιώνει τη μερική ή ολική διαγραφή τους από τα αρχεία της επιχείρησης, να ζητά τη διόρθωση ή συμπλήρωσή τους, να πληροφορείται τον χρόνο και τρόπο της αρχικής απόκτησης των προσωπικών δεδομένων από την επιχείρηση καθώς και να ενημερώνεται για τις εφαρμοζόμενες μεθόδους προστασίας των προσωπικών δεδομένων.

 

<h4>Περίοδος αποθήκευσης αρχείων δεδομένων προσωπικού χαρακτήρα </h4>
 Τα δεδομένα προσωπικού χαρακτήρα που συλλέγονται για την εκτέλεση των παραγγελιών και την έκδοση των νόμιμων φορολογικών στοιχείων, διατηρούνται για το χρονικό διάστημα που απαιτείται για την εκτέλεση της παραγγελίας καθώς και για την ανταπόκριση στις εκ των φορολογικών διατάξεων απαιτήσεις που κάθε φορά ισχύουν.

 

<h4> Το ηλεκτρονικό κατάστημα παρέχει συνδέσμους σε εξωτερικούς δικτυακούς τόπους για την πολιτική ασφάλειας προσωπικών δεδομένων των οποίων δεν είναι υπεύθυνη </h4>

<h4> Η ... διατηρεί το δικαίωμα να τροποποιεί τους παρόντες όρους, αναλαμβάνει δε να ενημερώνει τους χρήστες για τυχόν τροποποιήσεις μέσω της παρούσας ιστοσελίδας. </h4>
    </div>
  )
}

export default PersonalData
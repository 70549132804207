import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { payOrder,statusOrder } from '../actions/orderActions'
import Modal from '../components/Modal'

const Orderitem = ({
  order,
  deleteHandler
}) => {

    const dispatch = useDispatch()
   
    const [showModal, setShowModal] = useState(false);
    const [statusPaid, setStatusPaid] = useState(order.payment_status)
    const [status, setStatus] = useState(order.status) 
    const [orderModal, setOrderModal]= useState('')
    const [message, setMessage] = useState('')
    const belongTo = 'order'



  const showOrderModal = (order) => {
         setOrderModal(order)
         setShowModal(true)
       }



  const togglePaymentStatus = (orderId) => {
    dispatch(payOrder(orderId))
     setStatusPaid(true)
  }


  const toggleStatus = (orderId, status) => {  
      dispatch(statusOrder(orderId, {
                           status: status,
                           message: message
                                    }
                        ))
                        setStatus(status)
        setMessage('')
  }


  return ( 

    <>
    { showModal && ( 
  <Modal 
    showModal={showModal} 
    setShowModal={setShowModal}
    belongTo = {belongTo}
    data={orderModal} 
    /> 
    )}
    {order && 
 <tr key={order.order_id} className="bg-gray-50 ">
      <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
            <button className="p-2 border-2 neumorph "
            onClick={(e)=> showOrderModal(order)}> {order.order_number} </button>
            </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">{order.payment_method}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">{order.totalPrice}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">{order.dateofOrder}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
    <select  
    value={status}  
    onChange={ (e) => {   
    if (window.confirm(`Please confirm your choice. A notification letter will be sent to your customer`))
     toggleStatus(order.order_id, e.target.value)
     }}
    className={`font-semibold text-white py-2 px-4 border hover:border-transparent rounded
       ${status === 'pending' ? 
        'hover:bg-white  bg-red-700 hover:text-red-700' : status === 'processing' ?
        'hover:bg-white bg-green-700 hover:text-green-700' : status === 'decline' ?
        'hover:bg-white line-through bg-gray-800 hover:text-gray-400' :
        'hover:bg-white bg-blue-500  hover:text-blue-700' }`}>
            <option value="pending">Pending</option>
            <option value="processing">Processing</option>
            {statusPaid &&  <option value="completed">Completed</option> }
            <option value="decline">Decline</option>
    </select>
      </td>             
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
      <button onClick={ (e) => 
        { if (window.confirm(`Please confirm your choice. A notification letter will be sent to your customer`)) 
        togglePaymentStatus(order.order_id) }}  
        className={`font-semibold text-white py-2 px-4 border  hover:border-transparent rounded 
         ${statusPaid ? 
         'bg-blue-500 hover:text-blue-700 hover:bg-white' : 
         'bg-red-500 hover:text-red-700 hover: border-red-500' } `}>
            {statusPaid ? 'Paid' : 'Not paid'}
    </button>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
              <button onClick={()=> { status === 'completed' && deleteHandler(order.order_id)}  }
              className="bg-transparent hover:bg-red-500 
              text-red-700 font-semibold 
              hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                    DELETE
            </button>
    </td>
    <td>
        <textarea value={message}
               className='w-1/2 border-2 border-gray-800'
               onChange={(e)=> setMessage(e.target.value)}
               name = 'message' 
        />
    </td>             
    </tr>
}
    </>
  )};

export default Orderitem;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Input from "@material-tailwind/react/Input";

const DiscountPlansForm = ({plan}) => {
const [percentage, setPercentage] = useState(plan.percentage ? plan.percentage : '')
const [duration_days, setDurationDays] = useState(plan.duration_days ? plan.duration_days : '')
const [msn, setMsn] = useState('')

const updateHandler = (name) => {
  const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)   
  const updatePlan = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }
    const  plansData = {
      name: name ,
      percentage: percentage,
      duration_days: duration_days,
    }
 try {   
 await axios.put(`/api/discountplans/${name}`, plansData, config);
 } catch (err) {
  setMsn(JSON.parse(err.response.data))
 }
    setDurationDays(duration_days)
    setPercentage(percentage)
};
  updatePlan();
}


  return (
<>
 <tr >
              <td className="px-6 py-4 whitespace-normal">
                <div className="flex items-center">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {plan.name}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-normal" >
              <Input 
                type="text"
                color="lightBlue"
                size="regular"
                outline={true}
                placeholder=""
                value={percentage}
                onChange = {(e)=> setPercentage(e.target.value)}
                />
              </td>
              <td className="px-6 py-4 whitespace-normal" >
              <Input 
                type="text"
                color="lightBlue"
                size="regular"
                outline={true}
                placeholder=""
                value={duration_days}
                onChange = {(e)=> setDurationDays(e.target.value)}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
              <button 
              onClick={()=>updateHandler(plan.name)}
              className="bg-transparent hover:bg-blue-500 
               text-blue-700 font-semibold 
               hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    UPDATE
              </button>
              </td> 
              </tr>
</>
  )
}

export default DiscountPlansForm
import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Sidebar from '../components/dashboard/Sidebar';
import {Dash} from './admin/Dash';
import Settings from './admin/Settings';
import Tables from './admin/Tables';
import Attributes from './admin/Attributes';
import Maps from './admin/Maps';
import Footer from '../components/dashboard/Footer';
import { UserListPage } from './UserListPage';
import { ProductListPage } from './ProductListPage';
import { OrderListPage } from './OrderListPage';
import {Scheduler} from '../components/Scheduler';
import ProductEditComponent from '../components/ProductEditComponent';
import Coupons from './admin/Coupons';
import AddressBook from '../components/dashboard/AddressBook';

export const DashBoardPage = () => {
    let { path } = useRouteMatch();
    
    return (
            <>
            <Sidebar />
            <div className="md:ml-32">
                <Switch>
                    <Route exact path={`${path}/`} component={Dash} />
                    <Route exact path={`${path}/settings`} component={Settings} />
                    <Route exact path={`${path}/tables`} component={Tables} />
                    <Route exact path={`${path}/attributes`} component={Attributes} />
                    <Route exact path={`${path}/coupons`} component={Coupons} />
                    <Route exact path={`${path}/addressbook`} component={AddressBook} />
                    <Route exact path={`${path}/calendar`} component={Scheduler} />
                    <Route exact path={`${path}/userlist`} component={UserListPage}/>
                    <Route exact path= {`${path}/productlist`} component={ProductListPage}/>
                    <Route exact path={`${path}/orderlist`} component={OrderListPage}/>
                    <Route path={`${path}/product/:id/edit`} component={ProductEditComponent}/>

                </Switch>
                <Footer />
            </div>
            </>
    )
}

import React from 'react'

const DeliveryCost = () => {
  return (
    <div>
        Εσωτερικό (εντός Ελλάδος)
Tο κόστος των μεταφορικών διαμορφώνεται 
στα 2,70€ για πληρωμή με πιστωτική ή χρεωστική κάρτα, PayPal ή κατάθεση για την Αττική και στα 3.7€ εκτός Αττικής.
Mε αντικαταβολή το κόστος των μεταφορικών διαμορφώνεται στα 4,50€. Επιπλέον των 2 κιλών βάρος χρεώνεται με 1€/Κγρ.
Δωρεάν για αγορές συνολικής αξίας άνω των 50 ευρω.
Εξωτερικό (εκτός Ελλάδος)
Οι αποστολές σε προορισμούς εκτός της ελληνικής επικράτειας, ανεξαρτήτως αξίας παραγγελίας, 
επιβαρύνονται με έξοδα αποστολής σύμφωνα με τον 
ισχύοντα τιμοκατάλογο της ACS Courier
και εξαρτώνται από το βάρος του δέματος αποστολής και τη χώρα προορισμού.
</div>
  )
}

export default DeliveryCost
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import ChartBar from '../../components/dashboard/ChartBar'
import ChartLine from '../../components/dashboard/ChartLine'
import StatusCard from '../../components/dashboard/StatusCard'
import PageVisitsCard from '../../components/dashboard/PageVisitsCard'
import TrafficCard from '../../components/dashboard/TrafficCard'
import "@material-tailwind/react/tailwind.css";

export const Dash = () => {

    const sortData = (data) => {
        let sortedData;

          sortedData = data.sort(function(a,b){
            return a.month - b.month;
          })
        return sortedData;
      }

    const [dataCurrentYear, setDataCurrentYear] = useState('')
    const [dataPreviousYear, setDataPreviousYear] = useState('')
    const [viewsMonth, setViewsMonth] = useState('')
    const [usersMonth, setUsersMonth] = useState('')
    const [salesWeek, setSalesWeek] = useState('')
    const [usersWeek, setUsersWeek] = useState('')
    const [viewsWeek, setViewsWeek] = useState('')
    const [viewsProduct, setViewsProduct] = useState()
    const [produi, setProdui] = useState([])
    const [render, setRender] = useState(false)

    useEffect(()=> {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
        const fetchData = async () => {
          const configConnect = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          const result = await axios.get('api/reports', configConnect);

          let currentYear = new Date().getFullYear()
          for (let i=1; i<=12; i++) {
          if( result.data[currentYear] && !result.data[currentYear].find((o)=> o.month=== i)) {
              result.data[currentYear].push({"year":2022,"month": i, "price": 0});
          }
          if(result.data[currentYear-1] && !result.data[currentYear-1].find((o)=> o.month=== i)) {
            result.data[currentYear-1].push({"year":2021,"month": i, "price": 0});
        }
          }
          result.data[currentYear-1] && setDataPreviousYear(sortData(result.data[currentYear-1]))
          result.data[currentYear] && setDataCurrentYear(sortData(result.data[currentYear]))
          setSalesWeek(result.data['order_week'])
          setUsersWeek(result.data['user_week'])
          setViewsWeek(result.data['view_week'])
          setViewsProduct(result.data['views_product'])
          setViewsMonth(result.data['view_month'])
          setUsersMonth(result.data['user_month'])
        };
        fetchData();
    }, [])

    useEffect(()=> {
        if(viewsProduct) {
            viewsProduct.forEach((item) => {
                retrieveBooks(item.viewable_id)
            })
        }
    },[viewsProduct])

    const retrieveBooks = async (id) => {
        const result = await axios.get(`api/product/${id}`);
        setProdui(produi => [...produi,result.data.data])
    }


    return (
  <>
  { produi  && (
     <div className="bg-gray-100 px-3 pt-40 md:px-8 h-screen">
            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <ChartBar dataCurrentYear={dataCurrentYear} dataPreviousYear={dataPreviousYear} />
                            <div className=' py-4 text-center'>
                                <p className='text-xl bg-blue-700 text-white p-4'> 5 Most Visited Products last Month </p>
                            {produi.map((item)=> (
                                              <div className="flex py-4 items-center">
                                              <div className="flex-shrink-0 h-10 w-10">
                                                <img className="h-10 w-10 rounded-full" src={`${process.env.REACT_APP_API_ENDPOINT}storage/${item.media.filter((el) => el.isMain ).map(el=>el.pathway)}`} alt=""/>
                                               </div>
                                               <div className="ml-4">
                                                <div className="text-sm text-gray-500">
                                                {item.title}
                                                 </div>
                                                </div>
                                              </div> 
                                                         
                        ))}
                        </div>
                        </div>
                        <div className="grid grid-cols-1 mb-2">
                        <StatusCard
                            color="blue"
                            icon="trending_up"
                            title="Traffic"
                            amount={<> {viewsMonth} / {viewsWeek} </>} 
                            date="current month / last week"
                        />
                        <StatusCard
                            color="blue"
                            icon="groups"
                            title="New Users"
                            amount={<> {usersMonth} / {usersWeek} </>} 
                            date="current month / last week"
                        />
                        <StatusCard
                            color="blue"
                            icon="paid"
                            title="Sales"
                            amount={salesWeek}
                            percentageColor="orange"
                            date="last week"
                        />
                    </div>
                    </div>

                </div>
            </div>
     </div>
  )}
</>
    )
}

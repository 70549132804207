import React, {useState} from 'react'
import { useTranslation } from "react-i18next";


const ProductPdfViewer = ({pdfPath}) => {
  const { t, i18n } = useTranslation();



    let filePath = `http://127.0.0.1:8000/storage/${pdfPath}`

    console.log(filePath)

    return (
    <div className='px-4 border border-gray-900 hover:border-red-800 bg-white'>
        <a style={{display: "table-cell"}} href={filePath} target="_blank">{t('lookInside')}</a>
    </div>
    )
}

export default ProductPdfViewer

import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CategoriesCard from './dashboard/CategoriesCard';
import { useHistory } from "react-router-dom";
import Typography from '@mui/material/Typography';
import {Accordion} from './subComponents/AccordionCustom'
import {AccordionSummary} from './subComponents/AccordionCustom'
import {AccordionDetails} from './subComponents/AccordionCustom'
import { MyTypography } from './subComponents/AccordionCustom';


export const Menu = () => {
const history = useHistory();
const { t, i18n } = useTranslation();
const [selectedCategory, setSelectedCategory] = useState({id: '0', name: '', slug: '' })
const isAdmin = false;

useEffect(() => {
    if(selectedCategory.slug) {
        history.push(`/category-products/${selectedCategory.slug}`)
    }
}, [selectedCategory.slug])

    return (
<nav className="pl-2 md:pl-10 flex text-gray-900 flex-col pb-4 pt-8 w-full">
        <Accordion className='uppercase'>
        <AccordionSummary>
          <MyTypography content={t('categories')}/>
        </AccordionSummary>
        <AccordionDetails className='text-xs lg:text-sm'>   
        <CategoriesCard 
         setSelectedCategory={setSelectedCategory}
         selectedCategory={selectedCategory}/>
        </AccordionDetails>
        </Accordion>
        <Link className='text-xs flex justify-center sm:justify-start md:text-sm lg:text-base xl:text-lg  uppercase' 
        to={ `/products/new-products`}> {t('new-products')}  </Link>
        <Link className='text-xs flex justify-center sm:justify-start md:text-sm lg:text-base xl:text-lg text-theme-base-color2 ' 
        to={ `/products/featured`}> 
        <p className='uppercase'> {t('featured')}</p>
        <p className='px-1 '> του Ναυτίλου </p></Link>
        <Link className='text-xs flex justify-center sm:justify-start md:text-sm lg:text-base xl:text-lg uppercase'
         to={ `/products/new-listings`}> {t('new-listings')}  </Link>   
        <Link className='text-xs flex justify-center sm:justify-start md:text-sm lg:text-base xl:text-lg uppercase'
         to={ `/aboutUs`}> {t('aboutus')}  </Link>
        <Link className='text-xs flex justify-center sm:justify-start md:text-sm lg:text-base xl:text-lg uppercase'
         to={ '/search'}> {t('cosearch')}  </Link>
</nav>
    )
}

import React, {useState, useRef, useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import moment from 'moment';

const SearchAutoComp = ({userData, labelOpts, apiUrl, setFilterValue}) => {

const [keyword, setKeyword] = useState('')
const [searchResult, setSearchResult] = useState('')

useEffect(() => {
    const fetchData = async () => { 
    const config = { 
      
      headers: userData ? { 'Authorization': `Bearer ${userData.token}`} : {},
      params : {
        keyword:keyword,
      }
    } 

    if(keyword.length > 3) {
    const {data} = await axios.get(apiUrl,config);
    setSearchResult(data.data.data)
      }else {
        setSearchResult([])
      }
    }
fetchData()
}, [keyword])



  return (
    <Autocomplete
    renderOption={(props, option) => {

      return (
        labelOpts === 'products' ? 
        <>
        <li className='bg-gray-150 py-1' {...props} key={option.id}>
          {option.title} - {option.author} - {option.publisher}
        </li>
        </>  :
        labelOpts === 'orders' ?
        <>
        <li className='bg-gray-150 py-1' {...props} key={option.id}>
          {option.order_number} - {option.shippingName} - {option.payment_method} - {moment(option.created_at).format('MMMM Do YYYY, h:mm:ss a')}
        </li>
        </> :
        labelOpts === 'tags' ?
        <>
        <li className='bg-gray-150 py-1' {...props} key={option.id}>
          {option.name}
        </li>
        </> : ''
      );
    }}
      multiple
      filterOptions={(x)=> x}
      id="size-small-outlined-multi"
      size="medium"
      options={searchResult}
      onChange={(event, filterValue) =>{ setFilterValue(filterValue)}}
     getOptionLabel={(option) => option.title ? option.title : option.order_number ? option.order_number : option.name ? option.name : ''}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Search"
          placeholder=""
          name="value"
          onChange={(e)=>{setKeyword(e.target.value)}}
        />
      )}
    />
  )
}

export default SearchAutoComp
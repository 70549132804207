import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';


const PaginationComponent = ({data, handleChangePage}) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      "& li .MuiPaginationItem-root" : {
        fontFamily: 'astana',
        [theme.breakpoints.down("720")]: {
          fontSize:  '0.5rem',
          margin: '-0.1rem',
        },
        [theme.breakpoints.down("800")]: {
          fontSize:  '0.65rem',
          margin: '-0.1rem',  
        },
        [theme.breakpoints.down("900")]: {
          fontSize:  '0.8rem',
          margin: '-0.1rem',  
        },
        [theme.breakpoints.up("900")]: {
          fontSize:  '1rem',
          margin: '-0.1rem',  
        },
      },
      'selected': {
        backgroundColor: 'red',
      },
  }}));
  
  
  const classes = useStyles();

  return (
    <Pagination
    size="small"
    siblingCount={1}
    boundaryCount={1}
    className={classes.root}
    count={Math.ceil(data.total/data.perPage)}
    page={data.pageNum}
    selected={true}
    onChange={handleChangePage}
    hidePrevButton={true}
    renderItem={(item)=> 
    <PaginationItem
    {...item} 
    classes={{root:classes.root}}
    />
  }
  />
  )
}

export default PaginationComponent
import React, {useState} from 'react'
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/solid'
import Cursor from '../locales/cursor.png'
import CategoriesProductSelect from './CategoriesProductSelect'
import Checkbox from '@mui/material/Checkbox';

const CategoriesProductSelectItem = ({category, deleteCats, selectedCats, addingCats}) => {
  const [checked, setChecked] = useState(selectedCats ? selectedCats.filter( (item) => item.id === category.id).length!==0 : false)

  return (
    <Disclosure
    >
    {({ open }) => (
      <>
        <Disclosure.Button 
        className="flex m-2 p-2 w-full justify-between text-left 
        text-sm lg:text-base font-medium text-gray-900 hover:bg-gray-200 
        focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
        <div>
          <Checkbox
           checked={checked}
           onChange={(e)=> { !checked ? addingCats(category) : deleteCats(category); setChecked(!checked) }}
          />
          {category.name}
        </div>
          { category.children.length>0 && (
       <svg 
       className={`${
        open ? 'rotate-90 transform' : ''
      } h-5 w-5 text-purple-500`} 
      xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 28.405 20.925"> 
         <g id="Layer_2" data-name="Layer 2">
           <path fill="none" stroke="black"  d="M27.614,5.144C27.7,7.383,26,13.308,21.593,16.775c-3.737,2.941-9.675,4.348-13.329,2.881a4.486,4.486,0,0,1-2.47-3.345,4.31,4.31,0,0,1,1.184-2.985,3.291,3.291,0,0,1,1.7-1.08c.778-.171,1.052.147,1.956.206a5.135,5.135,0,0,0,3.576-.721,4.8,4.8,0,0,0,1.879-2.7,5.83,5.83,0,0,0,.128-2.418A13.258,13.258,0,0,1,19.123,5.3C20.667,4.784,27.589,4.5,27.614,5.144ZM9.265,12.191a6.912,6.912,0,0,0-1.644-1.258,4.54,4.54,0,0,0-3.757.541c-1.467,1.132-1.338,2.907-.875,4.348.614,1.911,4.857,3.6,4.857,3.6M7.8,10.985a3.277,3.277,0,0,0-1.75-2.419,4.087,4.087,0,0,0-3.4,0,3.788,3.788,0,0,0-1.518,3.783,9.145,9.145,0,0,0,1.981,3.756M7.261,9.544A8.729,8.729,0,0,0,6.9,6.971,2.491,2.491,0,0,0,4.379,5.4c-1.344.024-2.877.4-3.268,1.776a10.442,10.442,0,0,0,0,4.554M7.518,7.9s2.547-2.033,2.136-4.168A2.721,2.721,0,0,0,7.775,1.49a4.013,4.013,0,0,0-2.65.309A6.718,6.718,0,0,0,3.118,3.471M8.933,6.353s2.934-.309,2.856-2.933C11.727,1.285,10.374,1.078,9.757.975a8.05,8.05,0,0,0-3.423.463M10.5,6.327a2.637,2.637,0,0,0,3.088-1.055,2.3,2.3,0,0,0-.412-3.216A6.174,6.174,0,0,0,9.757.975m1.389,5.558a2.331,2.331,0,0,0,2.29,1.261A2.639,2.639,0,0,0,15.7,5.761c.1-.617.206-2.161-2.033-3.242M11.815,7.254a4.438,4.438,0,0,0,.155,1.8,1.921,1.921,0,0,0,2.225.926,2.642,2.642,0,0,0,2-2.418,4.319,4.319,0,0,0-.464-1.982M11.764,8.412a2.186,2.186,0,0,0-.9,1.8c.129,1.055,1.361,2.049,3.188,1.611M11.326,8.772a2.357,2.357,0,0,0-1.672,1A1.755,1.755,0,0,0,9.6,11.783a2.567,2.567,0,0,0,.946.649m-.225-3.3a1.936,1.936,0,0,0-1.467-.463,1.95,1.95,0,0,0-1.364,1.39M10.323,9a2.283,2.283,0,0,0-1.338-1.03A4.5,4.5,0,0,0,7.338,8,6.8,6.8,0,0,0,8.11,6.379,3.047,3.047,0,0,0,7,3.368a3.513,3.513,0,0,0-2.984-.411A4.766,4.766,0,0,0,1.24,6.816M10.323,9a6.544,6.544,0,0,0-.592-1.827,2.019,2.019,0,0,0-.8-.824m1.493,2.676a8.532,8.532,0,0,0,.206-2.238,4.832,4.832,0,0,0-.232-.9M10.58,9a5.172,5.172,0,0,0,.721-1.03c.411-.788.283-.772.283-.772"/>
         </g>
       </svg>

)}
        </Disclosure.Button>

        <Disclosure.Panel className="px-2 flex flex-col md:w-full md:flex-row  text-sm lg:text-base text-gray-500">
              { category.children.length>0 && category.children.map( (cat) =>  (
              <CategoriesProductSelectItem
              key={category.id}
              addingCats={addingCats}
              deleteCats={deleteCats}
              category={cat}
              selectedCats = {selectedCats}
              />
       ))
              }  
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
  )
}

export default CategoriesProductSelectItem
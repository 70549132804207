import React, { useEffect, useState } from "react";
import axios from "axios";
import IconDelete from '../adminAssets/img/icon-delete.png'


const ProductListImage = ({productId, notifyImg}) => {

    const [images, setImages] = useState([])


    useEffect(() => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
        const fetchImages = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          const result = await axios.get(
            `api/products/${productId}/media`, config
          );
          setImages(result.data);
        };
        fetchImages();
      }, [productId, notifyImg]);

      const handleDelete = (id) => {
        const token = (JSON.parse(localStorage.getItem('userInfo')).data.token)
        const deleteImage = async () => {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              }
          }
          await axios.delete(`api/media/${id}`, config);
          const newImages = images.filter(image => image.id !== id)
          setImages(newImages)
        };
        deleteImage()
      }


    return (
        <div className="pt-6 pb-6">
          {images &&
          <>
            <label className="inline-block mb-2 text-gray-500">Image List</label>
            <div className=" flex p-4">
              { images.map((image) => {
                return image.pathway.split('.').pop() === "pdf" ?
                <div className="w-auto h-20 p-2" key={image.id}>
                <button onClick={(e) => handleDelete(image.id)} className=" w-6 bg-red-300"> 
                <p> {image.pathway} </p>
                <img className="w-6" src={IconDelete} alt="" />
                </button>
                </div>
                : 
                <div className="w-auto h-auto p-2" key={image.id}>
                    <img className="h-64" alt={image.pathway} src={ `${process.env.REACT_APP_API_ENDPOINT}storage/${image.pathway}`} />
                    <button onClick={(e) => handleDelete(image.id)} className=" w-6 bg-red-300"> 
                    <img className="w-6" src={IconDelete} alt="" />
                    </button>
                </div>
             })}
            </div>
            </>
}
        </div>
    )
}

export default ProductListImage
import React from 'react'
import { Link } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { t } from 'i18next'

export const ProductPageReviewTheme = ({errorReview, userInfo,
   reviewSubmitHandler, setReview,
   review, product, deleteReview}) => {


  return (
    <>
    <Disclosure>
          {({ open }) => (
            <>
                  <Disclosure.Button className="flex 
              w-full justify-center  
              py-6 border-t
              border-gray-800 
              focus:outline-none focus-visible:ring 
              focus-visible:ring-gray-200 
              focus-visible:ring-opacity-75">
                <span className='text-lg uppercase md:text-xl lg:text-2xl'>{t('reviews')}</span>
                <ArrowRightIcon
                  className={`${
                    open ? 'rotate-90 transform' : ''
                  } p-1 h-6 w-6 text-gray-700`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pb-2 text-sm  border-gray-800 text-gray-500">
      <div className="bg-gray-50 p-10  grid grid-cols-1 gap-2">
    <div className="pl-10 pr-10">
      <p className=" text-lg pb-5 tracking-wide	 ">Write a Review</p>
      {errorReview && (<h1> {errorReview} </h1>) }
      {
      userInfo ? (
      <form onSubmit={reviewSubmitHandler}>
      <textarea value={review} onChange={(e)=>setReview(e.target.value)}
      className="w-full h-20 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline">
      </textarea>
      <button className="bg-transparent border-gray-900 hover:border-red-500 text-gray-900  py-2 px-4 border-2" 
      type='submit' > Submit</button>
      </form>) :
      <h3>Should <Link className="underline text-red-500" to='/login'> log in </Link> to write a review</h3> 
      }
    </div>
    <div className="pl-10 pr-10">
      <p className=" text-lg pb-5 tracking-wide	 ">Reviews</p>
      { product.data.reviews !== undefined && product.data.reviews.map(review => 
        ( 
        <div className="pb-2"> 
        <p key={review.id} 
        className="text-gray-700 w-full">
        {review.review} 
        </p> 

        <p onClick={()=> {deleteReview(review.id)}} className='bg-red-200 text-center cursor-pointer w-1/2 '>
          Delete
        </p>
        </div> ))}
    </div>
 </div>


 </Disclosure.Panel>
 </>
          )}
        </Disclosure>
    </>
  )
}
